import React, { useState } from 'react';
import PluangListPage from 'components/organisms/pluangListTable';
import { filter, header } from './constants';

const AcIndonesianSingleStockTransaction = (props) => {
  const [filters] = useState(filter);

  const [headers] = useState(header);

  return (
    <PluangListPage
      {...props}
      title={'Indonesian Single Stock Transactions'}
      headers={headers}
      filters={filters}
      resource={'IDSS_TRANSACTIONS'}
    />
  );
};

export default AcIndonesianSingleStockTransaction;
