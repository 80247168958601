import React, { useState, useEffect } from 'react';
import { makeRequest } from 'services/APIService';
import { REMOTE } from 'constants/serverRoutes';
import { useStateValue } from 'utils/redux/store';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { formatDateToZone } from 'services/DateTime';
import { useNotification } from 'services/hooks';

const RdnTopup = () => {
  const [topUpState, setTopUpState] = useState({
    topUpData: [],
    topUp: false
  });
  const [appState] = useStateValue();
  const { showNotification } = useNotification();

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    let data = { salesForceId: appState.sfId };
    makeRequest({
      url: `${REMOTE.IDSS}/rdnTopupHistory`,
      params: data
    })
      .then((result) => {
        if (result.data && result.data.success) {
          if (result.data.topupHistories && result.data.topupHistories.length === 0) {
            showNotification('error', 'RDN topup not found for this user');
          }
          setTopUpState((prevState) => ({
            ...prevState,
            topUpData: result.data.topupHistories,
            topUp: true
          }));
        } else {
          showNotification('error', result.data);
        }
      })
      .catch(() => {
        showNotification('error', 'RDN topup not found for this user');
        setTopUpState((prevState) => ({
          ...prevState,
          topUpData: [],
          topUp: false
        }));
      });
  };

  const { topUpData, topUp } = topUpState;
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h5">RDN Top Up History</Typography>
      </Box>
      {topUp ? (
        <Table style={{ width: '35%', marginLeft: '10%' }}>
          <TableHead>
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topUpData.map((topup, key) => {
              return (
                <TableRow key={key}>
                  <TableCell>{topup.amount}</TableCell>
                  <TableCell>{formatDateToZone(new Date(topup.date))}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : null}
    </>
  );
};

export default RdnTopup;
