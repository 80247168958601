import { Box, Button, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ImageMagnifier from 'components/molecules/ImageMagnifier';
import KycTask from 'components/molecules/KycTask';
import KycTaskTable from 'components/molecules/KycTaskTable';
import ListDialog from 'components/molecules/ListDialog';
import { dateFormatter } from 'helpers/dateChanger';
import { makeRequest } from 'services/APIService';
import { REMOTE } from 'constants/serverRoutes';
import { useStateValue } from 'utils/redux';
import useNotification from 'services/hooks/useNotification';
import { foreignerKycHeaders, normalKycHeaders } from './constants';
import { OPEN_MODAL } from 'utils/redux/actions';
import { useLoader } from 'services/hooks';

const listHeaders = ['createdAt', 'fromState', 'toState', 'rejectionReason', 'rejectionReasonId'];

const BasicDetails = ({ ktpOcrData: ktpOcr, escalate, kyc1Data: kycData, userData }) => {
  const [basicDetailsState, setBasicDetailsState] = useState({
    kycData: {},
    userData: {},
    ktpOcrData: {},
    idCardPicture: '',
    selfPortraitPicture: '',
    parentalLetter: '',
    familyCertificate: '',
    npwpPicture: '',
    nationality: '',
    fatcaDocument: null,
    nameOnIdentityCard: '',
    identityNumber: '',
    rejectionReasonLogData: [],
    isModalOpen: false,
    dateOfBirth: '',
    showDetail: false,
    dateOfSubmission: '',
    count: [''],
    values: [],
    viewImage: '',
    deg: 0,
    scale: 1,
    flip: 1,
    englishOptions: [],
    negativeCheckError: '',
    selfieScoreCheckError: '',
    isButtonDisabled: false
  });
  const [appState, dispatch] = useStateValue();
  const { showNotification } = useNotification();
  const { user } = appState;
  const [showDetail, setShowDetail] = useState(false);
  const [, setLoader] = useLoader();

  const handleDropdown = (action) => {
    setShowDetail(!action);
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setBasicDetailsState((prevState) => ({
      ...prevState,
      [name]: files[0]
    }));
  };

  const getRejectionReasonInIndonesianLang = (rejectionReasonJson) => {
    const rejectionData = rejectionReasonJson && rejectionReasonJson.id;
    const rejectionReason = [];
    if (Array.isArray(rejectionData)) {
      rejectionData.forEach((reason) => {
        rejectionReason.push(reason.value);
      });
    } else {
      return rejectionData;
    }
    return rejectionReason.join(',');
  };

  const getRejectionReasonLog = () => {
    if (!basicDetailsState.rejectionReasonLogData.length > 0) {
      getRejectionReasonData(kycData.userId).then((result) => {
        if (result.data && result.data.data && result.data.data.length > 0) {
          setBasicDetailsState((prevState) => ({
            ...prevState,
            rejectionCount: result.data.data.length,
            rejectionReasonLogData: result.data.data
          }));
          openRejectionReasonDialog(result.data.data);
        } else {
          showNotification('error', 'No rejection reason logs found');
        }
      });
    }
  };

  const openRejectionReasonDialog = (data) => {
    dispatch({
      type: OPEN_MODAL,
      modalContent: <ListDialog title="Rejection Reason logs" data={data} headers={listHeaders} />
    });
  };

  const getRejectionReasonData = (userId) => {
    setLoader(true);
    return makeRequest({
      url: `${REMOTE.KYC1}/stateTransition/filter`,
      params: {
        userId,
        toState: 'REJECTED'
      }
    })
      .then((result) => result)
      .finally(() => {
        setLoader(false);
      });
  };

  const { rejectionReasonLogData } = basicDetailsState;
  const parentalLetter = (kycData.meta && kycData.meta.parentalLetter) || kycData.parentalLetter;
  const familyCertificate =
    (kycData.meta && kycData.meta.familyCertificate) || kycData.familyCertificate || '';

  const basicTableHeaders = [
    'User Id',
    'User Name',
    'NIK',
    'DoB',
    'OCR DoB',
    'Last Upload Time',
    'Last Update Time',
    'FATCA Document',
    ...(kycData.nationality === 'Indonesia' ? normalKycHeaders : foreignerKycHeaders),
    'PEP Status',
    'Rejection Reason',
    'Dual Approval Status',
    'Dual Approval Reject Reason',
    'Date of checker',
    'Dual Approval Submission Time'
  ];

  const normalKycValues = [
    <>{kycData ? kycData.selfieScore : ''}</>,
    kycData ? kycData.partnerId : '',
    kycData ? kycData.autoApprovalRejectionReason : '',
    kycData ? kycData.asliriResponse : '',
    <>{kycData ? kycData.negativeAsliriResponse : ''}</>
  ];

  const foreignerKycValues = [
    ktpOcr.meta?.nationality,
    kycData ? kycData.residentialAddress : '',
    kycData ? kycData.residentialCountry : '',
    kycData ? kycData.residentialPostalCode : '',
    kycData ? kycData.placeOfBirth : '',
    kycData && kycData.fatcaDocument ? 'UPLOADED' : 'NOT UPLOADED'
  ];
  const basicTableValues = [
    userData.id,
    kycData.nameOnIdentityCard,
    kycData.identityCardNumber,
    userData.dateOfBirth,
    ktpOcr.dateOfBirth,
    kycData.lastUploadTime ? dateFormatter(kycData.lastUploadTime) : '',
    kycData.updated ? dateFormatter(kycData.updated) : '',
    kycData.status !== 'VERIFIED' && kycData.nationality?.toLowerCase() === 'united states' && (
      <input
        type="file"
        accept=".pdf"
        id="formFatcaDocument"
        name="fatcaDocument"
        style={{ opacity: 1, width: '40%' }}
        onChange={() => handleFileChange()}
      />
    ),
    ...(kycData.nationality === 'Indonesia' ? normalKycValues : foreignerKycValues),
    String(userData?.pepCheck).toUpperCase(),
    kycData.rejectionReasonJson
      ? getRejectionReasonInIndonesianLang(kycData.rejectionReasonJson)
      : '',
    kycData.dualApprovalStatus,
    kycData.dualApprovalRejectionReason,
    kycData ? dateFormatter(kycData.dualApprovalTime) : '',
    ktpOcr.updatedAt ? dateFormatter(ktpOcr.updatedAt) : ''
  ];

  const kycTaskStatus = () => (
    <>
      {escalate && escalate.iskyc1Escalated && <span className="escalated-status">ESCALATED</span>}
      {kycData && kycData.status === 'VERIFIED' ? (
        <span style={{ color: 'green' }}>{kycData.status}</span>
      ) : (
        <span style={{ color: 'black' }}>{kycData.status}</span>
      )}
    </>
  );

  const handleKycTaskClick = () => {
    handleDropdown(showDetail);
  };

  const isTaskDisabled = kycData.status === 'NOT UPLOADED';
  return (
    <>
      {(Object.keys(kycData).length &&
        Object.keys(userData).length &&
        Object.keys(ktpOcr).length && (
          <KycTask
            title="Basic Verification Data"
            status={kycTaskStatus()}
            onClick={handleKycTaskClick}
            disabled={isTaskDisabled}
            open={showDetail}>
            <Grid>
              <Grid item>
                <KycTaskTable headers={basicTableHeaders} values={basicTableValues} />
                {kycData.dualApprovalStatus === 'NEED_APPROVAL' && (
                  <Box
                    bgcolor="red"
                    border={1}
                    borderColor="red"
                    borderRadius={4}
                    color="red"
                    my={2}
                    p={1.5}
                    textAlign="center">
                    Dual Approval Pending
                  </Box>
                )}
              </Grid>
            </Grid>
            <br />
            {kycData.dualApprovalStatus !== 'NEED_APPROVAL' && (
              <div className="formHeading">
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box>
                    <h4>User KYC Details</h4>
                  </Box>
                  <Box>
                    {(user.features.superadmin || user.features.dualapproval) &&
                      kycData.dualApprovalStatus === 'NEED_APPROVAL' && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="btn btn-primary btnAction"
                          type="button"
                          disabled>
                          Need Approval
                        </Button>
                      )}
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      type="button"
                      className="btnLeft"
                      onClick={getRejectionReasonLog}>
                      Rejection reason logs
                    </Button>
                  </Box>
                </Box>
              </div>
            )}
            <div className="workingArea">
              {kycData.idCardPicture && (
                <Grid container>
                  <Grid item xs={6}>
                    <h6 className={'foSize'}>Id Card Picture</h6>
                    <div className="fluid">
                      <div className="fluid__image-container">
                        {kycData.idCardPicture ? (
                          <ImageMagnifier url={kycData.idCardPicture} />
                        ) : null}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}
              {kycData.selfPortraitPicture && (
                <Grid container>
                  <Grid item xs={6}>
                    <h6 className={'foSize'}>Self Portrait Picture</h6>
                    <div className="fluid">
                      <div className="fluid__image-container">
                        {kycData.selfPortraitPicture ? (
                          <ImageMagnifier url={kycData.selfPortraitPicture} />
                        ) : null}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>

            {kycData.nationality === 'Indonesia' && (
              <Grid container>
                <Grid item xs={6}>
                  <h6 className={'foSize'}>Parental Letter</h6>
                  <div className="fluid">
                    <div className="fluid__image-container">
                      {parentalLetter ? <ImageMagnifier url={parentalLetter} /> : null}
                      {kycData.status &&
                        kycData.status !== 'VERIFIED' &&
                        parentalLetter === null &&
                        (userData && userData.ageVerified === false
                          ? 'Waiting for Parental Letter'
                          : '')}
                      {parentalLetter === null &&
                        (userData && userData.ageVerified
                          ? 'User is above 17 years old / Age is verified'
                          : '')}
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
            {kycData.nationality === 'Indonesia' && (
              <Grid container>
                <Grid item xs={6}>
                  <h6 className={'foSize'}>Family Certificate</h6>
                  <div className="fluid">
                    <div className="fluid__image-container">
                      {familyCertificate ? <ImageMagnifier url={familyCertificate} /> : null}
                      {kycData.status &&
                        kycData.status !== 'VERIFIED' &&
                        familyCertificate === null &&
                        (userData && userData.ageVerified === false
                          ? 'Waiting for family certificate'
                          : '')}
                      {familyCertificate === null &&
                        (userData && userData.ageVerified
                          ? 'User is above 17 years old / Age is verified'
                          : '')}
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
            {kycData.npwpPicture && (
              <Grid container>
                <Grid item xs={6}>
                  <h6 className={'foSize'}>Npwp Picture</h6>
                  <div className="fluid">
                    <div className="fluid__image-container">
                      {kycData.npwpPicture ? <ImageMagnifier url={kycData.npwpPicture} /> : null}
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
            {kycData.fatcaDocument && (
              <Grid container>
                <Grid item xs={6}>
                  <h6 className={'foSize'}>Fatca Document</h6>
                  <div className="fluid">
                    <div className="fluid__image-container">
                      {kycData.fatcaDocument ? (
                        <ImageMagnifier url={kycData.fatcaDocument} />
                      ) : null}
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
          </KycTask>
        )) ||
        null}
    </>
  );
};

BasicDetails.propTypes = {
  ktpOcrData: PropTypes.object,
  escalate: PropTypes.object,
  kyc1Data: PropTypes.object,
  userData: PropTypes.object
};

export default BasicDetails;
