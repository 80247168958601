import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import BasicTable from 'components/molecules/BasicTable';
import S3DownloadLink from 'components/molecules/s3DownloadLink';
import { dateFormatter } from 'helpers/dateChanger';
import PropTypes from 'prop-types';
import { FOREX_CASHOUT_STATUS } from '../../constants';
import ForexFinanceForm from './ForexFinanceForm';

const ForexCashoutViewForm = ({ usdDetails, userDetails, adminDetails, auditTrail }) => {
  const userDetailsTable = [
    { label: 'Name', value: userDetails ? userDetails.name : '' },
    {
      label: 'Email',
      value: (
        <>
          {userDetails ? userDetails.email : ''}
          <Typography ml={2} variant="caption" color={userDetails?.emailVerified ? 'green' : 'red'}>
            {userDetails?.emailVerified ? 'Verified' : 'Not verified'}
          </Typography>
        </>
      )
    },
    { label: 'Phone', value: userDetails?.phone },
    { label: 'Basic Kyc Status', value: userDetails?.bappebtiKycStatus },
    { label: 'GSS Kyc Status', value: userDetails?.gssKycStatus },
    { label: 'Status', value: userDetails?.active ? 'Active' : 'InActive' },
    { label: 'Edd Status', value: userDetails?.eddStatus || 'Not Requested' },
    { label: 'Risk Rating Category', value: userDetails?.riskRatingCategory },
    { label: 'Referral Code', value: userDetails?.referralCode || 'N/A' },
    {
      label: 'Referral Code Status',
      value: userDetails?.referralCodeActive ? 'Active' : 'InActive'
    },
    { label: 'Registered Date', value: dateFormatter(userDetails?.createdAt) },
    { label: 'Last Updated', value: dateFormatter(userDetails?.updatedAt) }
  ];

  const getAdminEmail = (id) => {
    return adminDetails[id]?.email;
  };

  const auditTrailTable = [
    {
      label: 'Requester',
      value: getAdminEmail(auditTrail[FOREX_CASHOUT_STATUS.INITIATED]?.processedBy)
    },
    {
      label: 'Requester Time',
      value: dateFormatter(auditTrail[FOREX_CASHOUT_STATUS.INITIATED]?.processedAt)
    },
    {
      label: 'Verifier',
      value: getAdminEmail(auditTrail[FOREX_CASHOUT_STATUS.VERIFIED]?.processedBy)
    },
    {
      label: 'Verifier Time',
      value: dateFormatter(auditTrail[FOREX_CASHOUT_STATUS.VERIFIED]?.processedAt)
    },
    {
      label: 'Approver',
      value: getAdminEmail(auditTrail[FOREX_CASHOUT_STATUS.APPROVED]?.processedBy)
    },
    {
      label: 'Approver Time',
      value: dateFormatter(auditTrail[FOREX_CASHOUT_STATUS.APPROVED]?.processedAt)
    },
    {
      label: 'Completer',
      value: getAdminEmail(auditTrail[FOREX_CASHOUT_STATUS.COMPLETED]?.processedBy)
    },
    {
      label: 'Completer Time',
      value: dateFormatter(auditTrail[FOREX_CASHOUT_STATUS.COMPLETED]?.processedAt)
    }
  ];

  const csCallConfirmationLink = [
    { label: 'User Confirmation Recording', value: usdDetails?.csCallConfirmationLink }
  ];

  const forexTopDetails = [
    { label: 'Cash Out Id', value: usdDetails?.id },
    { label: 'Cash Out Status', value: usdDetails?.status },
    { label: 'Withdrawal Amount Requested', value: usdDetails?.withdrawalAmount },
    {
      label: 'Rejection Reason (En)',
      value: usdDetails?.rejectionReasonEn
    },
    {
      label: 'Rejection Reason (In)',
      value: usdDetails?.rejectionReasonIn
    },
    {
      label: 'Internal Rejection Reason',
      value: usdDetails?.internalRejectionReason
    }
  ];

  const beneficiaryAccountDetails = [
    { label: 'Account Number', value: usdDetails?.destinationBankAccount },
    {
      label: 'Name',
      value: usdDetails?.destinationBankAccountOwner
    },
    { label: 'Address', value: usdDetails?.destinationBankAccountOwnerAddress }
  ];

  const beneficiaryBankInfo = [
    { label: 'Bank Name', value: usdDetails?.destinationBank },
    {
      label: 'Bank SWIFT Code',
      value: usdDetails?.destinationBankSwift
    },
    { label: 'Bank Address', value: usdDetails?.destinationBankAddress },
    { label: 'Bank City', value: usdDetails?.destinationBankCity },
    {
      label: 'Bank Country',
      value: usdDetails?.destinationBankCountry
    },
    {
      label: 'Cash Out Form',
      value: usdDetails?.proofOfRequest ? <S3DownloadLink url={usdDetails?.proofOfRequest} /> : ''
    },
    {
      label: 'Proof Of Bank Ownership',
      value: usdDetails?.proofOfBankAccountOwnership ? (
        <S3DownloadLink url={usdDetails?.proofOfBankAccountOwnership} />
      ) : (
        ''
      )
    }
  ];

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">USD Cashout Details</Typography>
        <Divider sx={{ my: 2 }} />
        {usdDetails && (
          <>
            <BasicTable tableData={forexTopDetails} />
            <Typography variant="subtitle1" sx={{ mt: 2, mx: 2, fontWeight: 'bold' }}>
              Beneficiary Account Details
            </Typography>
            <BasicTable tableData={beneficiaryAccountDetails} />
            <Typography variant="subtitle1" sx={{ mt: 2, mx: 2, fontWeight: 'bold' }}>
              Beneficiary Bank Information
            </Typography>
            <BasicTable tableData={beneficiaryBankInfo} />
            <Typography variant="subtitle1" sx={{ mt: 2, mx: 2, fontWeight: 'bold' }}>
              Cash Out Form
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                my: 2
              }}>
              <Box component="img" src={usdDetails?.proofOfRequest} sx={{ height: 400, mt: 1 }} />
            </Box>
            {usdDetails?.proofOfBankAccountOwnership ? (
              <>
                <Typography variant="subtitle1" sx={{ mt: 2, mx: 2, fontWeight: 'bold' }}>
                  Proof Of Bank Ownership
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    my: 2
                  }}>
                  <Box
                    component="img"
                    src={usdDetails?.proofOfBankAccountOwnership}
                    sx={{ height: 400, mt: 1 }}
                  />
                </Box>
              </>
            ) : (
              ''
            )}
            <Typography variant="h5" sx={{ mt: 4 }}>
              Customer Confirmation
            </Typography>
            <BasicTable tableData={csCallConfirmationLink} />
            <ForexFinanceForm usdDetails={usdDetails} />
          </>
        )}
        <Divider sx={{ my: 2 }} />
        <Typography variant="h5">User Details</Typography>
        {userDetails && <BasicTable tableData={userDetailsTable} />}
        <Divider sx={{ my: 2 }} />
        <Typography variant="h5">Audit Trail</Typography>
        <Divider sx={{ my: 2 }} />
        {usdDetails && <BasicTable tableData={auditTrailTable} />}
      </CardContent>
    </Card>
  );
};

ForexCashoutViewForm.propTypes = {
  usdDetails: PropTypes.object,
  userDetails: PropTypes.object,
  adminDetails: PropTypes.object,
  auditTrail: PropTypes.object,
  uploadCsCallConfirmationLink: PropTypes.func,
  isButtonDisabled: PropTypes.bool
};

export default ForexCashoutViewForm;
