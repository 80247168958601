import Dialog from '@mui/material/Dialog';
import React from 'react';
import PropTypes from 'prop-types';
import { useStateValue } from 'utils/redux';
import { CLOSE_MODAL } from 'utils/redux/actions';

const Modal = ({ children }) => {
  const [appState, dispatch] = useStateValue();

  return (
    <Dialog maxWidth="xl" open={appState.showModal} onClose={() => dispatch({ type: CLOSE_MODAL })}>
      {children}
    </Dialog>
  );
};

Modal.propTypes = {
  children: PropTypes.node
};

export default Modal;
