import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ProductTable from './ProductTable';

const Products = ({ userPortfolio }) => {
  const [showProductDetail, setShowProductDetail] = useState(false);
  const [showCryptoAssets, setShowCryptoAssets] = useState(false);
  const [showGlobalEquities, setShowGlobalEquities] = useState(false);
  const [showMutualFunds, setShowMutualFunds] = useState(false);
  const [showMutualFundsIdr, setShowMutualFundsIdr] = useState(false);
  const [showUsStocksCfd, setShowUsStocksCfd] = useState(false);
  const [showGSS, setShowGSS] = useState(false);
  const [showGold, setShowGold] = useState(false);
  const [showForex, setShowForex] = useState(false);

  const forex = { forex: userPortfolio?.userCurrentPortfolio?.forex };

  return (
    <Accordion
      expanded={showProductDetail}
      onChange={() => setShowProductDetail((prevState) => !prevState)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h5">Product</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {Object.keys(userPortfolio?.userCurrentPortfolio?.cryptoPortfolio ?? {}).length > 1 ? (
          <ProductTable
            asset={userPortfolio?.userCurrentPortfolio?.cryptoPortfolio}
            keyField="cryptoTotalValues"
            open={showCryptoAssets}
            setOpen={setShowCryptoAssets}
            title="Crypto Assets"
          />
        ) : null}
        {Object.keys(userPortfolio?.userCurrentPortfolio?.stockIndexPortfolio ?? {}).length > 1 ? (
          <ProductTable
            asset={userPortfolio?.userCurrentPortfolio?.stockIndexPortfolio}
            currency="USD"
            keyField="stockIndexTotalValues"
            open={showGlobalEquities}
            setOpen={setShowGlobalEquities}
            title="US Index"
          />
        ) : null}
        {Object.keys(
          userPortfolio?.userCurrentPortfolio?.mutualFundPortfolio?.mutualFundsTotalValuesIdr ?? {}
        ).length > 1 ? (
          <ProductTable
            asset={userPortfolio?.userCurrentPortfolio?.mutualFundPortfolio}
            keyField="mutualFundsTotalValuesIdr"
            open={showMutualFundsIdr}
            setOpen={setShowMutualFundsIdr}
            title="Mutual Funds (IDR)"
          />
        ) : null}
        {Object.keys(
          userPortfolio?.userCurrentPortfolio?.mutualFundPortfolio?.mutualFundsTotalValues ?? {}
        ).length > 1 ? (
          <ProductTable
            asset={userPortfolio?.userCurrentPortfolio?.mutualFundPortfolio}
            currency="USD"
            keyField="mutualFundsTotalValues"
            open={showMutualFunds}
            setOpen={setShowMutualFunds}
            title="Mutual Funds (USD)"
          />
        ) : null}
        {Object.keys(userPortfolio?.userCurrentPortfolio?.globalStockPortfolio ?? {}).length > 1 ? (
          <ProductTable
            asset={userPortfolio?.userCurrentPortfolio?.globalStockPortfolio}
            currency="USD"
            keyField="usStocksTotalValues"
            open={showGSS}
            setOpen={setShowGSS}
            title="US Equities (GSS)"
          />
        ) : null}
        {Object.keys(userPortfolio?.userCurrentPortfolio?.globalStockCfdPortfolio ?? {}).length >
        1 ? (
          <ProductTable
            asset={userPortfolio?.userCurrentPortfolio?.globalStockCfdPortfolio}
            currency="USD"
            keyField="usStocksCfdTotalValues"
            open={showUsStocksCfd}
            setOpen={setShowUsStocksCfd}
            title="US Stocks CFD"
          />
        ) : null}
        {Object.keys(userPortfolio?.userCurrentPortfolio?.goldPortfolio ?? {}).length > 0 ? (
          <ProductTable
            asset={userPortfolio?.userCurrentPortfolio?.goldPortfolio}
            open={showGold}
            setOpen={setShowGold}
            title="Gold"
          />
        ) : null}
        {Object.keys(userPortfolio?.userCurrentPortfolio?.forex ?? {}).length > 0 ? (
          <ProductTable
            asset={forex}
            open={showForex}
            setOpen={setShowForex}
            title="Forex / USD Wallet"
          />
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};

Products.propTypes = {
  userPortfolio: PropTypes.object
};

export default Products;
