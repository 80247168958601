import React, { useState } from 'react';
import PropTypes from 'prop-types';
import KycTask from 'components/molecules/KycTask';
import S3DownloadLink from 'components/molecules/s3DownloadLink';
import { dateFormatter } from 'helpers/dateChanger';
import KycTaskTable from 'components/molecules/KycTaskTable';
import { disclosureTncTableHeaders } from './constants';
import { Box } from '@mui/system';

const DisclosureTnc = ({ gssKycData }) => {
  const [showDetail, setShowDetail] = useState(false);

  const disclosureTncTableValues = [
    (gssKycData.disclosureTnc && gssKycData.disclosureTnc.toString()) || null,
    gssKycData.cddFuturesBrokerageFirm ? (
      <S3DownloadLink url={gssKycData.cddFuturesBrokerageFirm} />
    ) : (
      ''
    ),
    gssKycData.cddStatementOfSimulation ? (
      <S3DownloadLink url={gssKycData.cddStatementOfSimulation} />
    ) : (
      ''
    ),
    gssKycData.cddTransactionAccountOpening ? (
      <S3DownloadLink url={gssKycData.cddTransactionAccountOpening} />
    ) : (
      ''
    ),
    gssKycData.cddNoticeOfRisk ? <S3DownloadLink url={gssKycData.cddNoticeOfRisk} /> : '',
    gssKycData.cddStatementOfResponsibility ? (
      <S3DownloadLink url={gssKycData.cddStatementOfResponsibility} />
    ) : (
      ''
    ),
    gssKycData.cddMandateAgreement ? <S3DownloadLink url={gssKycData.cddMandateAgreement} /> : '',
    gssKycData.cddTermsConditions ? <S3DownloadLink url={gssKycData.cddTermsConditions} /> : '',
    gssKycData.lastUploadTime ? dateFormatter(gssKycData.lastUploadTime) : '',
    gssKycData.updatedAt ? dateFormatter(gssKycData.updatedAt) : ''
  ];

  const kycTaskStatus = () => (
    <Box style={{ color: 'green' }}>{gssKycData.disclosureTnc ? 'COMPLETED' : ''}</Box>
  );

  const handleKycTaskClick = () => setShowDetail(!showDetail);

  const isTaskDisabled = gssKycData.status === 'NOT UPLOADED' || !gssKycData.disclosureTnc;

  return (
    <>
      {Object.keys(gssKycData).length ? (
        <>
          <KycTask
            title="Disclosure"
            status={kycTaskStatus()}
            onClick={handleKycTaskClick}
            disabled={isTaskDisabled}
            open={showDetail}>
            <KycTaskTable headers={disclosureTncTableHeaders} values={disclosureTncTableValues} />
          </KycTask>
        </>
      ) : null}
    </>
  );
};

DisclosureTnc.propTypes = {
  gssKycData: PropTypes.object
};

export default DisclosureTnc;
