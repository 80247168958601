import Config from 'config';

export const filter = [
  {
    id: 'id',
    type: 'number',
    label: 'Transaction Id'
  },
  {
    id: 'switchingId',
    type: 'number',
    label: 'Switching Id'
  },
  {
    id: 'status',
    type: 'dropdown',
    label: 'Status',
    default: 'all',
    option: [
      { text: 'Status-All', value: 'all' },
      { text: 'Unpaid', value: 'UNPAID' },
      { text: 'Waiting to be processed', value: 'WAITING_TO_BE_PROCESSED' },
      { text: 'Processing By S-Invest', value: 'PROCESSING_BY_S_INVEST' },
      { text: 'Pending', value: 'PENDING' },
      { text: 'Approved', value: 'APPROVED' },
      { text: 'Failed', value: 'FAILED' },
      { text: 'Expired', value: 'EXPIRED' }
    ]
  },
  {
    id: 'fundCode',
    type: 'dropdown',
    label: 'Fund Name',
    default: '',
    option: [{ text: 'Funds-All', value: '' }]
  },
  {
    id: 'paymentChannel',
    type: 'dropdown',
    label: 'Channel',
    default: 'all',
    option: [
      { text: 'Channel-All', value: 'all' },
      { text: 'GoPay', value: 'gopay wallet' },
      { text: 'Bank Transfer', value: 'bank transfer' },
      { text: 'Virtual Account', value: 'virtual account' }
    ]
  },
  {
    id: 'clientId',
    type: 'dropdown',
    label: 'Platform',
    default: 'all',
    option: [
      { text: 'Platform - all', value: 'all' },
      { text: 'Pluang', value: Config.PLUANG_CLIENT_ID },
      { text: 'Go Invest', value: Config.GOINVEST_CLIENT_ID }
    ]
  },
  {
    id: 'transactionType',
    type: 'dropdown',
    label: 'TransactionType',
    default: 'all',
    option: [
      { text: 'Trx.Type - All', value: 'all' },
      { text: 'Buy', value: 'buy' },
      { text: 'Sell', value: 'sell' },
      { text: 'Switch-In', value: 'switch_in' },
      { text: 'Switch-Out', value: 'switch_out' }
    ]
  },
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];
