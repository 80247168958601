import { CalendarMonth } from '@mui/icons-material';
import { Button, Grid, Paper, Popover } from '@mui/material';
import { format, isThisMonth } from 'date-fns';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Month from './Month';

const Menu = (props) => {
  const {
    dateRange,
    initialDateRange,
    minDate,
    maxDate,
    firstMonth,
    setFirstMonth,
    secondMonth,
    setSecondMonth,
    helpers,
    handlers
  } = props;
  const startDate =
    dateRange.startDate && dateRange.endDate
      ? format(dateRange.startDate, 'dd-MM-yyyy')
      : format(initialDateRange.startDate, 'dd-MM-yyyy');
  const endDate =
    dateRange.startDate && dateRange.endDate
      ? format(dateRange.endDate, 'dd-MM-yyyy')
      : format(initialDateRange.endDate, 'dd-MM-yyyy');
  const canNavigateForward = !isThisMonth(secondMonth);
  const commonProps = { dateRange, minDate, maxDate, helpers, handlers };
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Button
        aria-describedby={id}
        size="small"
        variant="outlined"
        onClick={handleClick}
        endIcon={<CalendarMonth />}>
        {startDate} - {endDate}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <Paper elevation={5} square>
          <Grid container direction="row" wrap="nowrap">
            <Month
              {...commonProps}
              value={firstMonth}
              setValue={setFirstMonth}
              navState={[true, false]}
            />
            <Month
              {...commonProps}
              value={secondMonth}
              setValue={setSecondMonth}
              navState={[false, canNavigateForward]}
            />
          </Grid>
        </Paper>
      </Popover>
    </>
  );
};

Menu.propTypes = {
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  dateRange: PropTypes.object,
  initialDateRange: PropTypes.object,
  firstMonth: PropTypes.instanceOf(Date),
  secondMonth: PropTypes.instanceOf(Date),
  setFirstMonth: PropTypes.func,
  setSecondMonth: PropTypes.func,
  helpers: PropTypes.object,
  handlers: PropTypes.object
};

export default Menu;
