import { Chip } from '@mui/material';
import PropTypes from 'prop-types';

const StatusChip = ({ color, label }) => {
  return <Chip sx={{ bgcolor: `${color}.100`, color: `${color}.700` }} label={label} />;
};

StatusChip.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string
};

export default StatusChip;
