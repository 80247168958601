import { Route, Routes } from 'react-router-dom';
import UserInsuranceEdit from './edit';
import UserInsuranceList from './list';

const OpsUserInsurance = () => {
  return (
    <Routes>
      <Route path="/" element={<UserInsuranceList />} />
      <Route path="/edit/:userInsuranceId" element={<UserInsuranceEdit />} />
    </Routes>
  );
};

export default OpsUserInsurance;
