import PluangListPage from 'components/organisms/pluangListTable';

const filter = [
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];

const headers = [
  { header_name: 'Asset', db_column_name: 'asset' },
  { header_name: 'Amount Type', db_column_name: 'autoDebitAmountType' },
  { header_name: 'Platform', db_column_name: 'clientId' },
  { header_name: 'Status', db_column_name: 'status' },
  { header_name: 'Active', db_column_name: 'active' },
  { header_name: 'Updated At', db_column_name: 'updated' },
  { header_name: 'Action', db_column_name: 'action', isView: 'true' }
];

const AutoDebitList = () => {
  return (
    <PluangListPage title="Auto Debit" headers={headers} filters={filter} resource="AUTO_INVEST" />
  );
};

export default AutoDebitList;
