import { Route, Routes } from 'react-router-dom';
import OpsUserBankAccountEdit from './edit';
import OpsUserBankAccountList from './list';

const OpsUserBankAccount = () => {
  return (
    <Routes>
      <Route path="/" element={<OpsUserBankAccountList />} />
      <Route path="/edit/:userBankId" element={<OpsUserBankAccountEdit />} />
    </Routes>
  );
};

export default OpsUserBankAccount;
