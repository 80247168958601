import React from 'react';
import PluangListPage from 'components/organisms/pluangListTable';
import { filters, headers } from './constants';

const GoldSendTransactionList = (props) => {
  return (
    <PluangListPage
      {...props}
      title={'Gold Send Transactions'}
      headers={headers}
      filters={filters}
      resource={'GOLD_SEND_TRANSACTION'}
    />
  );
};

export default GoldSendTransactionList;
