export const filters = [
  {
    id: 'status',
    type: 'dropdown',
    label: 'Status',
    default: 'all',
    option: [
      { text: 'Status - all', value: 'all' },
      { text: 'Pending', value: 'PENDING' },
      { text: 'Accepted', value: 'ACCEPTED' },
      { text: 'Rejected', value: 'REJECTED' },
      { text: 'Cancelled', value: 'CANCELLED' },
      { text: 'Expired', value: 'EXPIRED' }
    ]
  },
    {
      id: 'date',
      type: 'dateRange',
      label: 'DateFilter'
    }
];

export const headers = [
  { header_name: 'Created', db_column_name: 'created' },
  { header_name: 'Receiver Name', db_column_name: 'receiverName' },
  { header_name: 'Amount (in grams)', db_column_name: 'quantity' },
  { header_name: 'Status', db_column_name: 'status' }
];
