import { Card, CardContent, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import { IMAGES } from 'constants/images';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSfId } from 'services/AuthService';
import { useStateValue } from 'utils/redux';

const Welcome = () => {
  const [appState] = useStateValue();
  const navigate = useNavigate();

  useEffect(() => {
    if (getSfId()) {
      navigate(-1);
    }
  });

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        backgroundImage: `url(${IMAGES.PLUANG_LOGIN_BG})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}>
      <Container sx={{ width: '540px' }}>
        <Card sx={{ borderRadius: 2 }}>
          <CardContent
            sx={{
              position: 'relative'
            }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
              <img src={IMAGES.PLUANG_LOGIN_LOGO} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
              <Typography variant="h6">Welcome {appState?.user?.name}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
              <Typography>Your salesforceId will be provided by the Admin Agent</Typography>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default Welcome;
