import { Route, Routes } from 'react-router-dom';
import ForexTopupList from './list';
import ForexTopupView from './view';

const ForexTopup = () => {
  return (
    <Routes>
      <Route path="/" element={<ForexTopupList />} />
      <Route path="view/:id" element={<ForexTopupView />} />
    </Routes>
  );
};

export default ForexTopup;
