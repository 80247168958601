export const filters = [
  {
    id: 'status',
    type: 'dropdown',
    label: 'Status',
    default: 'all',
    option: [
      { text: 'All', value: 'all' },
      { text: 'Claimed', value: 'CLAIMED' },
      { text: 'Ready To Claim', value: 'READY_TO_CLAIM' },
      { text: 'Pending', value: 'PENDING' }
    ]
  }
];

export const headers = [
  { header_name: 'User Type', db_column_name: 'userType' },
  { header_name: 'Referral Code', db_column_name: 'referralCode' },
  { header_name: 'Reward Amount', db_column_name: 'rewardAmount', formattedCurrency: true },
  { header_name: 'Friend UserName', db_column_name: 'oppUserName' },
  { header_name: 'Status', db_column_name: 'status' },
  { header_name: 'Updated At', db_column_name: 'updated' }
];
