import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          letterSpacing: 0.1,
          textTransform: 'capitalize'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 12,
          minHeight: 32
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 12
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          minHeight: 32
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: 20
        }
      }
    }
  }
});

export default theme;
