import React, { useEffect, useState } from 'react';
import { REMOTE } from 'constants/serverRoutes';
import { makeRequest } from 'services/APIService';
import { dateFormatter } from 'helpers/dateChanger';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Config from 'config';
import { useParams } from 'react-router-dom';
import { useStateValue } from 'utils/redux';
import Container from 'components/atoms/Container';
import Item from 'components/atoms/Item';
import { Box } from '@mui/system';
import KycTaskTable from 'components/molecules/KycTaskTable';
const PAGE_SIZE = 5;

const InstallmentView = () => {
  const [installmentState, setInstallmentState] = useState({
    loanData: {},
    paymentsData: {
      activePage: 1,
      data: [],
      totalPages: 0,
      totalElements: 0
    },
    isLoading: false
  });
  const [appState] = useStateValue();
  const params = useParams();

  useEffect(async () => {
    if (params.loanId && appState.sfId) {
      await getLoan(params.loanId);
      await getPayments();
    }
  }, [appState.sfId]);

  const handlePageChange = (pageNumber) => {
    setInstallmentState((prevState) => ({
      ...prevState,
      paymentsData: {
        ...prevState.paymentsData,
        activePage: pageNumber
      }
    }));
    getPayments(pageNumber);
  };

  const getLoan = (loanId) => {
    let query = {
      loanId,
      salesForceId: appState.sfId
    };
    return makeRequest({
      url: `${REMOTE.LOANS}`,
      params: query
    }).then((result) => {
      if (result.data && result.data.data && result.data.data.length === 1) {
        let loan = result.data.data[0];
        setInstallmentState((prevState) => ({
          ...prevState,
          loanData: loan
        }));
      }
    });
  };

  const getPayments = (page = 1) => {
    let query = {
      page: page,
      limit: PAGE_SIZE
    };
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    return makeRequest({
      url: `${REMOTE.LOAN_PAYMENTS}/${params.loanId}`,
      params: query
    }).then((result) => {
      if (result.data && result.data.data) {
        setInstallmentState((prevState) => ({
          ...prevState,
          paymentsData: {
            activePage: result.data.current_page,
            data: result.data.data,
            totalPages: result.data.totalPages,
            totalElements: result.data.totalElements
          }
        }));
      }
    });
  };

  const { loanData, paymentsData } = installmentState;

  const installmentHeaders = [
    'Name',
    'Date Of Birth',
    'Email',
    'Tenure',
    'Installment',
    'Total Loan',
    'Total Gold Price',
    'Down payment',
    'Gold Frozen',
    'Status'
  ];

  const installmentValues = [
    loanData.user_name,
    loanData.user_dateOfBirth,
    loanData.user_email,
    loanData.tenure,
    loanData.monthlyInstallment,
    loanData.totalInstallment,
    loanData.totalGoldPrice,
    loanData.downPayment,
    loanData.goldFrozen,
    loanData.status
  ];

  return (
    <>
      <Container
        sx={{
          marginBottom: '10px'
        }}>
        <Item>
          <Typography variant="h6" fontWeight="bold">
            Installment Detail
          </Typography>
        </Item>
      </Container>
      <KycTaskTable headers={installmentHeaders} values={installmentValues} />
      {(loanData.status === 'CANCELLED' ||
        (loanData.status === 'WAITING_FOR_CANCEL' &&
          loanData.partnerId !== Config.PLUANG_PARTNER_ID)) && (
        <Box
          sx={{
            width: '400px',
            margin: '0 auto',
            background: '#f0f8ff',
            padding: '8px',
            border: '2px #dff0ff dashed',
            marginBottom: '10px'
          }}>
          <Container alignItems="center" justifyContent="center">
            <Item>
              <Typography variant="h6">
                <strong>CashBack Amount:</strong>
                <Box
                  display={'inline'}
                  sx={{
                    fontSiz: '20px',
                    color: '#0070d1',
                    fontWeight: '700',
                    paddingLeft: '8px'
                  }}>
                  {loanData.cashBack}
                </Box>
              </Typography>
            </Item>
          </Container>
        </Box>
      )}
      <Container
        sx={{
          marginBottom: '10px'
        }}>
        <Item>
          <Typography variant="h6" fontWeight="bold">
            Loan Payments
          </Typography>
        </Item>
      </Container>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Payment Date</TableCell>
            <TableCell>Installment</TableCell>
            <TableCell>Fine</TableCell>
            <TableCell>Fee</TableCell>
            <TableCell>Money Paid</TableCell>
            <TableCell>Payment Channel</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentsData.totalElements > 0 ? (
            paymentsData.data.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  '&:nth-of-type(odd)': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)'
                  },
                  '&:last-child td, &:last-child th': {
                    border: 0
                  }
                }}>
                <TableCell key={row.id + '_status'}>{row.status}</TableCell>
                <TableCell key={row.id + '_dueDate'}>{dateFormatter(row.dueDate)}</TableCell>
                <TableCell key={row.id + '_datePaidOn'}>{dateFormatter(row.datePaidOn)}</TableCell>
                <TableCell key={row.id + '_installment'}>{row.installment}</TableCell>
                <TableCell key={row.id + '_moneyFine'}>{row.moneyFine || 0}</TableCell>
                <TableCell key={row.id + '_moneyFee'}>{row.moneyFee || 0}</TableCell>
                <TableCell key={row.id + '_moneyPaid'}>{row.moneyPaid}</TableCell>
                <TableCell key={row.id + '_paymentChannel'}>
                  {row.paymentChannel || 'pending payment'}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7}>No Data Found</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Container>
        <Item xs={12}>
          <Box
            sx={{
              float: 'right'
            }}>
            {paymentsData.totalPages > 1 && (
              <Pagination
                color="primary"
                page={installmentState.paymentsData.activePage}
                shape="rounded"
                showFirstButton
                showLastButton
                siblingCount={1}
                count={installmentState.paymentsData.totalPages}
                onChange={(e, v) => handlePageChange(v)}
              />
            )}
          </Box>
        </Item>
      </Container>
    </>
  );
};

export default InstallmentView;
