import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Proptypes from 'prop-types';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, redirect: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (this.state.hasError) {
      setTimeout(() => this.setState({ redirect: true }), 5000);
    }
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to="/ops/users " />;
    }
    if (this.state.hasError) {
      return (
        <h1>
          There was an error with this listing. <Link to="/ops/users">Click here</Link> to back to
          the home page or wait five seconds.
        </h1>
      );
    }
    return this.props?.children || null;
  }
}

ErrorBoundary.propTypes = {
  children: Proptypes.node
};

export default ErrorBoundary;
