import { REMOTE } from 'constants/serverRoutes';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeRequest } from 'services/APIService';
import { useLoader } from 'services/hooks';
import { useStateValue } from 'utils/redux';
import ForexCashoutViewForm from './ForexCashoutViewForm';

const ForexCashoutView = () => {
  const [appState] = useStateValue();
  const [usdDetails, setUsdDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [adminDetails, setAdminDetails] = useState({});
  const [auditTrail, setAuditTrail] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const [, setLoader] = useLoader();

  useEffect(() => {
    if (id && appState.sfId) {
      getUSDDetails(id);
    }
  }, [appState.sfId]);

  const getUSDDetails = (id) => {
    setLoader(true);
    makeRequest({
      url: `${REMOTE.USD_CASHOUTS}/${id}`
    })
      .then((result) => {
        if (result?.data?.data.length === 1) {
          setUsdDetails(result.data.data[0]);
          getUser(result.data.data[0].userId);
          const { processedStateDetails } = result.data.data[0].stateTransitionDetail;
          if (processedStateDetails && processedStateDetails.length) {
            getAdmins(result.data.data[0].stateTransitionDetail);
            let auditTrail = {};
            processedStateDetails.forEach((auditDetails) => {
              if (auditDetails && auditDetails.status) {
                auditTrail[auditDetails.status] = auditDetails;
              }
            });
            setAuditTrail(auditTrail);
          }
        }
      })
      .catch(() => {
        navigate(-1);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getUser = () => {
    makeRequest({
      url: `${REMOTE.USER}/${appState.sfId}`,
      params: {
        salesForceId: appState.sfId
      }
    }).then((result) => {
      if (result.data && result.data.content) {
        setUserDetails(result.data.content[0]);
      }
    });
  };

  const getAdmins = (data) => {
    const { processedStateDetails, recheckRequesterId } = data;
    const adminIds = [];
    if (processedStateDetails && processedStateDetails.length) {
      processedStateDetails.forEach((ids) => {
        if (ids && ids.processedBy) {
          adminIds.push(ids.processedBy);
        }
      });
    }
    if (recheckRequesterId) {
      adminIds.push(recheckRequesterId);
    }

    setLoader(true);
    makeRequest({
      method: 'GET',
      url: `${REMOTE.ADMIN}/getByAdminIds`,
      params: {
        adminIds
      }
    })
      .then((result) => {
        if (result && Object.keys(result.data)) {
          setAdminDetails(result.data);
        }
      })
      .catch(() => setAdminDetails({}))
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <ForexCashoutViewForm
      usdDetails={usdDetails}
      userDetails={userDetails}
      adminDetails={adminDetails}
      auditTrail={auditTrail}
    />
  );
};

export default ForexCashoutView;
