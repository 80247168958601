import PluangListPage from 'components/organisms/pluangListTable';
import { BCA_CREDIT_NOTIFICATION_STATUS } from '../../constants';

const filters = [
  {
    id: 'referenceNumber',
    type: 'text',
    label: 'Reference Number'
  },
  {
    id: 'status',
    type: 'dropdown',
    label: 'Status Type',
    default: '',
    options: [
      { text: 'All', value: '' },
      { text: 'Assigned', value: BCA_CREDIT_NOTIFICATION_STATUS.ASSIGNED },
      { text: 'Unassigned', value: BCA_CREDIT_NOTIFICATION_STATUS.UNASSIGNED },
      { text: 'In Verification', value: BCA_CREDIT_NOTIFICATION_STATUS.IN_VERIFICATION },
      { text: 'Ready to Refund', value: BCA_CREDIT_NOTIFICATION_STATUS.READY_TO_REFUND },
      { text: 'Refunded', value: BCA_CREDIT_NOTIFICATION_STATUS.REFUNDED },
      { text: 'Internal Transfer', value: BCA_CREDIT_NOTIFICATION_STATUS.INTERNAL_TRANSFER }
    ]
  },
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  },
  {
    type: 'exportExcel'
  }
];

const headers = [
  { header_name: 'Credit Notification Id', db_column_name: 'tableId' },
  { header_name: 'Reference Number', db_column_name: 'referenceNumber' },
  { header_name: 'Transaction Date', db_column_name: 'transactionDate' },
  { header_name: 'Transaction Time', db_column_name: 'transactionTime' },
  { header_name: 'Amount', db_column_name: 'amount', formattedCurrency: true },
  { header_name: 'Status', db_column_name: 'status' },
  { header_name: 'Action', db_column_name: 'action', isView: 'true' }
];

const CreditNotificationList = () => {
  return (
    <PluangListPage
      title="BCA Credit Notification"
      filters={filters}
      headers={headers}
      resource="BCA_CREDIT_NOTIFICATIONS"
    />
  );
};

export default CreditNotificationList;
