import { Route, Routes } from 'react-router-dom';
import ForexCashoutList from './list';
import ForexCashoutView from './view';

const ForexCashout = () => {
  return (
    <Routes>
      <Route path="/" element={<ForexCashoutList />} />
      <Route path="view/:id" element={<ForexCashoutView />} />
    </Routes>
  );
};

export default ForexCashout;
