import PluangListPage from 'components/organisms/pluangListTable';

const filters = [
  {
    id: 'status',
    type: 'dropdown',
    label: 'Status',
    default: '',
    options: [
      { text: 'All', value: '' },
      { text: 'INITIATED', value: 'INITIATED' },
      { text: 'VERIFIED', value: 'VERIFIED' },
      { text: 'COMPLETED', value: 'COMPLETED' },
      { text: 'FAILED', value: 'FAILED' },
      { text: 'APPROVED', value: 'APPROVED' }
    ]
  }
];

const headers = [
  { header_name: 'Status', db_column_name: 'status' },
  { header_name: 'Withdrawal Amount', db_column_name: 'withdrawalAmount' },
  { header_name: 'Created At', db_column_name: 'created' },
  { header_name: 'Last Updated', db_column_name: 'updated' },
  { header_name: 'Action', db_column_name: 'action', isView: true }
];

const ForexCashoutList = () => {
  return (
    <PluangListPage
      title="Forex Cashouts"
      headers={headers}
      filters={filters}
      filterDateRange={14}
      resource="USD_CASHOUTS"
    />
  );
};

export default ForexCashoutList;
