const Config = {};
Config.MAINTENANCE_ERROR = 'Down due to maintenance.';
Config.PLUANG_CLIENT_ID = 3;
Config.GOINVEST_CLIENT_ID = 6;
Config.PLUANG_SAHAM_CLIENT_ID = 21;
Config.PLUANG_WEB_CLIENT_ID = 9;
Config.CASHOUT_TABLE = 'cashouts';
Config.ColorMap = {
  forex_transactions: '#bf9da8',
  crypto_currency_transactions: '#69cdab',
  stock_index_transactions: '#d395de',
  gold_transactions: '#eae780',
  fund_transactions: '#2db7e0'
};
Config.NATIONALITIES = {
  INDONESIA: 'Indonesia'
};

module.exports = Config;
