export const FOREX_TOPUP_STATUS = Object.freeze({
  INITIATED: 'INITIATED',
  VERIFIED: 'VERIFIED',
  EXPIRED: 'EXPIRED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  RECHECK: 'RECHECK'
});

export const FOREX_CASHOUT_STATUS = Object.freeze({
  INITIATED: 'INITIATED',
  VERIFIED: 'VERIFIED',
  EXPIRED: 'EXPIRED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  APPROVED: 'APPROVED'
});
