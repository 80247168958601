import { Box, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const Day = (props) => {
  const {
    disabled,
    filled,
    highlighted,
    outlined,
    startOfRange,
    endOfRange,
    onClick,
    onHover,
    value
  } = props;

  return (
    <Box
      sx={{
        backgroundColor: !disabled && highlighted && 'lightgray',
        borderStartStartRadius: startOfRange && '50%',
        borderEndStartRadius: startOfRange && '50%',
        borderEndEndRadius: endOfRange && '50%',
        borderStartEndRadius: endOfRange && '50%'
      }}>
      <IconButton
        disabled={disabled}
        onClick={onClick}
        onMouseOver={onHover}
        sx={{
          height: 36,
          width: 36,
          '&:hover': {
            backgroundColor: !disabled && filled && 'gray'
          },
          backgroundColor: !disabled && filled && 'gray',
          border: !disabled && outlined && '1px solid gray'
        }}>
        <Typography
          color={!disabled ? 'initial' : 'textSecondary'}
          sx={{ color: !disabled && filled && 'white' }}
          variant="body2">
          {value}
        </Typography>
      </IconButton>
    </Box>
  );
};

Day.propTypes = {
  disabled: PropTypes.bool,
  filled: PropTypes.bool,
  highlighted: PropTypes.bool,
  outlined: PropTypes.bool,
  startOfRange: PropTypes.bool,
  endOfRange: PropTypes.bool,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  value: PropTypes.number
};

export default Day;
