import { Route, Routes } from 'react-router-dom';
import ForexCashout from './forexCashout';
import ForexTopup from './forexTopup';

const OpsUsdDeposit = () => {
  return (
    <Routes>
      <Route path="/topup/*" element={<ForexTopup />} />
      <Route path="/cashout/*" element={<ForexCashout />} />
    </Routes>
  );
};

export default OpsUsdDeposit;
