import { Route, Routes } from 'react-router-dom';
import CreditNotificationList from './list';
import CreditNotificationView from './view';

const FiCreditNotification = () => {
  return (
    <Routes>
      <Route path="/" element={<CreditNotificationList />} />
      <Route path="view/:id" element={<CreditNotificationView />} />
    </Routes>
  );
};

export default FiCreditNotification;
