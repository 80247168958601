import { AppBar, Button, Toolbar, Typography } from '@mui/material';
import React from 'react';
import Container from 'components/atoms/Container';
import { IMAGES } from 'constants/images';
import { logout } from 'services/AuthService';
import { useStateValue } from 'utils/redux';
import { USER_STATE } from 'utils/redux/actions';
import { useNotification } from 'services/hooks';

const Header = () => {
  const [appState, dispatch] = useStateValue();
  const { showNotification } = useNotification();
  const { user } = appState;
  const handleLogout = () => {
    logout().then(() => {
      dispatch({
        type: USER_STATE,
        user: null,
        loggedIn: false,
        sfId: null
      });
      window.sessionStorage.removeItem('salesForceId');
      showNotification('success', 'Successfully Logged Out');
    });
  };

  return (
    <AppBar
      position="fixed"
      sx={{ backgroundColor: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Container sx={{ alignItems: 'center' }}>
          <img src={IMAGES.PLUANG_LOGO} alt="" />
          <Typography variant="subtitle1" sx={{ color: 'black', ml: 12 }}>
            {user?.name} - {user?.id}
          </Typography>
        </Container>
        <Button color="primary" size="small" variant="outlined" onClick={handleLogout}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
