import PluangListPage from 'components/organisms/pluangListTable';

const filter = [
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];
const headers = [
  { header_name: 'Insurance Id', db_column_name: 'id' },
  { header_name: 'Insurance Type', db_column_name: 'insuranceType' },
  { header_name: 'Status', db_column_name: 'status' },
  { header_name: 'Created At', db_column_name: 'created' },
  { header_name: 'Last Updated', db_column_name: 'updated' },
  { header_name: 'Action', db_column_name: 'action', isEdit: 'true' }
];

const templateUrl =
  'https://pluang-production-uploads.s3-ap-southeast-1.amazonaws.com/insurance/insuranceUploadTemplate.csv';

const UserInsuranceList = () => {
  return (
    <PluangListPage
      title="User Insurance List"
      headers={headers}
      filterDateRange={30}
      filters={filter}
      resource="USER_INSURANCE"
      isUploadFile
      uploadTemplate={templateUrl}
    />
  );
};

export default UserInsuranceList;
