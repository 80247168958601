import React from 'react';
import PluangListPage from 'components/organisms/pluangListTable';
import { filters, headers } from './constants';

const GoldTransaction = (props) => {
  return (
    <PluangListPage
      {...props}
      title="Gold Transactions"
      headers={headers}
      filters={filters}
      resource="TRANSACTIONS"
    />
  );
};

export default GoldTransaction;
