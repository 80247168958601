import React from 'react';
import PluangListPage from 'components/organisms/pluangListTable';

const filters = [
  {
    id: 'partnerId',
    type: 'typeAhead',
    label: 'Partner Name',
    resource: 'PARTNER',
    labelFields: ['companyName']
  },
  {
    id: 'loanStatus',
    type: 'dropdown',
    label: 'Status',
    default: 'all',
    option: [
      { text: 'All', value: 'all' },
      { text: 'PENDING', value: 'PENDING' },
      { text: 'EXPIRED', value: 'EXPIRED' },
      { text: 'APPROVED', value: 'APPROVED' },
      { text: 'REJECTED', value: 'REJECTED' },
      { text: 'PAID_OFF', value: 'PAID_OFF' },
      { text: 'WAITING_FOR_CANCEL', value: 'WAITING_FOR_CANCEL' },
      { text: 'CANCELLED', value: 'CANCELLED' },
      { text: 'REQUEST_DEFAULT', value: 'REQUEST_DEFAULT' },
      { text: 'DEFAULT', value: 'DEFAULT' },
      { text: 'ACCEPTED', value: 'ACCEPTED' }
    ]
  },
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];

const headers = [
  { header_name: 'Ref. No', db_column_name: 'partnerReference' },
  { header_name: 'Partner Id', db_column_name: 'partnerId' },
  { header_name: 'Loan Amount', db_column_name: 'goldLoanAmount', formattedCurrency: true },
  { header_name: 'Tenure', db_column_name: 'tenure' },
  { header_name: 'Gold Frozen', db_column_name: 'goldFrozen' },
  { header_name: 'Cicilan', db_column_name: 'monthlyInstallment', formattedCurrency: true },
  { header_name: 'Status', db_column_name: 'status' },
  { header_name: 'Created At', db_column_name: 'created' },
  { header_name: 'Action', db_column_name: 'action', isView: 'true' }
];

const InstallmentList = () => {
  return (
    <PluangListPage
      title={'Installment List'}
      headers={headers}
      filters={filters}
      resource={'LOANS'}
    />
  );
};

export default InstallmentList;
