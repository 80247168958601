import { LOCAL } from 'constants/clientRoutes';
import { validateUuId } from 'helpers/validators';
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useStateValue } from 'utils/redux';
import { getSfId } from 'services/AuthService';

const RequireAuth = () => {
  const [appState] = useStateValue();
  let location = useLocation();
  const paths = location?.pathname.split('/');
  const id = paths[paths.length - 1];

  if (!getSfId() && id && validateUuId(id) === null) {
    location.pathname = LOCAL.WELCOME;
  }
  return appState.loggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={LOCAL.LOGIN} state={{ from: location }} replace />
  );
};

export default RequireAuth;
