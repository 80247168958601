export const filters = [
  {
    id: 'bankCode',
    type: 'dropdown',
    label: 'Rdn-Bank',
    default: 'all',
    option: [
      { text: 'RDN - all', value: 'all' },
      { text: 'BCA', value: 'BCA' },
      { text: 'JAGO', value: 'JAGO' }
    ]
  },
  {
    id: 'status',
    type: 'dropdown',
    label: 'Status',
    default: 'all',
    option: [
      { text: 'Status - all', value: 'all' },
      { text: 'Pending', value: 'PENDING' },
      { text: 'Approved', value: 'APPROVED' },
      { text: 'Rejected', value: 'REJECTED' },
      { text: 'Initiated', value: 'INITIATED' },
      { text: 'Completed', value: 'COMPLETED' },
      { text: 'Failed', value: 'FAILED' }
    ]
  }
];

export const headers = [
  { header_name: 'Status', db_column_name: 'status' },
  { header_name: 'Amount', db_column_name: 'amount' },
  { header_name: 'Bank Name', db_column_name: 'bankName' },
  { header_name: 'Bank Account Number', db_column_name: 'accountNumber' },
  { header_name: 'Rdn Bank Account Name', db_column_name: 'rdnBankAccountName' },
  { header_name: 'Created', db_column_name: 'created' },
  { header_name: 'Updated', db_column_name: 'updated' }
];
