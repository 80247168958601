/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PluangListPage from 'components/organisms/pluangListTable';
import { makeRequest } from 'services/APIService';
import { REMOTE } from 'constants/serverRoutes';
import { TRANSACTION_STATUS_TYPE, CANCELLED_TYPE, filter } from './constant';
import { useLoader, useNotification } from 'services/hooks';

const GlobalSingleStockTransaction = (props) => {
  const { showNotification } = useNotification();
  const [, setLoader] = useLoader();
  const priceColumn = (row) => {
    if (row.orderType === 'MARKET') {
      return <>{row.unitPrice}</>;
    }
    if (row.orderType === 'LIMIT') {
      return <>{row.status}</>;
    }
    return '';
  };

  const statusColumn = (row) => {
    let cancelledType = '';

    if (row.cancelledType && row.cancelledType === CANCELLED_TYPE.AUTOMATIC) {
      cancelledType = 'by system';
    } else if (row.cancelledType && row.cancelledType === CANCELLED_TYPE.MANUAL) {
      cancelledType = 'by user';
    }

    return (
      <>
        {row.status === TRANSACTION_STATUS_TYPE.CANCELLED.toLowerCase()
          ? `cancelled ${cancelledType}`
          : row.status}
      </>
    );
  };

  const header = [
    { header_name: 'Order ID', db_column_name: 'id' },
    { header_name: 'Order Type', db_column_name: 'orderType' },
    { header_name: 'Trans Type', db_column_name: 'transactionType' },
    { header_name: 'Quantity', db_column_name: 'quantity' },
    { header_name: 'Unit Price', isCustom: true, customMethod: priceColumn },
    {
      header_name: 'Status',
      db_column_name: 'status',
      isCustom: true,
      customMethod: statusColumn
    },
    { header_name: 'Company Ticker', db_column_name: 'companyCode' },
    { header_name: 'Created', db_column_name: 'created' },
    { header_name: 'Updated', db_column_name: 'updated' }
  ];

  const [headers] = useState(header);

  const [filters, setFilters] = useState(filter);

  useEffect(() => {
    getAllGlobalSingleStocks();
  }, []);

  const getAllGlobalSingleStocks = () => {
    setLoader(true);
    makeRequest({
      url: `${REMOTE.GLOBAL_SINGLE_STOCK}/allGlobalSingleStocks`
    })
      .then((result) => {
        if (result && result.data && result.data.data) {
          const globalStocksFilters = result.data.data.map((globalStocks) => {
            return { text: globalStocks.companyCode, value: globalStocks.companyCode };
          });
          const companyCodeFilter = {
            id: 'companyCode',
            type: 'dropdown',
            label: 'Company Ticker',
            default: 'all',
            option: [{ text: 'Company Ticker', value: 'all' }, ...globalStocksFilters]
          };
          const newFilters = [...filters];
          newFilters[2] = companyCodeFilter;
          setFilters(newFilters);
        }
      })
      .catch((err) => {
        showNotification('error', err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <PluangListPage
      {...props}
      title={props.fixedQuery ? 'Global Single Stock Transactions PALN' : 'Global Single Stock Transactions CFD'}
      headers={headers}
      filters={filters}
      resource={'GLOBAL_SINGLE_STOCK_TRANSACTIONS'}
    />
  );
};

export default GlobalSingleStockTransaction;
