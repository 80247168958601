import React from 'react';
import PluangListPage from 'components/organisms/pluangListTable';
import { filter, header } from './constants';

const TopupList = () => {
  return (
    <PluangListPage title={'Topup List'} headers={header} filters={filter} resource={'TOPUP'} />
  );
};

export default TopupList;
