import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { REMOTE } from 'constants/serverRoutes';
import PluangTable from 'components/molecules/pluangTable';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useStateValue } from 'utils/redux';
import { makeRequest } from 'services/APIService';
import Filter from 'components/molecules/pageFilter';
import { useLoader } from 'services/hooks';

let PAGE_SIZE = 20;

const PluangListPage = ({ title, pageSize, headers, resource, fixedQuery, filters }) => {
  const [listState, setListState] = useState({
    activePage: 0,
    totalElements: 0,
    totalPages: 0,
    isButtonDisabled: false,
    data: [],
    query: '',
    searchData: false
  });

  const [appState] = useStateValue();
  const [, setLoader] = useLoader();

  useEffect(() => {
    if (appState.sfId) {
      handlePageChange(1);
    }
  }, [appState.sfId]);

  useEffect(() => {
    submitQuery();
  }, [listState.activePage, appState.sfId]);

  useEffect(() => {
    if (listState.isButtonDisabled) {
      getTableData();
    }
  }, [listState.isButtonDisabled]);

  const handlePageChange = (pageNumber) => {
    setListState((prevState) => ({
      ...prevState,
      activePage: pageNumber
    }));
  };

  const submitQuery = (q) => {
    if (appState.sfId) {
      setListState((prevState) => ({
        ...prevState,
        searchData: false
      }));
      let query = {};
      if (q) {
        query = q;
      }
      query.salesForceId = appState.sfId;
      if (listState && !listState.isButtonDisabled) {
        const page = (q && q.activePage) || 1;
        setListState((prevState) => ({
          ...prevState,
          isButtonDisabled: true,
          activePage: q ? page : prevState.activePage,
          searchData: true,
          query: {
            ...prevState.query,
            ...query
          }
        }));
      }
    }
  };

  const getTableData = () => {
    const page_size = pageSize || PAGE_SIZE;
    setLoader(true);
    makeRequest({
      url: `${REMOTE[resource]}/filter`,
      params: {
        page: listState.activePage,
        limit: page_size,
        ...fixedQuery,
        ...listState.query
      }
    })
      .then((result) => {
        if (result.data && result.data.data) {
          setListState((prevState) => ({
            ...prevState,
            data: result.data.data,
            totalPages: result.data?.pages?.totalPages || result.data?.data?.pages?.totalPages,
            totalElements: result.data.pages?.totalElements || result.data?.data?.pages?.totalElements
          }));
        }
      })
      .finally(() => {
        setLoader(false);
        setListState((prevState) => ({
          ...prevState,
          isButtonDisabled: false
        }));
      });
  };

  const { totalElements, data, totalPages } = listState;

  return (
    <>
      <Box>
        <Grid container>
          <Grid item>
            <Typography variant="h5">{title}</Typography>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ my: 2 }} />
      {filters && (
        <Filter
          onSubmit={submitQuery}
          filters={filters}
          isButtonDisabled={listState.isButtonDisabled}
          resource={resource}
        />
      )}
      <Divider sx={{ mt: 2 }} />
      <PluangTable
        headers={headers}
        roles={appState.user && appState.user.roles}
        data={data}
        totalElements={totalElements}
        totalPages={totalPages}
        pageSize={pageSize || PAGE_SIZE}
        activePage={listState.activePage}
        handlePageChange={handlePageChange.bind(this)}
      />
    </>
  );
};

PluangListPage.propTypes = {
  resource: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  filters: PropTypes.array,
  headers: PropTypes.array.isRequired,
  headerChild: PropTypes.func,
  parentRefreshMethod: PropTypes.func,
  isAddNew: PropTypes.bool,
  isAddNewForm: PropTypes.bool,
  newFormName: PropTypes.string,
  newFormSlug: PropTypes.string,
  approvalList: PropTypes.string,
  approvalListName: PropTypes.string,
  pageSize: PropTypes.number,
  isShowApprovalList: PropTypes.bool,
  approvalListSlug: PropTypes.string,
  isUploadFile: PropTypes.bool,
  uploadTemplate: PropTypes.string,
  fixedQuery: PropTypes.object,
  uploadFixedQuery: PropTypes.object,
  exportFixedQuery: PropTypes.object,
  uploadTemplateText: PropTypes.string,
  uploadButtonText: PropTypes.string,
  deactivateLocationSearch: PropTypes.bool,
  sfId: PropTypes.string
};

export default PluangListPage;
