import React from 'react';
import PluangListPage from 'components/organisms/pluangListTable';
import { rewardFilters, rewardHeaders } from '../constant.js';

const MissionRewardClaim = () => {
  return (
    <PluangListPage
      title="Mission Reward Claim"
      pageSize={20}
      headers={rewardHeaders}
      filters={rewardFilters}
      resource={'MISSION_REWARD_CLAIM'}
    />
  );
};

export default MissionRewardClaim;
