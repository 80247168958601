module.exports = {
  dateFormatter: (dateString) => {
    if (!dateString) {
      return;
    }
    let dateObj = new Date(dateString);
    const months = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC'
    ];
    return (
      dateObj.getDate() +
      '-' +
      months[dateObj.getMonth()] +
      '-' +
      dateObj.getFullYear() +
      ' ' +
      dateObj.getHours().toString().padStart(2, 0) +
      ':' +
      dateObj.getMinutes().toString().padStart(2, 0) +
      ':' +
      dateObj.getSeconds().toString().padStart(2, 0)
    );
  },
  birthDateFormatter: (dateString) => {
    if (!dateString) {
      return;
    }
    let dateObj = new Date(dateString);
    const months = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC'
    ];
    return dateObj.getDate() + '-' + months[dateObj.getMonth()] + '-' + dateObj.getFullYear();
  },
  capitalizeSnakeCase: (text) => {
    if (!text) return text;
    text = text.split('_').join(' ');
    return text.charAt(0).toUpperCase() + text.slice(1);
  },
  titleCase: (text) => {
    if (!text) return text;
    text = text.split('_').join(' ');
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }
};
