import React from 'react';
import PluangListPage from 'components/organisms/pluangListTable';
import { filters, headers } from './constants';

const GoldWithdrawalList = () => {
  return (
    <PluangListPage
      title={'Gold Withdrawal'}
      headers={headers}
      filters={filters}
      resource={'GOLD_WITHDRAWAL'}
    />
  );
};

export default GoldWithdrawalList;
