import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';

import IconExpandLess from '@mui/icons-material/ExpandLess';
import IconExpandMore from '@mui/icons-material/ExpandMore';
import SidebarMenuComponent from 'components/molecules/SideMenuComponent';

const SidebarMenuItem = (props) => {
  const { name, link, Icon, items = [] } = props;
  const isExpandable = items && Array.isArray(items) && items.length > 0;
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(!open);
  }

  const MenuItemRoot = (
    <SidebarMenuComponent
      sx={{
        marginLeft: '5px'
      }}
      button
      selected={open}
      link={link}
      onClick={handleClick}>
      {!!Icon && (
        <ListItemIcon
          sx={{
            minWidth: '40px',
            [`.MuiSvgIcon-root`]: { fontSize: '20px' }
          }}>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText
        primaryTypographyProps={{
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '14px'
          }
        }}
        primary={name}
        inset={!Icon}
      />
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </SidebarMenuComponent>
  );

  const MenuItemChildren = isExpandable && (
    <Collapse
      sx={{
        marginLeft: '10px'
      }}
      in={open}
      timeout="auto"
      unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {items.map((item, index) => (
          <SidebarMenuItem {...item} key={index} />
        ))}
      </List>
    </Collapse>
  );

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

SidebarMenuItem.propTypes = {
  name: PropTypes.string,
  Icon: PropTypes.elementType,
  items: PropTypes.array,
  link: PropTypes.string
};

export default SidebarMenuItem;
