import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import KycTask from 'components/molecules/KycTask';
import KycTaskTable from 'components/molecules/KycTaskTable';

const InvestorProfile = ({ userData }) => {
  const [investorProfile, setInvestorProfile] = useState('');
  const [showDetail, setShowDetail] = useState(false);

  const investorProfileTableHeaders = ['Investor Profile'];

  const investorProfileTableValues = [investorProfile];

  useEffect(() => {
    getInvestorProfile(userData.investorScore);
  }, [userData]);

  const getInvestorProfile = (score) => {
    let investorProfile = '';
    if (score > 0 && score < 50) {
      investorProfile = 'Risk Averse';
    }
    if (score >= 50 && score < 90) {
      investorProfile = 'Income';
    }
    if (score >= 90 && score < 130) {
      investorProfile = 'Balance';
    }
    if (score >= 130) {
      investorProfile = 'Growth';
    }
    setInvestorProfile(investorProfile);
  };

  const kycTaskStatus = () => (
    <Box style={{ color: 'green' }}>{investorProfile ? 'COMPLETED' : ' '}</Box>
  );

  const handleKycTaskClick = () => setShowDetail(!showDetail);

  const isTaskDisabled = !investorProfile;

  return (
    <>
      {Object.keys(userData).length ? (
        <KycTask
          title="Investor Profile"
          status={kycTaskStatus()}
          onClick={handleKycTaskClick}
          disabled={isTaskDisabled}
          open={showDetail}>
          <KycTaskTable headers={investorProfileTableHeaders} values={investorProfileTableValues} />
        </KycTask>
      ) : null}
    </>
  );
};

InvestorProfile.propTypes = {
  userData: PropTypes.object
};

export default InvestorProfile;
