import React from 'react';
import PluangListPage from 'components/organisms/pluangListTable';
import { filters, headers } from './constants';

const CashoutList = () => {
  return (
    <PluangListPage
      title={'Cashout List'}
      headers={headers}
      filters={filters}
      resource={'CASHOUT'}
    />
  );
};

export default CashoutList;
