import React, { useEffect, useState } from 'react';
import PluangListPage from 'components/organisms/pluangListTable';
import { makeRequest } from 'services/APIService';
import { REMOTE } from 'constants/serverRoutes';
import { filter, headers } from './constants';
import { useLoader, useNotification } from 'services/hooks';

const CryptoTransaction = (props) => {
  const { showNotification } = useNotification();
  const [filters, setFilters] = useState(filter);
  const [, setLoader] = useLoader();

  useEffect(() => {
    getAllCryptocurrencies();
  }, []);

  const getAllCryptocurrencies = () => {
    setLoader(true);
    makeRequest({
      url: `${REMOTE.CRYPTO_ACCOUNT}/allCryptocurrencies`
    })
      .then((result) => {
        if (result && result.data && result.data.data) {
          setFilters((prevState) => {
            const currencyNameFilters = result.data.data.map((currency) => {
              return { text: currency.name, value: currency.symbol };
            });
            prevState[0].option = [...prevState[0].option, ...currencyNameFilters];
            return prevState;
          });
        }
      })
      .catch((err) => {
        showNotification('errr', err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <PluangListPage
      {...props}
      title="Crypto Currency Transactions"
      headers={headers}
      filters={filters}
      resource="CRYPTO_TRANSACTIONS"
    />
  );
};

export default CryptoTransaction;
