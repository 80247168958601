import { addDays, addMinutes, format, formatDistanceStrict, startOfDay } from 'date-fns';

export const startOfDayFormat = (dateTime = new Date(), formatString = 'yyyy-MM-dd HH:mm:ss') => {
  try {
    return format(startOfDay(dateTime), formatString);
  } catch (error) {
    return 'Invalid Date';
  }
};

export const endOfDayFormat = (dateTime = new Date(), formatString = 'yyyy-MM-dd HH:mm:ss') => {
  try {
    return format(startOfDay(addDays(dateTime, 1)), formatString);
  } catch (error) {
    return 'Invalid Date';
  }
};

export const formatTodayDistance = (date = new Date(), unit) => {
  try {
    unit = unit ?? 'hour';
    return formatDistanceStrict(startOfDay(new Date()), date, { unit }).split(' ')[0];
  } catch (error) {
    return 'Invalid Date';
  }
};

export const formatDateToZone = (date) => {
  return format(addMinutes(date, date.getTimezoneOffset()), 'yyyy-MM-dd HH:mm:ss');
};
