import React from 'react';
import PluangListPage from 'components/organisms/pluangListTable';
import { filters, headers } from './constants';

const RefereeList = () => {
  return (
    <PluangListPage title={'Referral'} headers={headers} filters={filters} resource={'REFERRAL'} />
  );
};

export default RefereeList;
