import { Route, Routes } from 'react-router-dom';
import CryptoTransferList from './list';
import TravelRuleView from './view';

const OpsCryptoTransfer = () => {
  return (
    <Routes>
      <Route path="/" element={<CryptoTransferList />} />
      <Route path="/view/:id" element={<TravelRuleView />} />
    </Routes>
  );
};

export default OpsCryptoTransfer;
