import React, { useEffect, useState } from 'react';
import { useStateValue } from 'utils/redux';
import { GOPAY_BANK_ID } from 'config';
import { makeRequest } from 'services/APIService';
import { REMOTE } from 'constants/serverRoutes';
import BasicDetails from './basicDetails';
import DisclosureTnc from './disclosureTnc';
import InvestorProfile from './investorProfile';
import Occupation from './occupation';
import OcrDetails from './ocrDetails';
import PersonalData from './personalData';
import UserBanks from './userBanks';
import UserWealthTrusted from './userWealthTrusted';
import ProductPage from './productPage';
import { useLoader } from 'services/hooks';

const KycAutoAssign = () => {
  const [appState] = useStateValue();

  const [kyc1Data, setKyc1Data] = useState({});
  const [kyc2Data, setKyc2Data] = useState({});
  const [userData, setUserData] = useState({});
  const [ktpOcrData, setKtpOcrData] = useState({});
  const [provinceCollection, setProvinceCollection] = useState([]);
  const [idssKycData, setIdssKycData] = useState({});
  const [basicKtpDetails, setBasicKtpDetails] = useState({});
  const [mutualFundBankData, setMutualFundBankData] = useState({});
  const [idssBankData, setIdssBankData] = useState({});
  const [otherBanksData, setOtherBanksData] = useState({});
  const [occupationData, setOccupationData] = useState({});
  const [userWealthTrustedData, setUserWealthTrustedData] = useState({});
  const [gssKycData, setGssKycData] = useState({});
  const [escalate, setEscalate] = useState({
    iskyc1Escalated: false,
    isKtpOcrEscalated: false,
    iskyc2Escalated: false,
    isOccupationEscalated: false,
    isUserBanksEscalated: false
  });
  const [, setLoader] = useLoader();

  useEffect(() => {
    fetchCaseDetails();
  }, [appState.sfId]);

  const fetchCaseDetails = async () => {
    if (appState.sfId) {
      setLoader(true);
      Promise.allSettled([
        getKyc1Data(),
        getUserData(),
        getKyc2Data(),
        getOccupationData(),
        getMutualFundBankAccount(),
        getIdssBankAccount(),
        getOtherBanksData(),
        getUserWealthTrustedData(),
        getIdssKycData(),
        getGssKycData()
      ]).then(() => {
        setLoader(false);
      });
    }
  };

  const getKyc2Data = () => {
    let query = {
      showSignature: 1
    };
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    return makeRequest({
      url: `${REMOTE.KYC2}`,
      params: query
    }).then((result) => {
      let kyc2Data = {};
      kyc2Data.status = 'NOT UPLOADED';
      if (
        result.data.content &&
        result.data.content[0] &&
        Object.keys(result.data.content[0]).length
      ) {
        kyc2Data = result.data.content[0];
      }
      setKyc2Data(kyc2Data);
    });
  };

  const getOccupationData = () => {
    let query = {
      showSignature: 1
    };
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    return makeRequest({
      url: `${REMOTE.OCCUPATION}`,
      params: query
    }).then((result) => {
      let occupationData = {};
      occupationData.status = 'NOT UPLOADED';
      if (result.data && Object.keys(result.data).length) {
        occupationData = result.data;
      }
      setOccupationData(occupationData);
    });
  };

  const getIdssKycData = () => {
    let query = {};
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    return makeRequest({
      url: `${REMOTE['USER_IDSS_KYC_INFORMATION']}`,
      params: query
    }).then((result) => {
      let idssKycData = {};
      idssKycData.status = 'NOT UPLOADED';
      if (result.data?.content?.[0] && Object.keys(result.data.content[0]).length) {
        idssKycData = result.data.content[0];
      }
      setIdssKycData(idssKycData);
    });
  };

  const getMutualFundBankAccount = () => {
    let query = {
      defaultUserBank: true
    };
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    makeRequest({
      url: `${REMOTE.BANK_ACCOUNTS}/filter`,
      params: query
    }).then((result) => {
      let mutualFundBankData = { status: 'NOT UPLOADED' };
      const isKycTaskEscalated = result.data.isKycTaskEscalated;
      if (result.data && result.data.data && result.data.data.length > 0) {
        mutualFundBankData = result.data.data[0];
      }
      setMutualFundBankData({ ...mutualFundBankData, isKycTaskEscalated });
    });
  };

  const getOtherBanksData = () => {
    let query = {};
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    makeRequest({
      url: `${REMOTE.BANK_ACCOUNTS}/filter`,
      params: query
    }).then((result) => {
      let otherBanksData = { status: 'NOT UPLOADED' };
      const isKycTaskEscalated = result.data.isKycTaskEscalated;
      if (result.data && result.data.data && result.data.data.length > 0) {
        delete otherBanksData.status;
        otherBanksData.data = result.data.data.filter(
          (bank) =>
            !bank.defaultUserBank &&
            !bank.defaultIdssUserBank &&
            !bank.deleted &&
            bank.bankId !== GOPAY_BANK_ID
        );
        if (otherBanksData.length === 0) {
          otherBanksData = { status: 'NOT UPLOADED' };
        }
      }
      setOtherBanksData({ ...otherBanksData, isKycTaskEscalated });
    });
  };

  const getIdssBankAccount = () => {
    let query = {
      defaultIdssUserBank: true
    };
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    makeRequest({
      url: `${REMOTE.BANK_ACCOUNTS}/filter`,
      params: query
    }).then((result) => {
      let idssBankData = { status: 'NOT UPLOADED' };
      const isKycTaskEscalated = result.data.isKycTaskEscalated;
      if (result.data && result.data.data && result.data.data.length > 0) {
        idssBankData = result.data.data[0];
      }
      setIdssBankData({ ...idssBankData, isKycTaskEscalated });
    });
  };

  const getUserData = () => {
    let query = {};
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    makeRequest({
      url: `${REMOTE.USER}/${appState.sfId}`,
      params: query
    }).then((result) => {
      if (result.data && result.data.content) {
        setUserData(result.data.content[0]);
      }
    });
  };

  const getKyc1Data = () => {
    let query = {};
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    return makeRequest({
      url: `${REMOTE.KYC1}`,
      params: query
    }).then((result) => {
      let kyc1 = {};
      let ktpOcr = {};
      if (
        result.data.content &&
        result.data.content[0] &&
        Object.keys(result.data.content[0]).length
      ) {
        kyc1 = result.data.content[0];
        if (kyc1.idCardPicture) {
          getKtpOcrData();
          setKyc1Data(kyc1);
        } else {
          ktpOcr.status = 'NOT UPLOADED';
          setKyc1Data(kyc1);
          setKtpOcrData(ktpOcr);
        }
      } else {
        kyc1.status = 'NOT UPLOADED';
        ktpOcr.status = 'NOT UPLOADED';
        setKyc1Data(kyc1);
        setKtpOcrData(ktpOcr);
      }
    });
  };

  const getKtpOcrData = () => {
    makeRequest({ url: `${REMOTE.KYC1}/ktpOcr/provinces` })
      .then((result) => {
        if (result.data && result.data.data) {
          setProvinceCollection(provinceCollection);
        }
      })
      .then(() => {
        return makeRequest({
          url: `${REMOTE.KYC1}/ktpOcr/basicDetails`
        }).then((result) => {
          if (result.data && result.data.data) {
            setBasicKtpDetails(basicKtpDetails);
          }
        });
      })
      .then(() => {
        let query = {};
        if (appState.sfId) {
          query.salesForceId = appState.sfId;
        }
        return makeRequest({
          url: `${REMOTE.KYC1}/ktpOcr`,
          params: query
        }).then((result) => {
          if (result.data) {
            setKtpOcrData(result.data);
          }
        });
      })
      .catch(() => {
        setKtpOcrData({ status: 'NOT_UPLOADED' });
      });
  };

  const getUserWealthTrustedData = () => {
    let query = {};
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    return makeRequest({
      url: `${REMOTE.USER_WEALTH_TRUSTED_CONTACTS}`,
      params: query
    }).then((result) => {
      let userWealthTrustedData = {};
      userWealthTrustedData.status = 'NOT UPLOADED';
      if (
        result.data.content &&
        result.data.content[0] &&
        Object.keys(result.data.content[0]).length
      ) {
        userWealthTrustedData = result.data.content[0];
      }
      setUserWealthTrustedData(userWealthTrustedData);
    });
  };

  const getGssKycData = () => {
    let query = {};
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    return makeRequest({
      url: `${REMOTE.USER_GSS_KYC_INFORMATION}`,
      params: query
    }).then((result) => {
      let gssKycData = {};
      gssKycData.status = 'NOT UPLOADED';
      if (result.data.content[0] && Object.keys(result.data.content[0]).length) {
        gssKycData = result.data.content[0];
      }
      setGssKycData(gssKycData);
    });
  };

  return (
    appState.user && (
      <>
        <OcrDetails ktpOcrData={ktpOcrData} escalate={escalate} kyc1Data={kyc1Data} />
        <BasicDetails
          ktpOcrData={ktpOcrData}
          escalate={escalate}
          kyc1Data={kyc1Data}
          userData={userData}
        />
        <PersonalData kyc2Data={kyc2Data} escalate={escalate} kyc1Data={kyc1Data} />
        <Occupation escalate={escalate} occupationData={occupationData} />
        <UserBanks
          mutualFundBankData={mutualFundBankData}
          idssBankData={idssBankData}
          otherBanksData={otherBanksData}
          escalate={escalate}
        />
        <InvestorProfile userData={userData} />
        <UserWealthTrusted
          userWealthTrustedData={userWealthTrustedData}
          kyc2Data={kyc2Data}
          kyc1Data={kyc1Data}
        />
        <DisclosureTnc gssKycData={gssKycData} />
        {Object.keys(kyc1Data).length > 0 &&
              Object.keys(occupationData).length &&
              Object.keys(idssKycData).length > 0 &&
              Object.keys(gssKycData).length > 0 &&
              Object.keys(mutualFundBankData).length > 0 &&
              Object.keys(idssBankData).length > 0 &&
              Object.keys(kyc2Data).length > 0 && (
                <ProductPage
                  kyc1Data={kyc1Data}
                  kyc2Data={kyc2Data}
                  occupationData={occupationData}
                  idssKycData={idssKycData}
                  gssKycData={gssKycData}
                  mutualFundBankData={mutualFundBankData}
                  idssBankData={idssBankData}
                />
              )}
      </>
    )
  );
};

export default KycAutoAssign;
