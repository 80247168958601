export const REMOTE = {
  ACCOUNT: `admin/crm/account`,
  ADMIN: '/admin/crm',
  ADMIN_LOGIN: `admin/crm/auth/login`,
  ADMIN_FORGOT: `admin/crm/auth/forgot`,
  ADMIN_RESET: `admin/crm/auth/reset`,
  ADMIN_LOGOUT: `admin/crm/auth/logout`,
  ADMIN_RESOURCES: `admin/crm/resources`,
  BANK_ACCOUNTS: '/admin/crm/bankAccount',
  BCA_CREDIT_NOTIFICATIONS: '/admin/crm/bcaCreditNotifications',
  BCA_TOPUP: '/admin/crm/topup/bca',
  CASHOUT: '/admin/crm/cashout',
  CRYPTO_ACCOUNT: '/admin/crm/cryptoAccount',
  CRYPTO_TRANSACTIONS: '/admin/crm/cryptoTransactions',
  FILE_DOWNLOAD: '/admin/crm/download',
  FUND: '/admin/crm/funds',
  FUND_TRANSACTIONS: '/admin/crm/fundTransactions',
  GOLD_SEND_TRANSACTION: '/admin/crm/goldSendTransaction',
  GOLD_WITHDRAWAL: '/admin/crm/withdrawal',
  GLOBAL_SINGLE_STOCK: '/admin/crm/globalSingleStockTransactions',
  GLOBAL_SINGLE_STOCK_TRANSACTIONS: '/admin/crm/globalSingleStockTransactions',
  IDSS: '/admin/crm/idss',
  IDSS_TRANSACTIONS: '/admin/crm/idssTransactions',
  KYC1: '/admin/crm/kyc1',
  KYC2: '/admin/crm/kyc2',
  KYC_AUTO: '/admin/crm/kycAuto',
  LOANS: '/admin/crm/installments/loans',
  LOAN_PAYMENTS: '/admin/crm/installments/payments',
  ME: '/admin/crm/auth/me',
  MISSION_REWARD_CLAIM: '/admin/crm/mission/reward-claim',
  MISSION_USER_ACTION: '/admin/crm/mission/user-action',
  OCCUPATION: '/admin/crm/occupation',
  PARTNER: '/admin/crm/partner',
  RDN_CASHOUT: '/admin/crm/rdnCashout',
  REFERRAL: '/admin/crm/referral',
  SNP500_TRANSACTIONS: '/admin/crm/snp500Transactions',
  STOCK_INDEX_ACCOUNT: '/admin/crm/stockIndexAccount',
  TOPUP: '/admin/crm/topup',
  TRANSACTIONS: '/admin/crm/transactions',
  USD_CASHOUTS: '/admin/crm/usdCashOuts',
  USD_TOPUP: '/admin/crm/usdTopUp',
  USD_TRANSACTIONS: '/admin/crm/USDTransactions',
  USER: '/admin/crm/user',
  USER_AML_RISK_RATING: '/admin/crm/userAmlRiskRating',
  USER_BALANCE: '/admin/crm/user/userBalance',
  USER_GSS_KYC_INFORMATION: '/admin/crm/gssKyc',
  USER_WEALTH_TRUSTED_CONTACTS: '/admin/crm/userWealthTrustedContact',
  VOUCHERS: '/admin/crm/voucher',
  USER_INSURANCE: '/admin/crm/userInsurance',
  AUTO_INVEST: '/admin/crm/autoInvest',
  POCKETS: '/admin/crm/pockets',
  CRYPTO_TRANSFER: '/admin/crm/cryptocurrencyTransfer',
  CRYPTO_CURRENCY_CURRENT_PRICE: '/admin/crm/cryptocurrencyPrice/currentPrice',
  USER_IDSS_KYC_INFORMATION: '/admin/crm/idssKyc'
};
