import { Route, Routes } from 'react-router-dom';
import UsersList from './list';
import UserView from './view';

const OpsUsers = () => {
  return (
    <Routes>
      <Route path="/" element={<UsersList />} />
      <Route path="/view/:sfId" element={<UserView />} />
    </Routes>
  );
};

export default OpsUsers;
