export const normalKycHeaders = [
  'Selfie Score',
  'Partner Id',
  'Auto Approve Rejection Reason',
  'Dukcapil Checking Response',
  'Negative record status'
];

export const foreignerKycHeaders = [
  'Country of Nationality',
  'Residence Address',
  'Residence Country',
  'Zip Code',
  'Place of birth',
  'FATCA Status'
];

export const disclosureTncTableHeaders = [
  'Disclosure Tnc',
  'Cdd Futures Brokerage Firm',
  'Cdd Statement Of Simulation',
  'Cdd Transaction Account Opening',
  'Cdd Notice Of Risk',
  'Cdd Statement Of Responsibility',
  'Cdd Mandate Agreement',
  'Cdd Terms Conditions',
  'Last Upload Time',
  'Last Update Time'
];

export const occupationTableHeaders = [
  'Position',
  'Company name',
  'Company Country',
  'Company Address',
  'Company Province',
  'Company City',
  'Company District',
  'Company Sub District',
  'Business Nature',
  'Company Postal Code',
  'Rejection Reasons',
  'npwp Number',
  'Autoverified',
  'Consent Letter',
  'Last Upload Time',
  'Last Update Time'
];

export const ktpOcrTableHeaders = [
  'Citizenship',
  'Place Of Birth',
  'Address',
  'RT/RW',
  'Province',
  'City',
  'District',
  'Sub-District',
  'Occupation',
  'Religion',
  'Blood Type',
  'Gender',
  'Marital Status',
  'Expiration date',
  'Last Update Time',
  'Last Upload Time'
];

export const userWealthTrustedTableHeaders = [
  'Mothers maiden name',
  'Investment experience',
  'Estimated net worth',
  'Home ownership status ',
  'Relationship',
  'Trusted contact name',
  'Phone number',
  'Trusted contact address',
  'Contact country',
  'Trusted contact province',
  'Trusted contact city',
  'Trusted contact district',
  'Trusted contact subdistrict',
  'Trusted contact postal code',
  'Spouse Relationship',
  'Spouse contact name',
  'Spouse Phone number',
  'Spouse contact address',
  'Spouse contact country',
  'Spouse contact province',
  'Spouse contact city',
  'Spouse contact district',
  'Spouse contact subdistrict',
  'Spouse contact postal code',
  'Last Upload Time'
];
