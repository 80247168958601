import { Route, Routes } from 'react-router-dom';
import AutoDebitList from './list';
import AutoDebitView from './view';

const OpsAutoDebit = () => {
  return (
    <Routes>
      <Route path="/" element={<AutoDebitList />} />
      <Route path="/view/:userId" element={<AutoDebitView />} />
    </Routes>
  );
};

export default OpsAutoDebit;
