import { Route, Routes } from 'react-router-dom';
import FiBcaTopup from './bcaTopup';
import FiCreditNotification from './creditNotification';

const FiBca = () => {
  return (
    <Routes>
      <Route path="topup/*" element={<FiBcaTopup />} />
      <Route path="creditNotification/*" element={<FiCreditNotification />} />
    </Routes>
  );
};

export default FiBca;
