import React, { useState } from 'react';
import { forgot } from 'services/AuthService';
import { validateEmail } from 'helpers/validators';
import { LOCAL } from 'constants/clientRoutes';
import { useLoader, useNotification } from 'services/hooks';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardContent, Container, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { IMAGES } from 'constants/images';

const Forgot = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { showNotification } = useNotification();
  const [, setLoader] = useLoader();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const handleSubmitClick = ({ email }) => {
    setIsButtonDisabled(true);
    setLoader(true);
    forgot(email)
      .then(() => {
        showNotification('success', 'Please check the registered email', 'Complete!!');
        navigate(LOCAL.LOGIN);
      })
      .finally(() => {
        setLoader(false);
        setIsButtonDisabled(false);
      });
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        backgroundImage: `url(${IMAGES.PLUANG_LOGIN_BG})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}>
      <Container sx={{ width: '540px' }}>
        <Card sx={{ borderRadius: 2 }}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
              <img src={IMAGES.PLUANG_LOGIN_LOGO} />
            </Box>
            <form onSubmit={handleSubmit(handleSubmitClick)}>
              <TextField
                fullWidth
                margin="normal"
                size="small"
                variant="outlined"
                type="email"
                name="email"
                label="Email address"
                {...register('email', {
                  required: 'Email is required',
                  pattern: { value: validateEmail, message: 'Invalid email address' }
                })}
                error={!!errors?.email}
                helperText={errors?.email?.message}
              />
              <Box mt={1}>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  type="submit"
                  disabled={isButtonDisabled}>
                  Submit
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default Forgot;
