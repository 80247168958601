import { Box, Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImageMagnifier from 'components/molecules/ImageMagnifier';
import KycTask from 'components/molecules/KycTask';
import ListDialog from 'components/molecules/ListDialog';
import { dateFormatter } from 'helpers/dateChanger';
import { makeRequest } from 'services/APIService';
import { REMOTE } from 'constants/serverRoutes';
import KycTaskTable from 'components/molecules/KycTaskTable';
import { useStateValue } from 'utils/redux';
import { OPEN_MODAL } from 'utils/redux/actions';
import { useLoader, useNotification } from 'services/hooks';

const listHeaders = ['createdAt', 'fromState', 'toState', 'rejectionReason', 'rejectionReasonId'];
const PersonalData = ({ kyc2Data: kycData, escalate, kyc1Data }) => {
  const [personalState, setPersonalState] = useState({
    rejectionReasonLogData: [],
    rejectionCount: 0
  });
  const { showNotification } = useNotification();
  const [showDetail, setShowDetail] = useState(false);
  const [, dispatch] = useStateValue();
  const handleDropdown = (action) => {
    setShowDetail(!action);
  };
  const [, setLoader] = useLoader();

  const personalDataHeaders = [
    'Occupation',
    'Income Level',
    'Marital Status',
    'Fund Source',
    'Religion',
    'Investment Reason Purpose',
    'Rejection Reason',
    'Gender',
    'Education Background',
    'SID',
    'IFUA',
    'Last Upload Time',
    'Last Update Time'
  ];

  const getRejectionReasonLog = () => {
    if (!personalState.rejectionReasonLogData.length > 0) {
      getRejectionReasonData(kycData.userId).then((result) => {
        if (result.data && result.data.data && result.data.data.length > 0) {
          setPersonalState((prevState) => ({
            ...prevState,
            rejectionCount: result.data.data.length,
            rejectionReasonLogData: result.data.data
          }));
          openRejectionReasonDialog(result.data.data);
        } else {
          showNotification('error', 'No rejection reason logs found');
        }
      });
    }
  };

  const openRejectionReasonDialog = (data) => {
    dispatch({
      type: OPEN_MODAL,
      modalContent: <ListDialog title="Rejection Reason logs" data={data} headers={listHeaders} />
    });
  };
  const getRejectionReasonData = (userId) => {
    setLoader(true);
    return makeRequest({
      url: `${REMOTE.KYC2}/personalDataStateTransition/`,
      params: {
        userId,
        toState: 'REJECTED'
      }
    })
      .then((result) => result)
      .finally(() => {
        setLoader(false);
      });
  };

  const getRejectionReasonInIndonesianLang = (rejectionReasonJson) => {
    const rejectionData = rejectionReasonJson && rejectionReasonJson.id;
    const rejectionReason = [];
    if (Array.isArray(rejectionData)) {
      rejectionData.forEach((reason) => {
        rejectionReason.push(reason.value);
      });
    } else {
      return rejectionData;
    }
    return rejectionReason.join(',');
  };

  const kycTaskStatus = () => (
    <>
      {escalate && escalate.iskyc2Escalated && <Box className="escalated-status">ESCALATED</Box>}
      {kycData && kycData.personalDataStatus === 'VERIFIED' ? (
        <Box style={{ color: 'green' }}>{kycData.personalDataStatus}</Box>
      ) : (
        <Box style={{ color: 'black' }}>{kycData.personalDataStatus}</Box>
      )}
    </>
  );

  const personalDataValues = [
    kycData ? kycData.occupation : '',
    kycData ? kycData.incomeLevel : '',
    kycData ? kycData.maritalStatus : 'N/A',
    kycData ? kycData.sourceOfFund : '',
    kycData ? kycData.religion : 'N/A',
    kycData ? kycData.reasonAndPurposeOfInvestment : 'N/A',
    kycData.status !== 'APPROVED' && kycData.personalDataRejectionReasonJson
      ? getRejectionReasonInIndonesianLang(kycData.personalDataRejectionReasonJson)
      : '',
    kycData ? kycData.gender : '',
    kycData ? kycData.educationBackground : '',
    kycData ? kycData.sid : 'N/A',
    kycData ? kycData.ifua : 'N/A',
    kycData.lastUploadTime ? dateFormatter(kycData.lastUploadTime) : '',
    kycData.updatedAt ? dateFormatter(kycData.updatedAt) : ''
  ];

  const handleKycTaskClick = () => {
    handleDropdown(showDetail);
  };

  const isTaskDisabled = kycData.status === 'NOT UPLOADED';

  return (
    <>
      {Object.keys(kycData).length && Object.keys(kyc1Data).length ? (
        <KycTask
          title="Personal Data"
          status={kycTaskStatus()}
          onClick={handleKycTaskClick}
          disabled={isTaskDisabled}
          open={showDetail}>
          <KycTaskTable headers={personalDataHeaders} values={personalDataValues} />
          <Box display="flex" justifyContent="center" my={2}>
            {!(
              personalState.rejectionReasonLogData &&
              personalState.rejectionReasonLogData.length > 0
            ) && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                type="button"
                className="btnLeft"
                onClick={getRejectionReasonLog}>
                Rejection reason logs
              </Button>
            )}
          </Box>
          {kycData.id > 0 ? (
            <div className="workingArea">
              <Grid container>
                <Grid item xs={6}>
                  <h6 className={'foSize'}>Signature</h6>
                  <div className="fluid">
                    <div className="fluid__image-container">
                      {kycData.signature ? <ImageMagnifier url={kycData.signature} /> : null}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div className="noFound">No Data Found</div>
          )}
        </KycTask>
      ) : null}
    </>
  );
};

PersonalData.propTypes = {
  escalate: PropTypes.object,
  kyc2Data: PropTypes.object,
  kyc1Data: PropTypes.object
};

export default PersonalData;
