import React from 'react';
import { useStateValue } from 'utils/redux';
import { HIDE_NOTIFICATION } from 'utils/redux/actions';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Container from 'components/atoms/Container';
import Item from 'components/atoms/Item';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NotificationContainer = () => {
  const [appState, dispatch] = useStateValue();

  const handleClose = () => {
    dispatch({
      type: HIDE_NOTIFICATION
    });
  };

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      open={appState.showNotification}
      autoHideDuration={2000}
      onClose={handleClose}>
      <Alert variant="filled" severity={appState.notificationType}>
        <Container>
          <Item xs={12}>{appState.notificationMessage && appState.notificationMessage.title}</Item>
          <Item xs={12}>{appState.notificationMessage && appState.notificationMessage.body}</Item>
        </Container>
      </Alert>
    </Snackbar>
  );
};

export default NotificationContainer;
