import { Box, Card, CardContent, Toolbar } from '@mui/material';
import Container from 'components/atoms/Container';
import Header from 'components/organisms/Header';
import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useStateValue } from 'utils/redux';
import { SET_SFID } from 'utils/redux/actions';
import { getSfId, setSfId } from 'services/AuthService';
import Modal from 'components/molecules/Modal';
import Sidebar from 'components/organisms/Sidebar';
import { LOCAL } from 'constants/clientRoutes';
import { validateUuId } from 'helpers/validators';

const Layout = () => {
  const params = useParams();
  const [appState, dispatch] = useStateValue();
  const drawerWidth = 240;
  const navigate = useNavigate();

  useEffect(() => {
    const sfId = params['*']?.replace('view/', '');
    if (sfId && validateUuId(sfId)) {
      setSfId(sfId);
      dispatch({
        type: SET_SFID,
        sfId
      });
    }
    if (!getSfId()) {
      navigate(`${LOCAL.WELCOME}`, { replace: true });
    }
  }, []);

  return (
    <Container>
      <Header />
      <Sidebar drawerWidth={drawerWidth} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          maxWidth: `calc(100% - ${drawerWidth}px)`
        }}>
        <Toolbar />
        <Card>
          <CardContent>
            <Outlet />
          </CardContent>
        </Card>
      </Box>
      <Modal>{appState.modalContent}</Modal>
    </Container>
  );
};

export default Layout;
