import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

const PocketItem = ({ pocket }) => {
  const [openPocketDetails, setOpenPocketDetails] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>{pocket?.name}</TableCell>
        <TableCell>{pocket?.userPocketStatus}</TableCell>
        <TableCell>{pocket?.totalValue?.toFixed(2)}</TableCell>
        <TableCell>{pocket?.unrealisedGain?.toFixed(2)}</TableCell>
        <TableCell>{pocket?.currency}</TableCell>
        <TableCell>
          <IconButton size="small" onClick={() => setOpenPocketDetails(!openPocketDetails)}>
            {openPocketDetails ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ pb: 0, pt: 0 }} colSpan={6}>
          <Collapse in={openPocketDetails} timeout="auto" unmountOnExit>
            <Box sx={{ m: 1 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Asset Name</TableCell>
                    <TableCell>Quantity Purchased</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pocket?.pocketAssetReturns?.map((asset) => (
                    <TableRow key={asset.assetId}>
                      <TableCell>{asset.name}</TableCell>
                      <TableCell>{asset.assetQuantity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

PocketItem.propTypes = {
  pocket: PropTypes.object.isRequired
};

export default PocketItem;
