import { addMonths, isAfter, isBefore, isSameDay, isWithinInterval } from 'date-fns';
import { subMonths } from 'date-fns/esm';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Menu from './components/Menu';

const DateRangePicker = (props) => {
  const today = new Date();
  const { onChange, initialDateRange, minDate, maxDate, maxAllowedDays, defaultAllowedDays } =
    props;

  const [dateRange, setDateRange] = useState({ ...initialDateRange });
  const [hoverDay, setHoverDay] = useState();
  const [firstMonth, setFirstMonth] = useState(subMonths(today, 1));
  const [secondMonth, setSecondMonth] = useState(today);

  const { startDate, endDate } = dateRange;

  useEffect(() => setDateRange(initialDateRange), [initialDateRange]);

  const setFirstMonthValidated = (date) => {
    if (isBefore(date, subMonths(today, 1))) {
      setFirstMonth(date);
      setSecondMonth(addMonths(date, 1));
    }
  };

  const setSecondMonthValidated = (date) => {
    if (isBefore(date, today)) {
      setFirstMonth(subMonths(date, 1));
      setSecondMonth(date);
    }
  };

  const onDayClick = (day) => {
    if (startDate && !endDate && !isBefore(day, startDate)) {
      const newRange = { startDate, endDate: day };
      onChange(newRange);
      setDateRange(newRange);
    } else {
      setDateRange({ startDate: day, endDate: undefined });
    }
    setHoverDay(day);
  };

  const onMonthNavigate = (action) => {
    const firstNew = addMonths(firstMonth, action);
    const secondNew = addMonths(secondMonth, action);
    setFirstMonth(firstNew);
    setSecondMonth(secondNew);
  };

  const onDayHover = (date) => {
    if (startDate && !endDate) {
      if (!hoverDay || !isSameDay(date, hoverDay)) {
        setHoverDay(date);
      }
    }
  };

  const inHoverRange = (day) => {
    return (
      startDate &&
      !endDate &&
      hoverDay &&
      isAfter(hoverDay, startDate) &&
      isWithinInterval(day, {
        start: startDate,
        end: hoverDay
      })
    );
  };

  const helpers = {
    inHoverRange,
    maxAllowedDays,
    defaultAllowedDays
  };

  const handlers = {
    onDayClick,
    onDayHover,
    onMonthNavigate
  };

  return (
    <Menu
      dateRange={dateRange}
      initialDateRange={initialDateRange}
      minDate={minDate}
      maxDate={maxDate}
      firstMonth={firstMonth}
      secondMonth={secondMonth}
      setFirstMonth={setFirstMonthValidated}
      setSecondMonth={setSecondMonthValidated}
      helpers={helpers}
      handlers={handlers}
    />
  );
};

DateRangePicker.propTypes = {
  open: PropTypes.bool,
  onChange: PropTypes.func,
  initialDateRange: PropTypes.object,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  maxAllowedDays: PropTypes.number,
  defaultAllowedDays: PropTypes.number,
  definedRanges: PropTypes.object
};

export default DateRangePicker;
