export const BCA_TOPUP_STATUS = Object.freeze({
  CANCELED: 'CANCELED',
  DENY: 'DENY',
  EXPIRE: 'EXPIRE',
  FAILED: 'FAILED',
  OPS_CHECKING: 'OPS_CHECKING',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  SUCCESS: 'SUCCESS',
  UNPAID: 'UNPAID'
});

export const BCA_CREDIT_NOTIFICATION_STATUS = Object.freeze({
  ASSIGNED: 'ASSIGNED',
  UNASSIGNED: 'UNASSIGNED',
  IN_VERIFICATION: 'IN_VERIFICATION',
  READY_TO_REFUND: 'READY_TO_REFUND',
  REFUNDED: 'REFUNDED',
  INTERNAL_TRANSFER: 'INTERNAL_TRANSFER'
});
