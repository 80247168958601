import React from 'react';
import PropTypes from 'prop-types';
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';

const TableViewBasic = (props) => {
  return (
    <Box>
      <Typography variant="h6" pt={2}>
        {props.heading}
      </Typography>
      <Table>
        <TableBody>
          {Object.entries(props.body).map((row, key) => (
            <TableRow key={key}>
              <TableCell>{row[0]}</TableCell>
              <TableCell>{row[1] ? row[1] : ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

TableViewBasic.propTypes = {
  heading: PropTypes.string.isRequired,
  body: PropTypes.object.isRequired
};

export default TableViewBasic;
