import React from 'react';
import { List } from '@mui/material';
import SidebarMenuItem from 'components/molecules/SidebarMenuItem';
import { sidebarMenuItems } from './menuItems';

const SidebarMenu = () => {
  return (
    <List component="nav" disablePadding>
      {sidebarMenuItems.map((item, index) => (
        <SidebarMenuItem {...item} key={index} />
      ))}
    </List>
  );
};

export default SidebarMenu;
