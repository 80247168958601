import { Badge } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { downloadFile } from 'helpers/downloadFile';
import { REMOTE } from 'constants/serverRoutes';
import { makeRequest } from 'services';
import { useNotification } from 'services/hooks';

const S3DownloadLink = ({ url }) => {
  const { showNotification } = useNotification();

  const download = () => {
    makeRequest({
      url: REMOTE.FILE_DOWNLOAD,
      params: {
        url: url
      },
      responseType: 'blob',
      ignoreError: true
    })
      .then((result) => {
        if (result && result.data) {
          let urlParts = url.split('/');
          downloadFile(
            result.data.Body,
            result.data.Metadata.originalname || urlParts[urlParts.length - 1] || 'data',
            result.data.ContentType || 'text/csv'
          );
        }
      })
      .catch((error) => {
        showNotification('error', 'Error occurred while downloading file');
      });
  };

  if (url === null) {
    return <Badge variant="primary">Not available</Badge>;
  } else {
    return (
      <a href={url} className="btn btn-success">
        <i className="fa fa-download " onClick={download} /> Download
      </a>
    );
  }
};

S3DownloadLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string,
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func
};

export default S3DownloadLink;
