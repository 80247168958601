import { Box, Button, Card, CardContent, Container, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { LOCAL } from 'constants/clientRoutes';
import { getSfId, login } from 'services/AuthService';
import { useStateValue } from 'utils/redux';
import { USER_STATE } from 'utils/redux/actions';
import { validateEmail } from 'helpers/validators';
import { IMAGES } from 'constants/images';
import { useNotification } from 'services/hooks';

const Login = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [appState, dispatch] = useStateValue();
  const location = useLocation();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const redirectPath = location.state?.from?.pathname || LOCAL.OPS;

  const handleLogin = ({ email, password }) => {
    setIsButtonDisabled(true);
    login(email, password)
      .then((data) => {
        const salesForceId = getSfId();
        dispatch({
          type: USER_STATE,
          user: data.user,
          loggedIn: true,
          sfId: salesForceId ? salesForceId : null
        });
        showNotification('success', 'You are now logged in!', `Welcome ${data.user.name}`);
        setIsButtonDisabled(false);
        navigate(redirectPath);
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        showNotification('error', error?.error_message || 'Login failed');
      });
  };

  if (appState.loggedIn) return <Navigate to={redirectPath} />;

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        backgroundImage: `url(${IMAGES.PLUANG_LOGIN_BG})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}>
      <Container sx={{ width: '540px' }}>
        <Card sx={{ borderRadius: 2 }}>
          <CardContent
            sx={{
              position: 'relative'
            }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
              <img src={IMAGES.PLUANG_LOGIN_LOGO} />
            </Box>
            <form onSubmit={handleSubmit(handleLogin)}>
              <TextField
                fullWidth
                margin="normal"
                size="small"
                variant="outlined"
                type="email"
                label="Email address"
                {...register('email', {
                  required: 'Email is required',
                  pattern: { value: validateEmail, message: 'Invalid email address' }
                })}
                error={!!errors?.email}
                helperText={errors?.email?.message}
              />
              <TextField
                fullWidth
                margin="normal"
                size="small"
                variant="outlined"
                type="password"
                label="Password"
                {...register('password', {
                  required: 'Password is required',
                  minLength: { value: 5, message: 'Password should be of minimum 5 characters' }
                })}
                error={!!errors?.password}
                helperText={errors?.password?.message}
              />
              <Box mt={1}>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  type="submit"
                  disabled={isButtonDisabled}>
                  Submit
                </Button>
              </Box>
            </form>
            <Box
              sx={{
                fontSize: '14px',
                position: 'absolute',
                bottom: '28px',
                left: '100px'
              }}>
              <Link to={LOCAL.FORGOT}> Forgot Password?</Link>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default Login;
