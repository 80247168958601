export const filter = [
  {
    id: 'status',
    type: 'dropdown',
    label: 'Status',
    default: 'all',
    option: [
      { text: 'Status', value: 'all' },
      { text: 'Pending', value: 'pending' },
      { text: 'Sending', value: 'sending' },
      { text: 'Partially Filled', value: 'partially_filled' },
      { text: 'Cancelled', value: 'cancelled' },
      { text: 'Successful', value: 'successful' },
      { text: 'Rejected', value: 'rejected' }
    ]
  },
  {
    id: 'transactionType',
    type: 'dropdown',
    label: 'Transaction Type',
    default: 'all',
    option: [
      { text: 'Transaction Type', value: 'all' },
      { text: 'Buy', value: 'buy' },
      { text: 'Sell', value: 'sell' }
    ]
  },
  {
    id: 'subCategory',
    type: 'text',
    label: 'Stock Code'
  },
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];

export const header = [
  { header_name: 'Order ID', db_column_name: 'id' },
  { header_name: 'Order Type', db_column_name: 'orderType' },
  { header_name: 'Trans Type', db_column_name: 'transactionType' },
  { header_name: 'Open Quantity', db_column_name: 'openQuantity' },
  { header_name: 'Executed Quantity', db_column_name: 'executedQuantity' },
  { header_name: 'Unit Price', db_column_name: 'unitPrice' },
  { header_name: 'Status', db_column_name: 'userStatus' },
  { header_name: 'Company Ticker', db_column_name: 'stockCode' },
  { header_name: 'Created', db_column_name: 'created' },
  { header_name: 'Updated', db_column_name: 'updated' }
];
