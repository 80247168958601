import LoaderPortal from 'components/molecules/LoaderPortal';
import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';

const useLoader = () => {
  const [isLoading, setLoading] = useState(false);

  const setLoader = useCallback((status) => {
    ReactDOM.render(
      <LoaderPortal isLoading={status} />,
      document.getElementById('__loader'),
      () => {
        setLoading(status);
      }
    );
  }, []);

  return [isLoading, setLoader];
};
export default useLoader;
