import axios from 'axios';
import { logout } from './AuthService';

export const makeRequest = (config) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        method: config.method || 'get',
        baseURL: process.env.REACT_APP_CRM_API_URL || 'http://localhost:8032/api/v1',
        url: config.url,
        data: config.data,
        headers: config.headers ? config.headers : {},
        params: config.params,
        timeout: config.timeout ? config.timeout : 100000,
        cancelToken: config.cancelToken,
        validateStatus: false
      })
        .then((result) => {
          if (result && result.status >= 200 && result.status < 300) {
            resolve(result);
          } else {
            errorHandler(reject, result, config.ignoreError);
          }
        })
        .catch((error) => {
          errorHandler(reject, error, config.ignoreError);
        });
    } catch (err) {
      reject(err);
    }
  });
};

const errorEvent = (type, body, title = null) => {
  const event = new CustomEvent('error', { detail: { type: type, body: body, title: title } });
  document.dispatchEvent(event);
};

const errorHandler = (reject, err, ignoreError) => {
  if (err.code === 'ECONNABORTED') {
    errorEvent('error', JSON.stringify(err.data), 'Error Code:' + err.code);
    return reject(err);
  }

  if (err.message === 'message') {
    return reject('cannot connect to server');
  }

  let msg = JSON.stringify(err.data);

  switch (err.status) {
    case 401:
      logout();
      // then need to change global login state
      break;
    case 503:
      errorEvent('error', err.data?.error_message, 'Error Occurred');
      return reject(err.data);
    default:
      if (err && err.data && err.data.errors) {
        msg = 'Input is invalid for ' + Object.keys(err.data.errors).join(', ');
      } else if (err && err.data && err.data.error_message) {
        msg = err.data.error_message;
      }
      if (!ignoreError) {
        if (err && err.data && err.data.code) {
          errorEvent('error', msg, 'Error Code:' + err.data.code);
        }
      }
      return reject(err.data);
  }
  if (!ignoreError) {
    errorEvent('error', err.message, 'Error Occurred');
  }
  return reject(err.message);
};
