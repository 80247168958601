import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { Flip, ScreenRotationAlt, ZoomIn, ZoomOut } from '@mui/icons-material';

const ImageViewer = ({ handleCloseModal = null, imgSrc }) => {
  const [imageState, setImageState] = useState({
    deg: 0,
    scale: 1,
    flip: 1
  });

  const setScale = () => {
    if (imageState.scale < 4) {
      setImageState((prevState) => ({
        ...prevState,
        scale: prevState.scale + 1
      }));
    } else if (imageState.scale > 1) {
      setImageState((prevState) => ({
        ...prevState,
        scale: prevState.scale - 1
      }));
    }
  };

  const setFlip = () => {
    setImageState((prevState) => ({
      ...prevState,
      flip: prevState.flip * -1
    }));
  };

  const setDeg = () => {
    setImageState((prevState) => ({
      ...prevState,
      deg: prevState.deg + 90
    }));
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '430px',
        left: 0,
        bgcolor: '#fff',
        height: '500px'
      }}>
      <DialogTitle>
        Image Viewer
        {handleCloseModal && (
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        sx={{
          height: '380px',
          overflow: 'auto',
          pl: 15,
          pr: 15,
          overflowX: 'scroll',
          width: '100%'
        }}
        dividers>
        <img
          src={imgSrc}
          sx={{
            transform: `rotate(${imageState.deg}deg) scale(${imageState.scale}) scaleX(${imageState.flip})`,
            width: '800px'
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-around',
          width: '429px',
          position: 'absolute',
          bottom: 0
        }}>
        <Button variant="outlined" onClick={() => setScale()}>
          <ZoomIn />
        </Button>
        <Button variant="outlined" onClick={() => setScale()}>
          <ZoomOut />
        </Button>
        <Button variant="outlined" onClick={() => setFlip()}>
          <Flip />
        </Button>
        <Button variant="outlined" onClick={() => setDeg()}>
          <ScreenRotationAlt />
        </Button>
      </DialogActions>
    </Box>
  );
};

ImageViewer.propTypes = {
  handleCloseModal: PropTypes.func,
  imgSrc: PropTypes.string
};

export default ImageViewer;
