import { Button } from '@mui/material';
import PluangListPage from 'components/organisms/pluangListTable';
import { REMOTE } from 'constants/serverRoutes';
import { dateFormatter } from 'helpers/dateChanger';
import { once } from 'lodash';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { makeRequest } from 'services';
import { useLoader, useNotification } from 'services/hooks';

const CustodianTransactionType = Object.freeze({
  DEPOSIT: 'deposit',
  WITHDRAWAL: 'withdrawal'
});

const TRANSACTION_STATUS = Object.freeze({
  UNFREEZE_CONFIRMED: 'UNFREEZE_CONFIRMED'
});

const intitalFilter = [
  {
    id: 'subCategory',
    type: 'dropdown',
    label: 'Currency Name',
    default: '',
    options: [{ text: 'All', value: '' }]
  },
  {
    id: 'status',
    type: 'dropdown',
    label: 'Status',
    default: '',
    options: [
      { text: 'All', value: '' },
      { text: 'Pending', value: 'PENDING' },
      { text: 'In Review', value: 'IN_REVIEW' },
      { text: 'Success', value: 'SUCCESS' },
      { text: 'Failed', value: 'FAILED' },
      { text: 'Confirming', value: 'CONFIRMING' },
      { text: 'Partially Filled', value: 'PARTIALLY_FILLED' },
      { text: 'Rejected', value: 'REJECTED' },
      { text: 'Timeout', value: 'TIMEOUT' },
      { text: 'Blocked', value: 'BLOCKED' },
      { text: 'Cancelled', value: 'CANCELLED' },
      { text: 'Completed', value: 'COMPLETED' }
    ]
  },
  {
    id: 'transactionType',
    type: 'dropdown',
    label: 'Transaction Type',
    default: '',
    options: [
      { text: 'All', value: '' },
      { text: 'Send', value: 'WITHDRAWAL' },
      { text: 'Receive', value: 'DEPOSIT' }
    ]
  },
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];

const CryptoTransferList = () => {
  const displayCurrencyName = (row) => {
    return row['subCategory'].toUpperCase();
  };

  const displayBlockUrl = (row) => {
    return (
      <a href={row.blockExplorerUrl} target="_blank" rel="noreferrer">
        {row.blockExplorerUrl}
      </a>
    );
  };

  const displayTransactionType = (row) => {
    return row?.transactionType === CustodianTransactionType.DEPOSIT ? 'Receive' : 'Send';
  };

  const handleUnfreeze = once((row) => {
    const { id, vendorTxnId } = row;
    setLoader(true);
    makeRequest({
      url: `${REMOTE.CRYPTO_CURRENCY}/unfreeze`,
      data: {
        id,
        vendorTxnId
      },
      method: 'POST'
    })
      .then((result) => {
        if (result?.data?.success) {
          showNotification('success', 'Success, will be updated within few minutes');
        }
      })
      .finally(() => setLoader(false));
  });

  const showFreezeStatus = (row) => {
    if (row?.transactionType === CustodianTransactionType.DEPOSIT && row.status === 'in_review') {
      return 'Frozen';
    } else if (row?.transactionSubStatus === TRANSACTION_STATUS.UNFREEZE_CONFIRMED) {
      return 'Unfrozen';
    }
    return null;
  };

  const unFreeze = (row) => {
    return (
      row?.transactionType === CustodianTransactionType.DEPOSIT &&
      row.status === 'in_review' && (
        <Button
          variant="contained"
          size="small"
          color="warning"
          onClick={() => handleUnfreeze(row)}>
          Un-Freeze
        </Button>
      )
    );
  };

  const displayName = (row, name) =>
    row?.transactionType === CustodianTransactionType.WITHDRAWAL ? row?.info?.[name] : 'N/A';

  const displayUnfreezeDetail = (row, columnName) =>
    row?.transactionSubStatus === TRANSACTION_STATUS.UNFREEZE_CONFIRMED && row?.info?.[columnName];

  const displayUnfreezeTime = (row) =>
    row?.transactionSubStatus === TRANSACTION_STATUS.UNFREEZE_CONFIRMED &&
    dateFormatter(row?.updated);

  const headers = [
    { header_name: 'Id', db_column_name: 'id' },
    {
      header_name: 'Trans Type',
      isCustom: true,
      customMethod: displayTransactionType,
      db_column_name: 'transactionType'
    },
    { header_name: 'Account Id', db_column_name: 'accountId' },
    { header_name: 'Created', db_column_name: 'created' },
    {
      header_name: 'Currency Name',
      isCustom: true,
      customMethod: displayCurrencyName,
      db_column_name: 'subCategory'
    },
    { header_name: 'Quantity', db_column_name: 'quantity' },
    { header_name: 'Status', db_column_name: 'status' },
    { header_name: 'Reason', db_column_name: 'transactionSubStatus' },
    { header_name: 'Risk Score', db_column_name: 'riskScore' },
    {
      header_name: 'Fund Status',
      db_column_name: 'freezeStatus',
      isCustom: true,
      customMethod: showFreezeStatus
    },
    { header_name: 'Action', isCustom: true, customMethod: unFreeze },
    {
      header_name: 'Unfrozen By',
      db_column_name: 'unfrozenBy',
      isCustom: true,
      customMethod: (row) => displayUnfreezeDetail(row, 'name')
    },
    {
      header_name: 'Unfrozen Time',
      db_column_name: 'unfrozenTime',
      isCustom: true,
      customMethod: displayUnfreezeTime
    },
    {
      header_name: `Sender's Name`,
      db_column_name: 'senderName',
      isCustom: true,
      customMethod: (row) => displayName(row, 'senderName')
    },
    { header_name: 'From', db_column_name: 'sourceAddress' },
    {
      header_name: `Receiver's Name`,
      db_column_name: 'recipientName',
      isCustom: true,
      customMethod: (row) => displayName(row, 'recipientName')
    },
    { header_name: 'To', db_column_name: 'destinationAddress' },
    { header_name: 'Network', db_column_name: 'network' },
    { header_name: 'Fee', db_column_name: 'transferFee' },
    {
      header_name: 'Block Explorer',
      isCustom: true,
      customMethod: displayBlockUrl,
      db_column_name: 'blockExplorerUrl'
    },
    {
      header_name: 'Travel Rule',
      db_column_name: 'action',
      isView: true,
      isUserId: true,
      isTravelRule: true
    }
  ];
  const [filters, setFilters] = useState(intitalFilter);
  const [, setLoader] = useLoader();
  const { showNotification } = useNotification();

  useEffect(() => {
    getAllCryptocurrencies();
  }, []);

  const getAllCryptocurrencies = () => {
    setLoader(true);
    makeRequest({
      url: `${REMOTE.CRYPTO_ACCOUNT}/allCryptocurrencies`
    })
      .then((result) => {
        if (result?.data?.data) {
          const currencyNameFilters = result.data.data.map((currency) => {
            return { text: currency.name, value: currency.symbol };
          });
          const filter = [...filters];
          filter[0].options = [...filter[0].options, ...currencyNameFilters];
          setFilters([...filter]);
        }
      })
      .catch((err) => {
        showNotification('error', err?.message);
      })
      .finally(() => setLoader(false));
  };

  return (
    <PluangListPage
      title="Crypto Transfers"
      headers={headers}
      filters={filters}
      resource="CRYPTO_TRANSFER"
    />
  );
};

export default CryptoTransferList;
