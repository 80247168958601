import { Route, Routes } from 'react-router-dom';
import BcaTopupList from './list';
import BcaTopupView from './view';

const FiBcaTopup = () => {
  return (
    <Routes>
      <Route path="/" element={<BcaTopupList />} />
      <Route path="view/:id" element={<BcaTopupView />} />
    </Routes>
  );
};

export default FiBcaTopup;
