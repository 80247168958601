import React, { useEffect, useState } from 'react';
import PluangListPage from 'components/organisms/pluangListTable';
import StringHelper from 'helpers/stringHelper';
import { makeRequest } from 'services/APIService';
import { REMOTE } from 'constants/serverRoutes';
import { Dialog } from '@mui/material';
import ImageViewer from 'components/molecules/ImageViewer';
import { filter } from './constants';
import { useLoader, useNotification } from 'services/hooks';

const MutualFundTransaction = (props) => {
  const templateUrl =
    'https://pluang-production-uploads.s3.ap-southeast-1.amazonaws.com/Pluang+Saver/failFundTransactionTemplate.csv';
  const { showNotification } = useNotification();
  const [fundTransactionState, setFundTransactionState] = useState({
    showModal: false,
    viewImage: ''
  });
  const [filters, setFilters] = useState(filter);
  const [, setLoader] = useLoader();

  const getActiveFundList = () => {
    setLoader(true);
    makeRequest({
      url: `${REMOTE.FUND}/active`
    })
      .then((result) => {
        if (((result.data || {}).result || {}).funds) {
          const activeFundsFilter = result.data.result.funds.map((fund) => {
            return { text: fund.name, value: fund.code };
          });
          const filter = [...filters];
          filter[3].option = [{ text: 'Funds-All', value: '' }, ...activeFundsFilter];
          setFilters(filter);
        }
      })
      .catch((err) => {
        showNotification.error('error', err.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const transactionInfoColoumn = (row) => {
    return (
      <>
        {row.transactionType.toUpperCase()} {row.fundSellType ? `(${row.fundSellType})` : ''}{' '}
        {row.transactionType.toUpperCase() === 'SWITCH_OUT' &&
          (row.switchAll ? '(full)' : '(partial)')}
        <br />
        <br />
        {row.status && row.status.toUpperCase().replace(/_/g, ' ')}
        <br />
        <br />
        {row.paymentChannel && row.paymentChannel.toUpperCase()}
      </>
    );
  };

  const customCurrency = (row) => {
    return row.currency === 'USD' ? (
      <>{row.finalPrice}</>
    ) : (
      <>
        {StringHelper.toFormattedCurrency(row.finalPrice, {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4
        })}
      </>
    );
  };

  const customNAV = (row) => {
    return row.currency === 'USD' ? (
      <>{row.unitPrice}</>
    ) : (
      <>
        {StringHelper.toFormattedCurrency(row.unitPrice, {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4
        })}
      </>
    );
  };

  const userBankInfoColumn = (row) => {
    if (row && row.userBankDetails) {
      const kseiBankOrder = row.userBankDetails.kseiBankDetails
        ? row.userBankDetails.kseiBankDetails
        : 1;
      return row.transactionType.toUpperCase() === 'SELL' ? (
        <>
          {row.userBankDetails.accountName}
          <br />
          {row.userBankDetails.accountNumber}
          <br />
          {row.userBankDetails.bankName}
          <br />
          {row.userBankDetails.bankCode}
          <br />
          KSEI Bank Order: {kseiBankOrder}
        </>
      ) : (
        <>
          {row.userBankDetails.accountName}
          <br />
          {row.userBankDetails.accountNumber}
          <br />
          {row.userBankDetails.bankName}
          <br />
          {row.userBankDetails.bankCode}
        </>
      );
    }
    return 'NA';
  };

  const handleOpenModal = (row) => {
    setFundTransactionState((prevState) => ({
      ...prevState,
      showModal: true,
      viewImage: row.paymentReceipt
    }));
  };

  const paymentProofColumn = (row) => {
    if (row.paymentReceipt) {
      return (
        <p style={{ color: 'blue', cursor: 'pointer' }} onClick={handleOpenModal.bind(this, row)}>
          Proof
        </p>
      );
    }
    return 'NA';
  };

  const rejectionReason = (row) => {
    if (row && row.rejectionReasonJson) {
      return row.rejectionReasonJson.in || row.rejectionReasonJson.en || '';
    }
    return 'N/A';
  };

  const getSwitchingId = (row) => {
    if (row && row.switchingId) {
      return row.switchingId;
    }
    return 'N/A';
  };

  const header = [
    { header_name: 'Id', db_column_name: 'id' },
    {
      header_name: 'Switching Id',
      db_column_name: 'switchingId',
      isCustom: true,
      customMethod: getSwitchingId.bind(this)
    },
    { header_name: 'Fund Name', db_column_name: 'fundName' },
    {
      header_name: 'Amount',
      db_column_name: 'amount',
      isCustom: true,
      customMethod: customCurrency
    },
    {
      header_name: 'Quantity (units)',
      db_column_name: 'quantity',
      formattedCurrency: true,
      formatCurrencyOptions: { minimumFractionDigits: 4, maximumFractionDigits: 4 }
    },
    { header_name: 'Platform', db_column_name: 'clientId' },
    {
      header_name: 'NAV',
      db_column_name: 'nav',
      isCustom: true,
      customMethod: customNAV
    },
    {
      header_name: 'Transaction Info',
      db_column_name: 'transactionInfo',
      isCustom: true,
      customMethod: transactionInfoColoumn
    },
    {
      header_name: 'User Bank Info',
      db_column_name: 'userBankInfo',
      isCustom: true,
      customMethod: userBankInfoColumn
    },
    {
      header_name: 'Payment Proof',
      db_column_name: 'paymentProof',
      isCustom: true,
      customMethod: paymentProofColumn.bind(this)
    },
    {
      header_name: 'Failed Reason',
      db_column_name: 'failedReason',
      isCustom: true,
      customMethod: rejectionReason.bind(this)
    },
    { header_name: 'Created', db_column_name: 'created' },
    { header_name: 'Updated', db_column_name: 'updated' }
  ];

  const [headers] = useState(header);

  useEffect(() => {
    getActiveFundList();
  }, []);

  const handleCloseModal = () => {
    setFundTransactionState((prevState) => ({
      showModal: false,
      viewImage: ''
    }));
  };

  return (
    <>
      <PluangListPage
        {...props}
        title={'Fund Transactions'}
        headers={headers}
        filters={filters}
        resource={'FUND_TRANSACTIONS'}
        isUploadFile
        uploadTemplate={templateUrl}
        uploadButtonText="Upload to Fail"
        buttonColor="red"
        borderColor="red"
        uploadTemplateText="Fail transactions Template"
        key={window.location.pathname}
      />
      <Dialog
        open={fundTransactionState.showModal}
        fullWidth
        disableEscapeKeyDown={true}
        maxWidth="md">
        <ImageViewer imgSrc={fundTransactionState.viewImage} handleCloseModal={handleCloseModal} />
      </Dialog>
    </>
  );
};

export default MutualFundTransaction;
