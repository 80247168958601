export const filter = [
  {
    id: 'currency',
    type: 'dropdown',
    label: 'Currency',
    default: 'all',
    option: [
      { text: 'All', value: '' },
      { text: 'USD', value: 'USD' },
      { text: 'IDR', value: 'IDR' }
    ]
  },
  {
    id: 'status',
    type: 'dropdown',
    label: 'Status',
    default: 'all',
    option: [
      { text: 'All', value: 'all' },
      { text: 'Pending', value: 'PENDING' },
      { text: 'Success', value: 'SUCCESS' },
      { text: 'Failed', value: 'FAILED' }
    ]
  },
  {
    id: 'transactionType',
    type: 'dropdown',
    label: 'Transaction Type',
    default: 'all',
    option: [
      { text: 'All', value: 'all' },
      { text: 'Buy', value: 'buy' },
      { text: 'Sell', value: 'sell' }
    ]
  },
  {
    id: 'subCategory',
    type: 'dropdown',
    label: 'Product Name',
    default: 'all',
    option: [{ text: 'All', value: 'all' }]
  },
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];

export const headers = [
  { header_name: 'Trans No', db_column_name: 'id' },
  { header_name: 'Product Name', db_column_name: 'stockIndexDisplayName' },
  { header_name: 'Trans Type', db_column_name: 'transactionType' },
  { header_name: 'Quantity', db_column_name: 'quantity' },
  { header_name: 'Unit Price', db_column_name: 'unitPrice', roundOff: 2 },
  { header_name: 'Admin Fee', db_column_name: 'fee' },
  { header_name: 'Total Price(USD)', db_column_name: 'totalPrice', roundOff: 2 },
  { header_name: 'Total Price(IDR)', db_column_name: 'totalPriceIdr', formattedCurrency: true },
  { header_name: 'Status', db_column_name: 'status' },
  { header_name: 'Currency', db_column_name: 'currency' },
  { header_name: 'To IDR', db_column_name: 'currencyToIdr' },
  { header_name: 'Created', db_column_name: 'created' }
];
