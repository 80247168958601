import Config from 'config';

export const filters = [
  {
    id: 'state',
    type: 'dropdown',
    label: 'status',
    default: 'all',
    option: [
      { text: 'All State', value: 'all' },
      { text: 'Requested', value: 'REQUESTED' },
      { text: 'In Process', value: 'IN_PROCESS' },
      { text: 'Gold Ready', value: 'GOLD_READY' },
      { text: 'Delivered', value: 'DELIVERED' },
      { text: 'Rejected', value: 'REJECTED' }
    ]
  },
  {
    id: 'clientId',
    type: 'dropdown',
    label: 'Platform',
    default: 'all',
    option: [
      { text: 'Platform - all', value: 'all' },
      { text: 'Pluang', value: Config.PLUANG_CLIENT_ID },
      { text: 'Go Invest', value: Config.GOINVEST_CLIENT_ID }
    ]
  },
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];

export const headers = [
  { header_name: 'Id', db_column_name: 'id' },
  { header_name: 'Gold Amount', db_column_name: 'goldAmount' },
  { header_name: 'State', db_column_name: 'status' },
  { header_name: 'Delivery Address', db_column_name: 'deliveryAddress' },
  { header_name: 'Platform', db_column_name: 'clientId' },
  { header_name: 'Created', db_column_name: 'created' }
];
