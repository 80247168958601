import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import BasicTable from 'components/molecules/BasicTable';
import { REMOTE } from 'constants/serverRoutes';
import { dateFormatter } from 'helpers/dateChanger';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeRequest } from 'services';
import { useStateValue } from 'utils/redux';
import { BCA_TOPUP_STATUS } from '../../constants';

const BcaTopupView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [appState] = useStateValue();
  const [bcaTopupDetails, setBcaTopupDetails] = useState({});
  const [creditNotificationDetails, setCreditNotificationDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});

  const getBcaTopupDetails = () => {
    makeRequest({
      url: `${REMOTE.TOPUP}/id/${id}`
    })
      .then((result) => {
        if (result.data.data) {
          const topupDetails = result.data.data;
          topupDetails.transferInfo = JSON.parse(topupDetails.information);
          topupDetails.rejectionReason = JSON.parse(topupDetails.rejectionReason);
          setBcaTopupDetails(topupDetails);
          getUserDetails();
          if (
            topupDetails.status === BCA_TOPUP_STATUS.OPS_CHECKING ||
            topupDetails.status === BCA_TOPUP_STATUS.SUCCESS
          ) {
            getCreditNotificationDetails();
          }
        }
      })
      .catch(() => navigate(-1));
  };

  const getCreditNotificationDetails = () => {
    makeRequest({
      url: `${REMOTE.BCA_CREDIT_NOTIFICATIONS}/byTopUpId/${id}`
    }).then((result) => {
      if (result.data.data) {
        const creditNotificationDetails = result.data.data;
        creditNotificationDetails.refundDetails = JSON.parse(creditNotificationDetails.info);
        setCreditNotificationDetails(result.data.data);
      }
    });
  };

  const getUserDetails = () => {
    makeRequest({
      url: `${REMOTE.USER}/${appState.sfId}`,
      params: {
        salesForceId: appState.sfId
      }
    }).then((result) => {
      if (result.data && result.data.content) {
        setUserDetails(result.data.content[0]);
      }
    });
  };

  useEffect(() => {
    if (appState.sfId)
      getBcaTopupDetails();
  }, [appState.sfId]);

  const bcaTopupData = [
    { label: 'Top Up Id', value: bcaTopupDetails?.id },
    { label: 'Top Up Status', value: bcaTopupDetails?.status },
    { label: 'Top Up Amount', value: bcaTopupDetails?.amount },
    { label: 'Created', value: dateFormatter(bcaTopupDetails?.createdAt) },
    { label: 'Bank Account name', value: bcaTopupDetails?.transferInfo?.senderName },
    { label: 'Bank Account Number', value: bcaTopupDetails?.transferInfo?.accountNumber },
    { label: 'Transfer Date', value: bcaTopupDetails?.transferInfo?.date },
    { label: 'Rejection Reason', value: bcaTopupDetails?.rejectionReason?.en },
    {
      label: 'Internal Rejection Reason',
      value: bcaTopupDetails?.rejectionReason?.internalRejectionReason
    }
  ];

  const creditNotificationData = [
    { label: 'Credit Notification Id', value: creditNotificationDetails?.id },
    { label: 'Credit Notification Status', value: creditNotificationDetails?.status },
    { label: 'Created', value: dateFormatter(creditNotificationDetails?.createdAt) },
    { label: 'Transaction Date', value: creditNotificationDetails?.transactionDate },
    { label: 'Transaction Time', value: creditNotificationDetails?.transactionTime },
    { label: 'Amount', value: creditNotificationDetails?.amount },
    { label: 'Reference Number', value: creditNotificationDetails?.referenceNumber },
    { label: 'Transaction Remarks', value: creditNotificationDetails?.transactionRemarks },
    { label: 'Name', value: creditNotificationDetails?.senderName }
  ];

  const userData = [
    { label: 'Name', value: userDetails?.name },
    {
      label: 'Email',
      value: (
        <>
          {userDetails?.email}
          <Typography ml={2} variant="caption" color={userDetails?.emailVerified ? 'green' : 'red'}>
            {userDetails?.emailVerified ? 'Verified' : 'Not verified'}
          </Typography>
        </>
      )
    },
    { label: 'Phone', value: userDetails?.phone },
    { label: 'Basic Kyc Status', value: userDetails?.bappebtiKycStatus },
    { label: 'Status', value: userDetails?.active ? 'Active' : 'InActive' },
    { label: 'Edd Status', value: userDetails?.eddStatus || 'Not Requested' },
    { label: 'Risk Rating Category', value: userDetails?.riskRatingCategory }
  ];

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">BCA Top Up Details</Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        <BasicTable tableData={bcaTopupData} />
        {bcaTopupDetails?.transactionReceipt && (
          <>
            <Typography variant="subtitle1" sx={{ mt: 2, mx: 2 }}>
              Proof Of Transfer
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                my: 2
              }}>
              <Box
                component="img"
                src={bcaTopupDetails?.transactionReceipt}
                sx={{ height: 400, mt: 1 }}
              />
            </Box>
          </>
        )}
        <Typography variant="h5" sx={{ mt: 4 }}>
          Credit Notification
        </Typography>
        {Object.keys(creditNotificationDetails).length > 0 ? (
          <BasicTable tableData={creditNotificationData} />
        ) : (
          <Typography sx={{ mt: 2 }}>Unmatched</Typography>
        )}
        <Typography variant="h5" sx={{ mt: 4 }}>
          User Details
        </Typography>
        {userDetails && <BasicTable tableData={userData} />}
      </CardContent>
    </Card>
  );
};

export default BcaTopupView;
