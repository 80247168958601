import React, { useState } from 'react';
import KycTask from 'components/molecules/KycTask';
import { dateFormatter } from 'helpers/dateChanger';
import PropTypes from 'prop-types';
import KycTaskTable from 'components/molecules/KycTaskTable';
import { userWealthTrustedTableHeaders } from './constants';
import { Box } from '@mui/material';

const UserWealthTrusted = ({ userWealthTrustedData, kyc2Data, kyc1Data }) => {
  const [showDetail, setShowDetail] = useState(false);

  const handleDropdown = (action) => {
    setShowDetail(!action);
  };

  const userWealthTrustedTableValues = [
    userWealthTrustedData.mothersMaidenName,
    userWealthTrustedData.investmentExperience,
    userWealthTrustedData.estimatedNetWorth,
    userWealthTrustedData.homeOwnership,
    userWealthTrustedData.relationship,
    userWealthTrustedData.contactName,
    userWealthTrustedData.contactPhone,
    userWealthTrustedData.contactAddress,
    userWealthTrustedData.contactCountry,
    userWealthTrustedData.contactProvince,
    userWealthTrustedData.contactCity,
    userWealthTrustedData.contactDistrict,
    userWealthTrustedData.contactSubDistrict,
    userWealthTrustedData.contactPostalCode,
    userWealthTrustedData.spouseRelationship,
    userWealthTrustedData.spouseName,
    userWealthTrustedData.spousePhoneNumber,
    userWealthTrustedData.spouseAddress,
    userWealthTrustedData.spouseCountry,
    userWealthTrustedData.spouseProvince,
    userWealthTrustedData.spouseCity,
    userWealthTrustedData.spouseDistrict,
    userWealthTrustedData.spouseSubDistrict,
    userWealthTrustedData.spousePostalCode,
    userWealthTrustedData.createdAt ? dateFormatter(userWealthTrustedData.createdAt) : ''
  ];

  const kycTaskStatus = () => (
    <>
      {userWealthTrustedData.status === 'UPLOADED' ? (
        <Box style={{ color: 'green' }}>VERIFIED</Box>
      ) : (
        <Box style={{ color: 'black' }}>{userWealthTrustedData.status}</Box>
      )}
    </>
  );

  const handleKycTaskClick = () => handleDropdown(showDetail);

  const isTaskDisabled = userWealthTrustedData.status === 'NOT UPLOADED';

  return (
    <>
      {Object.keys(kyc2Data).length && Object.keys(kyc1Data).length ? (
        <KycTask
          title="User Wealth Trusted Contact"
          status={kycTaskStatus()}
          onClick={handleKycTaskClick}
          disabled={isTaskDisabled}
          open={showDetail}>
          <KycTaskTable
            headers={userWealthTrustedTableHeaders}
            values={userWealthTrustedTableValues}
          />
        </KycTask>
      ) : null}
    </>
  );
};

UserWealthTrusted.propTypes = {
  userWealthTrustedData: PropTypes.object,
  kyc2Data: PropTypes.object,
  kyc1Data: PropTypes.object
};

export default UserWealthTrusted;
