import React from 'react';
import PluangListPage from 'components/organisms/pluangListTable';
import { userActionFilters, userActionHeaders } from '../constant.js';

const MissionUserAction = () => {
  return (
    <PluangListPage
      title="Mission User Action"
      pageSize={20}
      headers={userActionHeaders}
      filters={userActionFilters}
      resource={'MISSION_USER_ACTION'}
    />
  );
};

export default MissionUserAction;
