import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Container, TextField } from '@mui/material';
import { LOCAL } from 'constants/clientRoutes';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { IMAGES } from 'constants/images';
import { useLoader, useNotification } from 'services/hooks';
import { reset } from 'services/AuthService';

const Reset = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const params = useParams();
  const [, setLoader] = useLoader();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    if (!params.adminId || !params.secretKey) {
      navigate(LOCAL.LOGIN);
    }
  }, []);

  const handleSubmitClick = ({ password }) => {
    setIsButtonDisabled(true);

    setLoader(true);
    reset(params.secretKey, params.adminId, password)
      .then(() => {
        showNotification('success', 'Proceed to Login', 'Complete!!');
        navigate(LOCAL.LOGIN);
      })
      .finally(() => {
        setLoader(false);
        setIsButtonDisabled(false);
      });
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        backgroundImage: `url(${IMAGES.PLUANG_LOGIN_BG})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}>
      <Container sx={{ width: '540px' }}>
        <Card sx={{ borderRadius: 2 }}>
          <CardContent
            sx={{
              position: 'relative'
            }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
              <img src={IMAGES.PLUANG_LOGIN_LOGO} />
            </Box>
            <form onSubmit={handleSubmit(handleSubmitClick)}>
              <TextField
                fullWidth
                name="password"
                margin="normal"
                size="small"
                variant="outlined"
                type="password"
                label="password"
                {...register('password', {
                  required: 'Passowrd is required',
                  minLength: {
                    value: 5,
                    message: 'Password must have at least 5 characters'
                  }
                })}
                error={!!errors?.password}
                helperText={errors?.password?.message}
              />
              <TextField
                fullWidth
                name="cppassword"
                margin="normal"
                size="small"
                variant="outlined"
                type="password"
                label="Confirm Password"
                {...register('cppassword', {
                  required: true,
                  validate: (val) => {
                    if (watch('password') != val) {
                      return 'Your passwords do no match';
                    }
                  }
                })}
                error={!!errors?.cppassword}
                helperText={errors?.cppassword?.message}
              />
              <Box mt={1}>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  type="submit"
                  disabled={isButtonDisabled}>
                  Submit
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default Reset;
