import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import App from 'containers/App';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApiInterceptor } from 'services';
import { StateProvider } from 'utils/redux/store';
import theme from 'theme';
import ErrorBoundary from 'components/ErrorBoundary';
import NotificationContainer from 'components/molecules/Notification';

const Main = () => {
  return (
    <React.StrictMode>
      <StateProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <ErrorBoundary>
              <BrowserRouter>
                <ApiInterceptor>
                  <CssBaseline />
                  <NotificationContainer />
                  <App />
                </ApiInterceptor>
              </BrowserRouter>
            </ErrorBoundary>
          </ThemeProvider>
        </StyledEngineProvider>
      </StateProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));
