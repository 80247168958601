import React, { useEffect, useState } from 'react';
import { REMOTE } from 'constants/serverRoutes';
import { makeRequest } from 'services/APIService';
import { useParams } from 'react-router-dom';
import Container from 'components/atoms/Container';
import Item from 'components/atoms/Item';
import { Badge, Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useStateValue } from 'utils/redux/store';
import { endOfDayFormat, startOfDayFormat } from 'services/DateTime';

const CashoutView = () => {
  const [cashOutData, setCashOutData] = useState({
    cashoutData: {},
    userData: {},
    bankAccountData: {},
    status: null,
    cashoutTotals: null
  });
  const [appState] = useStateValue();
  const params = useParams();

  useEffect(() => {
    if (params.cashoutId && appState.sfId) {
      getCashout(params.cashoutId);
    }
  }, [appState.sfId]);

  const getCashout = (cashoutId) => {
    return makeRequest({
      url: `${REMOTE.CASHOUT}/${cashoutId}`
    }).then((result) => {
      if (result.data && result.data.data) {
        let cashout = result.data.data;
        getUser(cashout.accountId);
        getCashoutTotal();
        setCashOutData((prevState) => ({
          ...prevState,
          cashoutData: cashout,
          status: cashout.status,
          bankAccountData: cashout.userBank
        }));
      }
    });
  };

  const getCashoutTotal = () => {
    const query = {
      from: startOfDayFormat(),
      to: endOfDayFormat()
    };
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    return makeRequest({
      url: `${REMOTE.CASHOUT}/totalByUser`,
      params: query
    }).then((result) => {
      if (result.data && result.data.data) {
        let cashoutTotals = result.data.data;
        setCashOutData((prevState) => ({
          ...prevState,
          cashoutTotals: cashoutTotals
        }));
      }
    });
  };

  const getUser = (accountId) => {
    const query = {
      id: accountId
    };
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    makeRequest({
      url: `${REMOTE.USER}/search`,
      params: query
    }).then((result) => {
      if (result.data && result.data.data && result.data.data.length === 1) {
        let user = result.data.data[0];
        setCashOutData((prevState) => ({
          ...prevState,
          userData: user
        }));
      }
    });
  };

  const { cashoutData, userData, bankAccountData, cashoutTotals } = cashOutData;

  return (
    <>
      <Container
        sx={{
          borderBottom: '1px #eee solid',
          paddingBottom: '10px',
          marginBottom: '20px'
        }}>
        <Item xs={6}>
          <Typography variant="h4">Cashout Details</Typography>
        </Item>
        <Item
          xs={6}
          sx={{
            textAlign: 'right'
          }}>
          {cashoutTotals && (
            <Box>
              Total Cashouts: {cashoutTotals.total}
              &nbsp; Cashouts Today: {cashoutTotals.total_per_day}{' '}
            </Box>
          )}
        </Item>
      </Container>

      <Container
        sx={{
          borderBottom: '1px #eee solid',
          paddingBottom: '10px',
          marginBottom: '20px'
        }}>
        <Item>
          <Typography variant="h5">Bank Account details</Typography>
        </Item>
      </Container>

      <Container>
        {bankAccountData && userData && userData.user && (
          <Item xs={6}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Beneficiary Name</TableCell>
                  <TableCell>{bankAccountData.accountName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Bank Account No.</TableCell>
                  <TableCell>{bankAccountData.accountNumber}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Bank</TableCell>
                  <TableCell>{bankAccountData.bank ? bankAccountData.bank.name : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Bank Account Status</TableCell>
                  <TableCell>
                    {bankAccountData.status !== 'VERIFIED' ? (
                      <Badge color="error" badgeContent={bankAccountData.status} />
                    ) : (
                      bankAccountData.status
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>KYC Verified</TableCell>
                  <TableCell>{userData.user.kycStatus ? userData.user.kycStatus : 'NO'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Item>
        )}
        {cashoutData && (
          <Item xs={6}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Cashout ID</TableCell>
                  <TableCell>{cashoutData.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Cash Amount</TableCell>
                  <TableCell>{cashoutData.amount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Fee</TableCell>
                  <TableCell>{cashoutData.fee}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Auto Approved</TableCell>
                  <TableCell>{cashoutData.autoApproved ? 'Yes' : 'No'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Cashout Status</TableCell>
                  <TableCell>
                    {(() => {
                      if (cashoutData.status === 'APPROVED') {
                        return (
                          <Box>
                            <Badge color="success" badgeContent="APPROVED" />
                          </Box>
                        );
                      } else if (cashoutData.status === 'COMPLETED') {
                        return (
                          <Box>
                            <Badge color="success" badgeContent="COMPLETED" />
                          </Box>
                        );
                      } else if (cashoutData.status === 'REJECTED') {
                        return (
                          <Box>
                            <Badge color="error" badgeContent="REJECTED" />
                          </Box>
                        );
                      } else {
                        return <Box>{cashoutData.status}</Box>;
                      }
                    })()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Rejection Reason (Bahasa)</TableCell>
                  <TableCell>
                    {(() => {
                      if (cashoutData.status === 'REJECTED') {
                        return cashoutData && cashoutData.rejectionReasonId
                          ? cashoutData.rejectionReasonId
                          : '';
                      }
                    })()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Item>
        )}
      </Container>
    </>
  );
};

export default CashoutView;
