import axios from 'axios';
import { getAccessToken, logout } from './AuthService';
import { useLoader } from './hooks';

const ApiInterceptor = ({ children }) => {
  const handleRequest = (config) => {
    if (config?.headers) {
      if (!config.headers.Authorization) {
        const token = getAccessToken();
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      } else {
        if (String(config.headers.Authorization).match('undefined')) {
          delete config.headers.Authorization;
        }
      }
      if (!config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json';
      }
    }
    return config;
  };
  const handleResponse = (response) => {
    return response;
  };
  const handleError = (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      logout();
      // then change global login state
    }
    return error, Promise.reject(error);
  };

  axios.interceptors.request.use(handleRequest, handleError);
  axios.interceptors.response.use(handleResponse, handleError);

  return children;
};

export default ApiInterceptor;
