import { Box, Typography } from '@mui/material';
import React from 'react';

const Maintenance = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Typography variant="h3">{`Sorry, we're down for schedule maintenance`}</Typography>
    </Box>
  );
};

export default Maintenance;
