import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import PocketItem from './PocketItem';

const pocketHeaders = ['Name', 'Status', 'Current Value', 'Unrealised P&L', 'Currency', 'Details'];

const Pockets = ({ userPortfolio }) => {
  const [showPockets, setShowPockets] = useState(false);
  const [openCryptoPockets, setOpenCryptoPockets] = useState(false);
  const [openGlobalStockPockets, setOpenGlobalStockPockets] = useState(false);
  const cryptoPockets =
    Object.values(userPortfolio?.userPocketPortfolio?.cryptoPocketPortfolio ?? {}) || [];
  const globalStockPockets =
    Object.values(userPortfolio?.userPocketPortfolio?.globalStockPocketPortfolio ?? {}) || [];

  const activeCryptoPockets = cryptoPockets.map(
    (cryptoPocket) => cryptoPocket.userPocketStatus === 'ACTIVE'
  ).length;
  const activeGlobalStockPockets = globalStockPockets.map(
    (globalStockPocket) => globalStockPocket.userPocketStatus === 'ACTIVE'
  ).length;
  const totalActivePockets = activeCryptoPockets + activeGlobalStockPockets;

  return (
    <Accordion expanded={showPockets} onChange={() => setShowPockets(!showPockets)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h5">Pockets - (Active Pockets: {totalActivePockets})</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Accordion
          expanded={openCryptoPockets}
          onChange={() => setOpenCryptoPockets(!openCryptoPockets)}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h5">Crypto Pockets</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table sx={{ whiteSpace: 'nowrap' }}>
                <TableHead>
                  <TableRow>
                    {pocketHeaders.map((header) => (
                      <TableCell key={header}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cryptoPockets?.map((cryptoPocket) => (
                    <PocketItem key={cryptoPocket.userPocketId} pocket={cryptoPocket} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={openGlobalStockPockets}
          onChange={() => setOpenGlobalStockPockets(!openGlobalStockPockets)}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h5">Global Stock Pockets</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table sx={{ whiteSpace: 'nowrap' }}>
                <TableHead>
                  <TableRow>
                    {pocketHeaders.map((header) => (
                      <TableCell key={header}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {globalStockPockets?.map((globalStockPocket) => (
                    <PocketItem key={globalStockPocket.userPocketId} pocket={globalStockPocket} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>
    </Accordion>
  );
};

Pockets.propTypes = {
  userPortfolio: PropTypes.object
};

export default Pockets;
