import { Grid, Paper, Typography } from '@mui/material';
import { addDays, format, getDate, isAfter, isSameMonth, isToday } from 'date-fns';
import PropTypes from 'prop-types';
import {
  chunks,
  getDaysInMonth,
  inDateRange,
  isEndOfRange,
  isRangeSameDay,
  isStartOfRange
} from '../utils';
import Day from './Day';
import Header from './Header';

const WEEK_DAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const Month = (props) => {
  const { helpers, handlers, value: date, dateRange, navState, setValue: setDate, minDate } = props;

  const [back, forward] = navState;
  return (
    <Paper sx={{ height: 352, width: 290 }}>
      <Grid container>
        <Header
          date={date}
          setDate={setDate}
          nextDisabled={!forward}
          prevDisabled={!back}
          onClickPrevious={() => handlers.onMonthNavigate(-1)}
          onClickNext={() => handlers.onMonthNavigate(1)}
        />
        <Grid item container direction="row" component="div" sx={{ mt: 2, px: 2 }}>
          {WEEK_DAYS.map((day) => (
            <Grid key={day} item sx={{ width: 36, textAlign: 'center' }}>
              <Typography color="textSecondary" variant="caption">
                {day}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Grid item container direction="column" component="div" sx={{ my: 2, px: 2 }}>
          {chunks(getDaysInMonth(date), 7).map((week, idx) => (
            <Grid key={idx} container direction="row">
              {week.map((day) => {
                const isStart = isStartOfRange(dateRange, day);
                const isEnd = isEndOfRange(dateRange, day);
                const isRangeOneDay = isRangeSameDay(dateRange);
                const highlighted = inDateRange(dateRange, day) || helpers.inHoverRange(day);
                return (
                  <Day
                    key={format(day, 'mm-dd-yyyy')}
                    filled={isStart || isEnd}
                    outlined={isToday(day)}
                    highlighted={highlighted && !isRangeOneDay}
                    disabled={
                      (!minDate && isAfter(day, new Date())) ||
                      isAfter(day, addDays(new Date(), helpers.maxAllowedDays)) ||
                      (dateRange.startDate &&
                        !dateRange.endDate &&
                        isAfter(day, addDays(dateRange.startDate, helpers.defaultAllowedDays))) ||
                      !isSameMonth(date, day)
                    }
                    startOfRange={isStart && !isRangeOneDay}
                    endOfRange={isEnd && !isRangeOneDay}
                    onClick={() => handlers.onDayClick(day)}
                    onHover={() => handlers.onDayHover(day)}
                    value={getDate(day)}
                  />
                );
              })}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

Month.propTypes = {
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  value: PropTypes.object,
  dateRange: PropTypes.object,
  navState: PropTypes.array,
  setValue: PropTypes.func,
  setSecondMonth: PropTypes.func,
  setDateRange: PropTypes.func,
  helpers: PropTypes.object,
  handlers: PropTypes.object
};

export default Month;
