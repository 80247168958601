import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import Container from 'components/atoms/Container';
import Item from 'components/atoms/Item';
import S3DownloadLink from 'components/molecules/s3DownloadLink';
import { NATIONALITIES } from 'config';
import {
  bankRejectionOptionsInEn,
  bankRejectionOptionsInId,
  generalRejectionOptionsInEn,
  generalRejectionOptionsInId,
  kycForeignerRejectionOptionsInEn,
  kycForeignerRejectionOptionsInId,
  kycRejectionOptionsInEn,
  kycRejectionOptionsInId,
  occupationRejectionOptionsInEn,
  occupationRejectionOptionsInId,
  personalDataRejectionOptionsInEn,
  personalDataRejectionOptionsInId
} from 'constants/rejectionOptions';
import { REMOTE } from 'constants/serverRoutes';
import { dateFormatter } from 'helpers/dateChanger';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { makeRequest } from 'services';
import { useLoader, useNotification } from 'services/hooks';
import RejectionReason from './RejectionReason';

const ProductPage = ({
  kyc2Data,
  kyc1Data,
  occupationData,
  idssKycData,
  idssBankData,
  mutualFundBankData,
  gssKycData
}) => {
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [product, setProduct] = useState('KYC2');
  const [options, setOptions] = useState({
    bahasaOptions: [],
    englishOptions: [],
    rejectModulesInOptions: [],
    rejectModulesEnOptions: []
  });
  const [rejectModules, setRejectModules] = useState(['']);
  const [rejectModulesRejectionOption, setRejectModulesRejectionOption] = useState({
    Kyc1: [kycRejectionOptionsInId, kycRejectionOptionsInEn],
    Kyc2: [personalDataRejectionOptionsInId, personalDataRejectionOptionsInEn],
    Occupation: [occupationRejectionOptionsInId, occupationRejectionOptionsInEn],
    General: [generalRejectionOptionsInId, generalRejectionOptionsInEn],
    Bank: [bankRejectionOptionsInId, bankRejectionOptionsInEn]
  });
  const [, setLoader] = useLoader();
  const { showNotification } = useNotification();

  useEffect(() => {
    if (kyc1Data.nationality !== NATIONALITIES.INDONESIA) {
      const rejectionModules = rejectModulesRejectionOption;
      rejectionModules.Kyc1 = [kycForeignerRejectionOptionsInId, kycForeignerRejectionOptionsInEn];
      setRejectModulesRejectionOption(rejectionModules);
    }
  }, []);

  const getRejectionReasonInIndonesianLang = (rejectionReasonJson) => {
    const rejectionData = rejectionReasonJson && rejectionReasonJson.id;
    const rejectionReason = [];
    if (Array.isArray(rejectionData)) {
      rejectionData.forEach((reason) => {
        rejectionReason.push(reason.value);
      });
    } else {
      return rejectionData;
    }
    return rejectionReason.join(',');
  };

  const generateReason = (lang, index) => {
    let reasons;
    if (lang === 'en') {
      reasons = options.englishOptions?.[index]?.map((item) => item.value);
    } else {
      reasons = options.bahasaOptions?.[index]?.map((item) => item.value);
    }

    if (!reasons || !reasons.length) return '';

    if (reasons.length === 1) {
      if (lang === 'en') {
        return `Your verification is rejected. ${reasons[0]}`;
      }

      return `Mohon Maaf, verifikasi anda ditolak. ${reasons[0]}`;
    }

    const combinedReason = `${reasons.slice(0, -1).join(', ')} and ${reasons[reasons.length - 1]}`;

    if (lang === 'en') {
      return `Your verification is rejected because ${combinedReason}`;
    }
    return `Mohon Maaf, verifikasi anda ditolak karena ${combinedReason}`;
  };

  const updatedKyc1Status = (reasonEn, reasonIn, rejectionReasonJson, kycStatus, product) => {
    setLoader(true);
    return makeRequest({
      url: `${REMOTE.KYC1}/update/${kyc1Data.userId}/status/${kycStatus}`,
      method: 'PUT',
      data: {
        rejectionReason: reasonEn,
        rejectionReasonId: reasonIn,
        rejectionReasonJson: JSON.stringify(rejectionReasonJson),
        nationality: kyc1Data.nationality,
        clientId: kyc1Data.clientId,
        product
      }
    })
      .then((result) => {
        if (result.data?.success) {
          showNotification('success', 'Status Updated');
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const updatedOccupationStatus = (rejectionReasonJson, occupationStatus, product) => {
    setLoader(true);
    makeRequest({
      url: `${REMOTE.OCCUPATION}/updateStatus`,
      method: 'PUT',
      data: {
        ...occupationData,
        rejectionReason: rejectionReasonJson,
        status: occupationStatus,
        product
      }
    })
      .then((result) => {
        if (result.data?.success) {
          showNotification('success', 'Status Updated');
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const updateBankAccountStatus = (reasonEn, reasonId, bankAccountData, product) => {
    const {
      bankId,
      accountNumber,
      accountName,
      userId,
      accountId,
      defaultIdssUserBank,
      defaultUserBank,
      status,
      kseiStatus
    } = bankAccountData;
    setLoader(true);
    makeRequest({
      method: 'PUT',
      url: `${REMOTE.BANK_ACCOUNTS}/reject/${bankAccountData.id}`,
      data: {
        bankId,
        accountNumber,
        accountName,
        userId,
        accountId,
        defaultIdssUserBank,
        defaultUserBank,
        kseiStatus,
        status,
        rejectionReason: reasonEn,
        rejectionReasonId: reasonId,
        product
      }
    })
      .then((result) => {
        if (result.data?.data) {
          showNotification('success', 'Status Updated');
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const updatedPersonaDataStatus = ({
    reasonEn,
    reasonIn,
    rejectionReasonJson,
    status,
    product
  }) => {
    setLoader(true);
    return makeRequest({
      url: `${REMOTE.KYC2}/personalDataStatus`,
      method: 'PUT',
      data: {
        userId: kyc1Data.userId,
        rejectionReason: reasonEn,
        rejectionReasonId: reasonIn,
        personalDataStatus: status,
        clientId: kyc2Data.clientId,
        kyc2Status: kyc2Data.status,
        rejectionReasonJson: JSON.stringify(rejectionReasonJson),
        product
      }
    })
      .then((result) => {
        if (result.data?.success) {
          showNotification('success', 'Status Updated');
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getRejectionJson = (index) => {
    const json = { id: [], en: [] };
    if (options.englishOptions?.length > index) {
      json.en = options.englishOptions[index];
    }
    if (options.bahasaOptions?.length > index) {
      json.id = options.bahasaOptions[index];
    }

    if (json.en?.length) {
      json.en.forEach((item) => {
        if (!item.position) {
          item.position = 'banner';
        }
      });
    }

    if (json.id?.length) {
      json.id.forEach((item) => {
        if (!item.position) {
          item.position = 'banner';
        }
      });
    }

    return json;
  };

  const updatedAllStatus = (status, product) => {
    const promises = [];
    let personalData = {};
    if (rejectModules.length > 1) {
      rejectModules.forEach((element, index) => {
        const reasonEn = generateReason('en', index);
        const reasonIn = generateReason('in', index);
        const rejectionReasonJson = getRejectionJson(index);
        if (reasonEn && reasonIn) {
          if (element.value === 'Kyc1') {
            if (kyc1Data.status === 'NOT UPLOADED') {
              showNotification('error', 'Kyc1 is not uploaded');
            } else {
              promises.push(
                updatedKyc1Status(reasonEn, reasonIn, rejectionReasonJson, status, product)
              );
            }
          }
          if (element.value === 'Kyc2') {
            if (kyc2Data.status === 'NOT UPLOADED') {
              showNotification('error', 'Kyc2 is not uploaded');
            } else {
              personalData = { reasonEn, reasonIn, rejectionReasonJson, status, product };
            }
          }
          if (element.value === 'Occupation') {
            if (occupationData.status === 'NOT UPLOADED') {
              showNotification('error', 'Occupation is not uploaded');
            } else {
              promises.push(updatedOccupationStatus(rejectionReasonJson, status, product));
            }
          }
          if (element.value === 'Bank') {
            if (product === 'KYC2') {
              if (
                mutualFundBankData.status === 'NOT UPLOADED' ||
                (mutualFundBankData && mutualFundBankData.status.toUpperCase()) === 'VERIFIED'
              ) {
                showNotification('error', 'Cannot reject bank data');
              } else {
                promises.push(
                  updateBankAccountStatus(reasonEn, reasonIn, mutualFundBankData, product)
                );
              }
            } else if (
              idssBankData.status === 'NOT UPLOADED' ||
              (idssBankData && idssBankData.status.toUpperCase()) === 'VERIFIED'
            ) {
              showNotification('error', 'Cannot reject bank data');
            } else {
              promises.push(updateBankAccountStatus(reasonEn, reasonIn, idssBankData, product));
            }
          }
        }
      });
    } else {
      showNotification('error', 'Rejection reason is required');
    }

    if (promises.length > 0 || Object.keys(personalData).length) {
      return Promise.allSettled(promises)
        .then(() => {
          if (Object.keys(personalData).length) {
            return updatedPersonaDataStatus(personalData);
          }
        })
        .then(() => {
          window.location.reload();
        })
        .finally(() => {
          setShowRejectionModal(false);
        });
    }
  };

  const resendFgsData = () => {
    setLoader(true);
    return makeRequest({
      url: `${REMOTE.USER_IDSS_KYC_INFORMATION}/resendDataToFgs`,
      method: 'POST',
      data: {
        userId: idssKycData.userId,
        status: idssKycData.status
      }
    })
      .then((result) => {
        if (result.data?.success) {
          showNotification('success', 'Status Updated');
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const mutualFundData = [
    {
      label: 'Status',
      value: kyc2Data?.status || ''
    },
    kyc2Data &&
      !['APPROVED', 'PENDING', 'READY_TO_SUBMIT'].includes(kyc2Data.status) && {
        label: 'Rejection Reason',
        value: kyc2Data?.rejectionReasonJson
          ? getRejectionReasonInIndonesianLang(kyc2Data.rejectionReasonJson)
          : ''
      },
    {
      label: 'Last Upload Time',
      value: kyc2Data.lastUploadTime ? dateFormatter(kyc2Data.lastUploadTime) : ''
    },
    {
      label: 'Last Update Time',
      value: kyc2Data.updatedAt ? dateFormatter(kyc2Data.updatedAt) : ''
    },
    kyc2Data.status === 'APPROVED' && {
      label: 'sid',
      value: kyc2Data?.sid || ''
    },
    kyc2Data.status === 'APPROVED' && {
      label: 'ifua',
      value: kyc2Data?.ifua || ''
    }
  ];

  const gssTableData = [
    {
      label: 'Status',
      value: gssKycData?.status || ''
    },
    {
      label: 'Last Upload Time',
      value: gssKycData.lastUploadTime ? dateFormatter(gssKycData.lastUploadTime) : ''
    },
    {
      label: 'Last Update Time',
      value: gssKycData.updatedAt ? dateFormatter(gssKycData.updatedAt) : ''
    }
  ];

  const idssTableData = [
    {
      label: 'Status',
      value: idssKycData?.status || ''
    },
    {
      label: 'Tnc',
      value: idssKycData?.tnc ? 'True' : ''
    },
    ['WAITING_TO_UPDATE_DATA_JAGO', 'APPROVED'].includes(idssKycData.status) && {
      label: 'sre',
      value: idssKycData.sre
    },
    ['WAITING_TO_UPDATE_DATA_JAGO', 'APPROVED'].includes(idssKycData.status) && {
      label: 'rdn',
      value: idssKycData.rdn
    },
    ['WAITING_TO_UPDATE_DATA_JAGO', 'APPROVED'].includes(idssKycData.status) && {
      label: 'sid',
      value: idssKycData.sid
    },
    [('APPROVED', 'REJECTED_FGS')].includes(idssKycData.status.toUpperCase()) && {
      label: 'Fgs Response',
      value: idssKycData?.meta?.response_fgs
        ? typeof idssKycData.meta.response_fgs === 'string'
          ? idssKycData.meta.response_fgs
          : idssKycData.meta.response_fgs.message
        : ''
    },
    ['SUBMISSION_FGS_ERROR'].includes(idssKycData.status.toUpperCase()) && {
      label: 'FGS submission error',
      value: idssKycData?.rejectionReason || ''
    },
    ['SUBMISSION_FGS_ERROR', 'REJECTED_FGS', 'SUBMITTED_TO_FGS'].includes(
      idssKycData.status.toUpperCase()
    ) && {
      label: 'Resend data to FGS',
      value: (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
          <Button size="small" variant="contained" disabled onClick={() => resendFgsData()}>
            Resend data
          </Button>
          <Typography color="error">IDSS KYC Service Not available</Typography>
        </Box>
      )
    },
    {
      label: 'Last Upload Time',
      value: idssKycData.lastUploadTime ? dateFormatter(idssKycData.lastUploadTime) : ''
    },
    {
      label: 'Last Update Time',
      value: idssKycData.updatedAt ? dateFormatter(idssKycData.updatedAt) : ''
    },
    {
      label: 'Bca rdn Terms',
      value: idssKycData.bcaRdnTerms ? <S3DownloadLink url={idssKycData.bcaRdnTerms} /> : ''
    },
    {
      label: 'Bca Tapres Terms',
      value: idssKycData.bcaTapresTerms ? <S3DownloadLink url={idssKycData.bcaTapresTerms} /> : ''
    }
  ];

  let modules = [
    { index: 0, value: 'Kyc1', label: 'Basic Detail' },
    { index: 1, value: 'Kyc2', label: 'Personal Detail' }
  ];

  if (product === 'KYC2') {
    modules.push({ index: 2, value: 'Bank', label: 'Bank' });
  }
  if (product === 'IDSS_KYC') {
    modules.push({ index: 2, value: 'Bank', label: 'Bank' });
    modules.push({ index: 3, value: 'Occupation', label: 'Occupation' });
  }

  if (product === 'GSS_KYC') {
    modules.push({ index: 2, value: 'Occupation', label: 'Occupation' });
  }

  return (
    <Box sx={{ mt: 3 }}>
      <Container spacing={4}>
        <Item xs={2} sx={{ mt: 2 }}>
          <Typography sx={{ fontWeight: 'bolder' }} align="right">
            Mutual Fund
          </Typography>
        </Item>
        <Item xs={8} sx={{ textAlign: 'center' }}>
          <TableContainer>
            <Table>
              <TableBody>
                {mutualFundData.map(
                  (mutualFundRow, key) =>
                    mutualFundRow && (
                      <TableRow key={key}>
                        <TableCell>{mutualFundRow.label}</TableCell>
                        <TableCell>{mutualFundRow.value}</TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Item>
      </Container>

      <Container sx={{ mt: 1 }} spacing={4}>
        <Item xs={2} sx={{ mt: 2 }}>
          <Typography sx={{ fontWeight: 'bolder' }} align="right">
            IDSS KYC Data
          </Typography>
        </Item>
        <Item xs={8} sx={{ textAlign: 'center' }}>
          <TableContainer>
            <Table>
              <TableBody>
                {idssTableData.map(
                  (idssTableRow, key) =>
                    idssTableRow && (
                      <TableRow key={key}>
                        <TableCell>{idssTableRow.label}</TableCell>
                        <TableCell>{idssTableRow.value}</TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Item>
      </Container>

      <Container sx={{ mt: 1 }} spacing={4}>
        <Item xs={2} sx={{ mt: 2 }}>
          <Typography sx={{ fontWeight: 'bolder' }} align="right">
            GSS KYC Data
          </Typography>
        </Item>
        <Item xs={8} sx={{ textAlign: 'center' }}>
          <TableContainer>
            <Table>
              <TableBody>
                {gssTableData.map(
                  (gssTableRow, key) =>
                    gssTableRow && (
                      <TableRow key={key}>
                        <TableCell>{gssTableRow.label}</TableCell>
                        <TableCell>{gssTableRow.value}</TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Item>
      </Container>
      <RejectionReason
        showRejectionModal={showRejectionModal}
        setShowRejectionModal={setShowRejectionModal}
        setOptions={setOptions}
        setRejectModules={setRejectModules}
        rejectModules={rejectModules}
        rejectModulesRejectionOption={rejectModulesRejectionOption}
        options={options}
        updatedAllStatus={updatedAllStatus}
        modules={modules}
        product={product}
      />
    </Box>
  );
};

ProductPage.propTypes = {
  kyc2Data: PropTypes.object.isRequired,
  kyc1Data: PropTypes.object.isRequired,
  occupationData: PropTypes.object.isRequired,
  idssKycData: PropTypes.object.isRequired,
  idssBankData: PropTypes.object.isRequired,
  mutualFundBankData: PropTypes.object.isRequired,
  gssKycData: PropTypes.object.isRequired
};

export default ProductPage;
