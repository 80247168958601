import React from 'react';
import PluangListPage from 'components/organisms/pluangListTable';
import { filter, header } from './constants';

const FundTransactionList = () => {
  return (
    <PluangListPage
      title={'Fund Transactions'}
      headers={header}
      filters={filter}
      resource={'FUND_TRANSACTIONS'}
    />
  );
};

export default FundTransactionList;
