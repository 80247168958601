import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { toFormattedCurrency } from 'helpers/stringHelper';
import PropTypes from 'prop-types';
import { useState } from 'react';

const NonProducts = ({ userPortfolio }) => {
  const [open, setOpen] = useState(false);

  const netAssetValueIdr =
    userPortfolio?.userCurrentPortfolio?.combinedAssetPortfolio?.profitLossSummary
      ?.netAssetValueIdr || 0;
  const investedValueIdr =
    userPortfolio?.userCurrentPortfolio?.combinedAssetPortfolio?.profitLossSummary
      ?.investedValueIdr || 0;
  const unrealizedPnLIdr =
    userPortfolio?.userCurrentPortfolio?.combinedAssetPortfolio?.profitLossSummary
      ?.unrealizedPnLMinusCashIdr || 0;
  const unrealizedAssetPnLIdr =
    userPortfolio?.userCurrentPortfolio?.combinedAssetPortfolio?.profitLossSummary
      ?.unrealizedAssetPnLMinusCashIdr || 0;
  const unrealizedForexPnLIdr =
    userPortfolio?.userCurrentPortfolio?.combinedAssetPortfolio?.profitLossSummary
      ?.unrealizedForexPnLMinusCashIdr || 0;

  const totalCashIdr =
    userPortfolio?.userCurrentPortfolio?.combinedAssetPortfolio?.portfolioCashSummary
      ?.totalCashIdr || 0;
  const idrCashValue =
    userPortfolio?.userCurrentPortfolio?.combinedAssetPortfolio?.portfolioCashSummary
      ?.idrCashValue || 0;
  const usdCashMargin =
    userPortfolio?.userCurrentPortfolio?.combinedAssetPortfolio?.portfolioCashSummary
      ?.usdCashMargin || 0;
  const usdCashMarginIdr =
    userPortfolio?.userCurrentPortfolio?.combinedAssetPortfolio?.portfolioCashSummary
      ?.usdCashMarginIdr || 0;
  const forexGainIdr =
    userPortfolio?.userCurrentPortfolio?.combinedAssetPortfolio?.portfolioCashSummary
      ?.forexGainIdr || 0;

  const realizedPnLIdr =
    userPortfolio?.userCurrentPortfolio?.combinedAssetPortfolio?.profitLossSummary
      ?.realizedPnLIdr || 0;
  const realizedAssetPnLIdr =
    userPortfolio?.userCurrentPortfolio?.combinedAssetPortfolio?.profitLossSummary
      ?.realizedAssetPnLIdr || 0;
  const realizedForexPnLIdr =
    userPortfolio?.userCurrentPortfolio?.combinedAssetPortfolio?.profitLossSummary
      ?.realizedForexPnLIdr || 0;
  const dividendIdr = userPortfolio?.userCurrentPortfolio?.combinedAssetPortfolio?.dividendIdr || 0;
  const rewardsReceived =
    userPortfolio?.userCurrentPortfolio?.combinedAssetPortfolio?.freeCredit || 0;

  const usStocksValue =
    (userPortfolio?.userCurrentPortfolio?.globalStockPortfolio?.usStocksTotalValues?.value || 0) +
    (userPortfolio?.userCurrentPortfolio?.stockIndexPortfolio?.stockIndexesTotalValue?.value || 0);

  const usStocksValueIdr =
    (userPortfolio?.userCurrentPortfolio?.globalStockPortfolio?.usStocksTotalValues?.valueIdr ||
      0) +
    (userPortfolio?.userCurrentPortfolio?.stockIndexPortfolio?.stockIndexesTotalValue?.valueIdr ||
      0);

  const usStocksUnrealisedPnL =
    (userPortfolio?.userCurrentPortfolio?.globalStockPortfolio?.usStocksTotalValues
      ?.unrealisedGain || 0) +
    (userPortfolio?.userCurrentPortfolio?.stockIndexPortfolio?.stockIndexesTotalValue
      ?.unrealisedGain || 0);

  const usStocksUnrealisedPnLIdr =
    (userPortfolio?.userCurrentPortfolio?.globalStockPortfolio?.usStocksTotalValues
      ?.unrealisedGainIdr || 0) +
    (userPortfolio?.userCurrentPortfolio?.stockIndexPortfolio?.stockIndexesTotalValue
      ?.unrealisedGainIdr || 0);

  const usStocksAssetUnrealisedPnLIdr =
    (userPortfolio?.userCurrentPortfolio?.globalStockPortfolio?.usStocksTotalValues
      ?.assetUnrealisedGainIdr || 0) +
    (userPortfolio?.userCurrentPortfolio?.stockIndexPortfolio?.stockIndexesTotalValue
      ?.assetUnrealisedGainIdr || 0);

  const usStocksForexUnrealisedPnLIdr =
    (userPortfolio?.userCurrentPortfolio?.globalStockPortfolio?.usStocksTotalValues
      ?.forexUnrealisedGainIdr || 0) +
    (userPortfolio?.userCurrentPortfolio?.stockIndexPortfolio?.stockIndexesTotalValue
      ?.forexUnrealisedGainIdr || 0);

  return (
    <Accordion expanded={open} onChange={() => setOpen(!open)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h5">Non-Product</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell variant="head">Non Product</TableCell>
                <TableCell variant="head">Values (IDR)</TableCell>
                <TableCell variant="head">Values (USD)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Net Asset Value (IDR)</TableCell>
                <TableCell colSpan={2}>
                  {toFormattedCurrency(netAssetValueIdr, { maximumFractionDigits: 0 })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 6 }}>Invested Value (IDR)</TableCell>
                <TableCell colSpan={2}>
                  {toFormattedCurrency(investedValueIdr, { maximumFractionDigits: 0 })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 6 }}>Unrealized PnL (IDR)</TableCell>
                <TableCell colSpan={2}>
                  {toFormattedCurrency(unrealizedPnLIdr, { maximumFractionDigits: 0 })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 10 }}>Unrealized Asset PnL (IDR)</TableCell>
                <TableCell colSpan={2}>
                  {toFormattedCurrency(unrealizedAssetPnLIdr, { maximumFractionDigits: 0 })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 10 }}>Unrealized Forex PnL (IDR)</TableCell>
                <TableCell colSpan={2}>
                  {toFormattedCurrency(unrealizedForexPnLIdr, { maximumFractionDigits: 0 })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Total Cash (IDR)</TableCell>
                <TableCell colSpan={2}>
                  {toFormattedCurrency(totalCashIdr, { maximumFractionDigits: 0 })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 6 }}>IDR Cash</TableCell>
                <TableCell colSpan={2}>
                  {toFormattedCurrency(idrCashValue, { maximumFractionDigits: 0 })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 6 }}>USD Cash Margin</TableCell>
                <TableCell>
                  {toFormattedCurrency(usdCashMarginIdr, { maximumFractionDigits: 0 })}
                </TableCell>
                <TableCell>
                  {toFormattedCurrency(usdCashMargin, { maximumFractionDigits: 0 })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 6 }}>Forex Gain</TableCell>
                <TableCell colSpan={2}>
                  {toFormattedCurrency(forexGainIdr, { maximumFractionDigits: 0 })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Realized P&L</TableCell>
                <TableCell colSpan={2}>{realizedPnLIdr}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 6 }}>Asset Gain/Loss</TableCell>
                <TableCell colSpan={2}>
                  {toFormattedCurrency(realizedAssetPnLIdr, { maximumFractionDigits: 0 })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 6 }}>Forex Gain/Loss</TableCell>
                <TableCell colSpan={2}>
                  {toFormattedCurrency(realizedForexPnLIdr, { maximumFractionDigits: 0 })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 6 }}>Dividends Received</TableCell>
                <TableCell colSpan={2}>
                  {toFormattedCurrency(dividendIdr, { maximumFractionDigits: 0 })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 6 }}>Rewards Received</TableCell>
                <TableCell colSpan={2}>
                  {toFormattedCurrency(rewardsReceived, { maximumFractionDigits: 0 })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} variant="head">
                  US Stocks
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Value</TableCell>
                <TableCell>
                  {toFormattedCurrency(usStocksValueIdr, { maximumFractionDigits: 0 })}
                </TableCell>
                <TableCell>
                  {toFormattedCurrency(usStocksValue, { maximumFractionDigits: 0 })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Unrealised P&L</TableCell>
                <TableCell>
                  {toFormattedCurrency(usStocksUnrealisedPnLIdr, { maximumFractionDigits: 0 })}
                </TableCell>
                <TableCell>
                  {toFormattedCurrency(usStocksUnrealisedPnL, { maximumFractionDigits: 0 })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 6 }}>Asset Gain/Loss</TableCell>
                <TableCell colSpan={2}>
                  {toFormattedCurrency(usStocksAssetUnrealisedPnLIdr, { maximumFractionDigits: 0 })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 6 }}>Forex Gain/Loss</TableCell>
                <TableCell colSpan={2}>
                  {toFormattedCurrency(usStocksForexUnrealisedPnLIdr, { maximumFractionDigits: 0 })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} variant="head">
                  Cumulative values
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 6 }}>Success Top Up</TableCell>
                <TableCell colSpan={2}>
                  {userPortfolio?.successTopup
                    ? toFormattedCurrency(userPortfolio.successTopup, { maximumFractionDigits: 0 })
                    : 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 6 }}>Pending Cashout</TableCell>
                <TableCell colSpan={2}>
                  {userPortfolio?.pendingCashout
                    ? toFormattedCurrency(userPortfolio.pendingCashout, {
                        maximumFractionDigits: 0
                      })
                    : 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 6 }}>Completed Cashout</TableCell>
                <TableCell colSpan={2}>
                  {userPortfolio?.completedCashout
                    ? toFormattedCurrency(userPortfolio.completedCashout, {
                        maximumFractionDigits: 0
                      })
                    : 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 6 }}>Cashback</TableCell>
                <TableCell colSpan={2}>
                  {userPortfolio?.cashback
                    ? toFormattedCurrency(userPortfolio.cashback, { maximumFractionDigits: 0 })
                    : 0}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

NonProducts.propTypes = {
  userPortfolio: PropTypes.object
};

export default NonProducts;
