import PluangListPage from 'components/organisms/pluangListTable';

const OpsUserBankAccountList = () => {
  const filters = [
    {
      id: 'date',
      type: 'dateRange',
      label: 'DateFilter'
    },
  ];

  const headers = [
    { header_name: 'Account Name', db_column_name: 'accountName' },
    { header_name: 'Account No.', db_column_name: 'accountNumber' },
    { header_name: 'Bank Id', db_column_name: 'bankId' },
    { header_name: 'Ksei Order', db_column_name: 'kseiBankOrder' },
    { header_name: 'Forex Bank', db_column_name: 'forexBank' },
    { header_name: 'Status', db_column_name: 'status' },
    { header_name: 'Action', db_column_name: 'action', isEdit: 'true' }
  ];

  return (
    <PluangListPage
      filters={filters}
      headers={headers}
      resource="BANK_ACCOUNTS"
      title="User Bank Accounts"
    />
  );
};

export default OpsUserBankAccountList;
