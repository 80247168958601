import { Backdrop } from '@mui/material';
import Container from 'components/atoms/Container';
import { IMAGES } from 'constants/images';
import PropTypes from 'prop-types';

const LoaderPortal = ({ isLoading = false }) => {
  return (
    <Container>
      {isLoading && (
        <Backdrop open sx={{ zIndex: 99999 }}>
          <img src={IMAGES.PLUANG_LOADER} alt="loading" width={100} height={100} />
        </Backdrop>
      )}
    </Container>
  );
};

LoaderPortal.propTypes = {
  isLoading: PropTypes.bool.isRequired
};

export default LoaderPortal;
