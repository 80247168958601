import PLUANG_LOGIN_BG from 'assets/images/background.jpg';
import PLUANG_LOGIN_LOGO from 'assets/images/login-logo.png';

export const IMAGES = {
  PLUANG_LOADER:
    'https://pluang-production-financial-content.s3.ap-southeast-1.amazonaws.com/images/loader_white-bg.gif',
  PLUANG_LOGIN_BG,
  PLUANG_LOGIN_LOGO,
  PLUANG_LOGO:
    'https://pluang-production-uploads.s3-ap-southeast-1.amazonaws.com/website-items/pluang-website/pluang-logo-header-font.svg'
};
