import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import StatusChip from 'components/atoms/StatusChip';
import ImageViewer from 'components/molecules/ImageViewer';
import ListDialog from 'components/molecules/ListDialog';
import { REMOTE } from 'constants/serverRoutes';
import { dateFormatter } from 'helpers/dateChanger';
import { validateIrisName } from 'helpers/validators';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as BrowserLink, useNavigate, useParams } from 'react-router-dom';
import { makeRequest } from 'services';
import { useNotification } from 'services/hooks';
import { useStateValue } from 'utils/redux';
import { OPEN_MODAL } from 'utils/redux/actions';
import { bankAccountSchema } from 'utils/schemas/bankAccount-schema.joi';

const listHeaders = ['createdAt', 'fromState', 'toState', 'rejectionReason', 'rejectionReasonId'];

const OpsUserBankAccountEdit = () => {
  const [appState, dispatch] = useStateValue();
  const navigate = useNavigate();
  const { userBankId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showRejectionModal, setRejectionModal] = useState(false);
  const { showNotification } = useNotification();
  const [bankAccountData, setBankAccountData] = useState({});
  const [bankAccountStatus, setBankAccountStatus] = useState('');
  const [userData, setUserData] = useState({});
  const [irisData, setIrisData] = useState({});
  const [kycData, setKycData] = useState(null);
  const [rejectionReasonLogData, setRejectionReasonLogData] = useState({});
  const [rejectionReasonOptions, setRejectionReasonOptions] = useState({
    englishOptions: [],
    bahasaOptions: []
  });
  const [kycError, setKycError] = useState('');
  const [isButtonDisabled, setisButtonDisabled] = useState(false);
  const methods = useForm({
    defaultValues: { accountHolderName: '' },
    resolver: joiResolver(bankAccountSchema)
  });
  useEffect(() => userBankId && getBankAccount(userBankId), []);

  const getBankAccount = (userBankId) => {
    const bankId = userBankId ? Number(userBankId) : '';
    makeRequest({
      url: `${REMOTE.BANK_ACCOUNTS}/${bankId}`
    })
      .then((result) => {
        if (result.data?.data?.length === 1) {
          let bankAccountData = result.data.data[0];
          setBankAccountData(bankAccountData);
          setBankAccountStatus(bankAccountData.status);
          if (bankAccountData?.bank) {
            getIrisValidation(
              bankAccountData.bank.code,
              bankAccountData.accountNumber,
              bankAccountData.forexBank,
              bankAccountData.beneficiaryFullName
            );
            getUser(bankAccountData.accountId);
          } else {
            pushBack();
          }
        } else {
          pushBack();
        }
      })
      .catch((error) => {
        pushBack();
      });
  };

  const getUser = (accountId) => {
    if (appState?.sfId) {
      makeRequest({
        url: `${REMOTE.USER}/search`,
        params: {
          id: accountId,
          salesForceId: appState.sfId
        }
      }).then((result) => {
        if (result.data?.data?.length === 1) {
          let user = result.data.data[0];
          getKyc1(user.user.id);
          setUserData(user);
          if (user.user.kycStatus === 'REJECTED') {
            setKycError('Cannot Approve. Kyc1 is Rejected');
          }
        }
      });
    }
  };

  const getIrisValidation = (bankName, accountNo, forexBank, beneficiaryFullName) => {
    if (!bankName && !accountNo) pushBack();
    makeRequest({
      url: `${REMOTE.BANK_ACCOUNTS}/validation`,
      params: {
        bankName,
        accountNo,
        forexBank,
        beneficiaryFullName
      }
    }).then((result) => {
      if (result.data?.data) {
        let irisData = result.data.data;
        setIrisData(irisData);
        methods.setValue('accountHolderName', irisData.account_name);
      }
    });
  };

  const getKyc1 = (userId) => {
    makeRequest({
      url: `${REMOTE.KYC1}/filter`,
      params: {
        userId: userId
      }
    }).then((result) => {
      if (result.data?.data?.length === 1) {
        setKycData(result.data.data[0]);
      }
    });
  };

  const getRejectionReasonLog = (userBankId) => {
    if (!userBankId) pushBack();
    if (!rejectionReasonLogData.length > 0) {
      makeRequest({
        url: `${REMOTE.BANK_ACCOUNTS}/stateTransition/filter`,
        params: {
          userBankId,
          toState: 'REJECTED'
        }
      }).then((result) => {
        if (result.data?.data?.length > 0) {
          setRejectionReasonLogData(result.data.data);
          openRejectionReasonDialog(result.data.data);
        } else {
          showNotification('error', 'No rejection reason logs found');
        }
      });
    } else openRejectionReasonDialog(rejectionReasonLogData);
  };

  const openRejectionReasonDialog = (data) => {
    dispatch({
      type: OPEN_MODAL,
      modalContent: <ListDialog title="Rejection Reason logs" data={data} headers={listHeaders} />
    });
  };

  const pushBack = () => {
    navigate(-1, { replace: true });
  };

  const updateKseiOrderForUsdBank = () => {
    makeRequest({
      method: 'PUT',
      url: `${REMOTE.BANK_ACCOUNTS}/update/usdBank`,
      data: {
        userBankId: bankAccountData.id,
        userId: bankAccountData.userId
      }
    })
      .then((result) => {
        if (result.data && result.data.success) {
          showNotification('success', 'KSEI order updated successfully');
          getBankAccount(userBankId);
        } else {
          pushBack();
        }
      })
      .catch(() => {
        pushBack();
      });
  };

  const closeRejectionDialog = () => {
    setRejectionModal(false);
    setRejectionReasonOptions({ englishOptions: [], bahasaOptions: [] });
  };

  const showButtons = () => {
    return (
      <Box sx={{ display: 'flex', gap: 2, my: 2 }}>
        {kycError}  
        {kycData?.idCardPicture && (
          <Button color="info" size="small" onClick={() => setShowModal(true)} variant="contained">
            Compare KTP Photo
          </Button>
        )}
      </Box>
    );
  };

  return (
    <>
      <Card>
        <CardContent>
          <Box display="flex" justifyContent="space-between" pt={2}>
            <Typography variant="h5">Bank Account Details</Typography>
            <Typography variant="h6">{bankAccountStatus}</Typography>
          </Box>
          <Table>
            <TableBody>
              {bankAccountData && userData?.user ? (
                <>
                  <TableRow>
                    <TableCell>User ID</TableCell>
                    <TableCell>{userData.user.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>KYC Status</TableCell>
                    {kycData ? (
                      <TableCell>
                        {kycData.status}
                        <Link
                          component={BrowserLink}
                          to={`/ops/kyc/users/view/${bankAccountData.userId}`}>
                          <Button sx={{ ml: 2 }} size="small" variant="contained">
                            KYC1 Details
                          </Button>
                        </Link>
                      </TableCell>
                    ) : (
                      <TableCell>No Data Found</TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>Bank Name</TableCell>
                    <TableCell>{bankAccountData.bank ? bankAccountData.bank.name : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Account No.</TableCell>
                    <TableCell>{bankAccountData.accountNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Beneficiary Name</TableCell>
                    <TableCell>{bankAccountData.accountName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Pluang Name</TableCell>
                    <TableCell>
                      {kycData && kycData.status === 'VERIFIED' && kycData.nameOnIdentityCard
                        ? kycData.nameOnIdentityCard
                        : userData.user.name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>IRIS Validation Status</TableCell>
                    {bankAccountData.accountName &&
                    irisData &&
                    irisData.account_name &&
                    userData.user &&
                    userData.user.name &&
                    bankAccountData.accountName.toLowerCase() ===
                      validateIrisName(irisData.account_name) &&
                    (kycData && kycData.status === 'VERIFIED' && kycData.nameOnIdentityCard
                      ? validateIrisName(irisData.account_name) ===
                        kycData.nameOnIdentityCard.toLowerCase()
                      : validateIrisName(irisData.account_name) ===
                        userData.user.name.toLowerCase()) ? (
                      <TableCell>
                        <StatusChip label="VERIFIED" color="success" />
                      </TableCell>
                    ) : (
                      <TableCell>
                        <StatusChip label="MISMATCH" color="error" />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>Similarity Score</TableCell>
                    <TableCell>{bankAccountData.nameSimilarityScore}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Auto Updated</TableCell>
                    <TableCell>{bankAccountData.autoUpdated ? 'YES' : 'NO'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Mf Status</TableCell>
                    <TableCell>{bankAccountData.kseiStatus}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Mf Bank Order</TableCell>
                    <TableCell>{bankAccountData.kseiBankOrder}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Forex Bank</TableCell>
                    <TableCell>{bankAccountData.forexBank ? 'True' : 'False'}</TableCell>
                  </TableRow>
                  {bankAccountData && bankAccountData.status === 'REJECTED' && (
                    <TableRow>
                      <>
                        <TableCell>Rejection Reason (Bahasa)</TableCell>
                        <TableCell>{bankAccountData.rejectionReasonId}</TableCell>
                      </>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>Last Upload Time</TableCell>
                    <TableCell>{dateFormatter(bankAccountData.lastUploadTime)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Verified By</TableCell>
                    <TableCell>{bankAccountData.verifiedById}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Last Updated Time</TableCell>
                    <TableCell>{dateFormatter(bankAccountData.updatedAt)}</TableCell>
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>No Data Found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <>
            {showButtons()}
            <Box sx={{ display: 'flex', gap: 2, my: 2 }}>
              {bankAccountData &&
                bankAccountData.status === 'VERIFIED' &&
                bankAccountData.forexBank &&
                !bankAccountData.kseiStatus && (
                  <Button
                    variant="contained"
                    onClick={() => updateKseiOrderForUsdBank()}
                    size="small">
                    Set Mutual Fund Bank
                  </Button>
                )}
              <Button
                variant="contained"
                onClick={() => getRejectionReasonLog(bankAccountData.id)}
                size="small">
                Rejection reason logs
              </Button>
              <Button color="warning" size="small" variant="contained" onClick={() => pushBack()}>
                Back
              </Button>
            </Box>
          </>
        </CardContent>
      </Card>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              minWidth: '800px',
              maxWidth: '800px'
            }
          }
        }}
        open={showRejectionModal}
        onClose={closeRejectionDialog}>
      </Dialog>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              minWidth: '600px'
            }
          }
        }}
        onClose={() => setShowModal(false)}
        open={showModal}>
        <ImageViewer imgSrc={kycData?.idCardPicture} fullWidth={true} />
      </Dialog>
    </>
  );
};

export default OpsUserBankAccountEdit;
