import PluangListPage from 'components/organisms/pluangListTable';
import { BCA_TOPUP_STATUS } from '../../constants';

const filters = [
  {
    id: 'status',
    type: 'dropdown',
    label: 'Status Type',
    default: '',
    options: [
      { text: 'All', value: '' },
      { text: 'Pending', value: BCA_TOPUP_STATUS.PENDING },
      { text: 'Success', value: BCA_TOPUP_STATUS.SUCCESS },
      { text: 'Ops Checking', value: BCA_TOPUP_STATUS.OPS_CHECKING },
      { text: 'Rejected', value: BCA_TOPUP_STATUS.REJECTED },
      { text: 'Expired', value: BCA_TOPUP_STATUS.EXPIRE },
      { text: 'Canceled', value: BCA_TOPUP_STATUS.CANCELED },
      { text: 'Deny', value: BCA_TOPUP_STATUS.DENY },
      { text: 'Failed', value: BCA_TOPUP_STATUS.FAILED },
      { text: 'Unpaid', value: BCA_TOPUP_STATUS.UNPAID }
    ]
  },
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];

const headers = [
  { header_name: 'Topup Id', db_column_name: 'id' },
  { header_name: 'Created', db_column_name: 'created' },
  { header_name: 'Payment Channel', db_column_name: 'paymentChannel' },
  { header_name: 'Amount', db_column_name: 'amount', formattedCurrency: true },
  { header_name: 'Status', db_column_name: 'status' },
  { header_name: 'Action', db_column_name: 'action', isView: 'true' }
];

const BcaTopupList = () => {
  return (
    <PluangListPage title="BCA Topup" headers={headers} filters={filters} resource="BCA_TOPUP" />
  );
};

export default BcaTopupList;
