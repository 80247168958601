import React from 'react';
import PluangListPage from 'components/organisms/pluangListTable';
import { filters, headers } from './constants';

const USDTransaction = (props) => {
  return (
    <PluangListPage
      {...props}
      title={'USD Transactions'}
      headers={headers}
      filters={filters}
      resource={'USD_TRANSACTIONS'}
    />
  );
};

export default USDTransaction;
