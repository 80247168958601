import { Card, CardContent, Divider, Typography } from '@mui/material';
import Container from 'components/atoms/Container';
import Item from 'components/atoms/Item';
import BasicTable from 'components/molecules/BasicTable';
import Config from 'config';
import { REMOTE } from 'constants/serverRoutes';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeRequest } from 'services';
import { useLoader } from 'services/hooks';
import { useStateValue } from 'utils/redux';

const PlatformMap = {
  [Config.GOINVEST_CLIENT_ID]: 'Go Invest',
  [Config.PLUANG_CLIENT_ID]: 'Pluang',
  [Config.PLUANG_WEB_CLIENT_ID]: 'Pluang Web'
};

const AutoDebitView = () => {
  const { userId } = useParams();
  const [appState] = useStateValue();
  const navigate = useNavigate();
  const [, setLoader] = useLoader();
  const [autoDebitData, setAutoDebitData] = useState({});

  useEffect(() => {
    if (userId) {
      getAutoDebit(userId);
    }
  }, []);

  const getAutoDebit = (id) => {
    setLoader(true);
    const query = {};
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    query.id = id;
    makeRequest({
      url: `${REMOTE.AUTO_INVEST}/filter`,
      params: query
    })
      .then((result) => {
        if (result.data?.success) {
          setAutoDebitData(result.data?.data?.[0]);
        } else {
          navigate(-1);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const autoDebitTableOne = [
    {
      label: 'Platform',
      value: autoDebitData && PlatformMap[autoDebitData.clientId]
    },
    {
      label: 'Asset',
      value: autoDebitData?.asset || 'N/A'
    },
    {
      label: 'Auto Debit Amount Type',
      value: autoDebitData?.autoDebitAmountType || 'N/A'
    },
    {
      label: 'Created',
      value: autoDebitData?.created || 'N/A'
    },
    {
      label: 'Debit Rule',
      value: JSON.stringify(autoDebitData?.debitRule) || 'N/A'
    }
  ];

  const autoDebitTableTwo = [
    {
      label: 'Partner Id',
      value: autoDebitData?.partnerId
    },
    {
      label: 'Status',
      value: autoDebitData?.status
    },
    {
      label: 'Info',
      value: JSON.stringify(autoDebitData?.info) || 'N/A'
    }
  ];

  return (
    <Card>
      <CardContent>
        <Container>
          <Item>
            <Typography variant="h5">Auto Debit View</Typography>
          </Item>
        </Container>
        <Divider sx={{ my: 2 }} />
        <Container spacing={3}>
          <Item xs={6}>
            <BasicTable tableData={autoDebitTableOne} />
          </Item>
          <Item xs={6}>
            <BasicTable tableData={autoDebitTableTwo} />
          </Item>
        </Container>
      </CardContent>
    </Card>
  );
};

export default AutoDebitView;
