import {
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import ImageMagnifier from 'components/molecules/ImageMagnifier';
import KycTaskTable from 'components/molecules/KycTaskTable';
import TableViewBasic from 'components/molecules/TableViewBasic';
import { REMOTE } from 'constants/serverRoutes';
import { birthDateFormatter, dateFormatter } from 'helpers/dateChanger';
import { useEffect, useState } from 'react';
import { makeRequest } from 'services/APIService';
import { useLoader, useNotification } from 'services/hooks';
import { useStateValue } from 'utils/redux';
import NonProducts from './nonProducts';
import Pockets from './pockets';
import Products from './products';

const UserView = () => {
  const [userState, setUserState] = useState({
    userData: {
      user: {},
      partner: {}
    },
    userBanksData: [],
    kycData: {
      user: {}
    },
    idCardPicture: '',
    selfPortraitPicture: '',
    npwpPicture: '',
    userPortfolio: {},
    isUserPluangPlus: false,
    active: '',
    rmId: 'N/A',
    virtualAccounts: []
  });
  const [userAmlRiskRatingData, setUserAmlRiskRatingData] = useState({});

  const [appState] = useStateValue();
  const { showNotification } = useNotification();
  const [, setLoader] = useLoader();

  useEffect(() => {
    setLoader(true);
    Promise.allSettled([
      getUser(),
      getUserBanks(),
      getIsUserPluangPlusAndRMId(),
      getUserVirtualAccounts()
    ]).finally(() => {
      setLoader(false);
    });
  }, [appState.sfId]);

  const getUser = () => {
    let query = {};
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    const sfId = appState.sfId;
    if (sfId) {
      makeRequest({
        url: `${REMOTE.ACCOUNT}/filter`,
        params: query
      }).then((result) => {
        if (result.data && result.data.data) {
          let user = result.data.data[0];
          setUserState((prevState) => ({
            ...prevState,
            userData: user,
            active: user && user.active
          }));
          getKyc1(sfId);
          getUserAmlRiskRating(sfId);
        }
      });
    }
  };

  const getIsUserPluangPlusAndRMId = () => {
    if (appState.sfId) {
      makeRequest({
        url: `${REMOTE.USER}/priorityTagsAndRMId`,
        params: { salesForceId: appState.sfId }
      }).then((result) => {
        if (result?.data?.data && result?.data?.success) {
          setUserState((prevState) => ({
            ...prevState,
            isUserPluangPlus: result?.data?.data?.isPriority,
            rmId:
              result?.data?.data?.rmId === 'RM is not assigned' ? 'N/A' : result?.data?.data?.rmId
          }));
        }
      });
    }
  };

  const getUserVirtualAccounts = () => {
    if (appState.sfId) {
      makeRequest({
        url: `${REMOTE.USER}/virtualAccounts`,
        params: { salesForceId: appState.sfId }
      }).then((result) => {
        if (result?.data?.data && result?.data?.success) {
          setUserState((prevState) => ({
            ...prevState,
            virtualAccounts: result?.data?.data
          }));
        }
      });
    }
  };

  const getUserPortfolio = () => {
    let query = {};
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    setLoader(true);
    makeRequest({
      url: `${REMOTE.USER}/userPortfolio`,
      params: query
    })
      .then((result) => {
        if (result.data && result.data.data && result.data.success) {
          setUserState((prevState) => ({
            ...prevState,
            userPortfolio: result.data.data
          }));
        } else {
          showNotification('error', 'User Portfolio Not found for this user');
        }
      })
      .catch(() => {
        showNotification('error', 'User Portfolio Not found for this user');
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getUserAmlRiskRating = (sfId) => {
    let query = {
      salesForceId: sfId
    };
    makeRequest({
      url: REMOTE.USER_AML_RISK_RATING,
      params: query
    }).then((result) => {
      if (result?.data?.data) {
        setUserAmlRiskRatingData(result.data.data);
      }
    });
  };

  const getUserBanks = () => {
    let query = {};
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
      setLoader(true);
      makeRequest({
        url: `${REMOTE.BANK_ACCOUNTS}/filter`,
        params: query
      })
        .then((result) => {
          if (result.data && result.data.data) {
            let userBanks = result.data.data;
            setUserState((prevState) => ({
              ...prevState,
              userBanksData: userBanks
            }));
          }
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  const getKyc1 = (sfId) => {
    let query = {};
    if (sfId) {
      query.salesForceId = sfId;
    }
    setLoader(true);
    makeRequest({
      url: `${REMOTE.KYC1}/filter`,
      params: query,
      ignoreError: true
    })
      .then((result) => {
        if (result.data && result.data.data && result.data.data.length === 1) {
          setUserState((prevState) => ({
            ...prevState,
            kycData: result.data.data[0],
            idCardPicture: result.data.data[0].idCardPicture,
            selfPortraitPicture: result.data.data[0].selfPortraitPicture,
            npwpPicture: result.data.data[0].npwpPicture
          }));
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const toggleStatus = (e) => {
    e.preventDefault();
    let query = {};
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    setLoader(true);
    makeRequest({
      method: 'PUT',
      url: `${REMOTE.USER}/update/status/${!userState.active}`,
      params: query
    })
      .then((result) => {
        if (result.data && result.data.success) {
          setUserState((prevState) => ({
            ...prevState,
            active: !prevState.active
          }));
        } else {
          showNotification('error', result.data);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getRowsData = (items) => {
    return items.map((row, index) => {
      return (
        <TableRow key={index}>
          <TableCell sx={{ width: '20%' }}>{row.accountName}</TableCell>
          <TableCell sx={{ width: '20%' }}>{row.accountNumber}</TableCell>
          <TableCell sx={{ width: '20%' }}>{row.bankName}</TableCell>
          <TableCell sx={{ width: '20%' }}>{row.status}</TableCell>
          <TableCell sx={{ width: '20%' }}>
            {row.defaultUserBank === true ? 'TRUE' : 'FALSE'}
          </TableCell>
        </TableRow>
      );
    });
  };

  const {
    kycData,
    userData,
    userBanksData,
    userPortfolio,
    active,
    isUserPluangPlus,
    rmId,
    virtualAccounts
  } = userState;

  const renderHeader = (header) => {
    return (
      <Typography
        variant="h5"
        sx={{
          background: '#eee',
          p: 1.25,
          border: '1px rgb(231, 230, 230) solid',
          mb: 2.5
        }}>
        {header}
      </Typography>
    );
  };

  const headers = [
    'Is Pluang+',
    'RM_id',
    'Email',
    'Name',
    'Date of Birth',
    'Identity Card Number',
    'Phone',
    'Basic Kyc Status',
    'MF Kyc Status',
    'GSS Kyc Status',
    'IDSS Kyc Status',
    'Status',
    'Edd Status',
    'Risk Rating Category',
    'Referral Code',
    'Referral Code Status',
    'Registered Date',
    'Last Updated'
  ];

  const values = [
    isUserPluangPlus ? 'True' : 'False',
    isUserPluangPlus ? rmId : 'N/A',
    <>
      {userData ? userData.email : ''}
      <Typography
        ml={2}
        variant="caption"
        color={userData && userData.emailVerified ? 'green' : 'red'}>
        {userData && userData.emailVerified ? 'Verified' : 'Not verified'}
      </Typography>
    </>,
    userData ? userData.name : '',
    userData && userData.dateOfBirth ? birthDateFormatter(userData.dateOfBirth) : '',
    kycData ? kycData.identityCardNumber : '',
    userData ? userData.phone : '',
    kycData ? kycData.status : '',
    userData && userData.ojkKycStatus ? userData.ojkKycStatus : 'N/A',
    userData && userData.gssKycStatus ? userData.gssKycStatus : 'N/A',
    userData && userData.idssKycStatus ? userData.idssKycStatus : 'N/A',
    userData && userData.active ? 'Active' : 'InActive',
    userData ? userData.eddStatus : 'Not requested',
    userData ? userData.riskRatingCategory : '',
    userData && userData.referralCode ? userData.referralCode : 'N/A',
    userData && userData.referralCodeActive ? 'Active' : 'InActive',
    userData ? dateFormatter(userData.created) : '',
    userData ? dateFormatter(userData.updated) : ''
  ];
  return (
    <>
      <Card sx={{ mb: 3, width: '40%' }}>
        <CardContent>
          <Typography variant="h5">User VA Numbers</Typography>
          <Box justifyContent="center" my={3}>
            {virtualAccounts?.map((data) => (
              <Typography key={data.id} variant="body1">
                Bank {data?.bankName} : {data?.virtualAccountNumber}
              </Typography>
            ))}
          </Box>
        </CardContent>
      </Card>
      <Box>
        {renderHeader('User details')}
        <KycTaskTable headers={headers} values={values} stripped={false} />
        <Box display="flex" justifyContent="center" my={2}>
          <Button size="small" variant="contained" onClick={toggleStatus}>
            {active === false || active === 'false' ? 'Activate' : 'De-activate'} User
          </Button>
        </Box>
      </Box>
      <Box>
        {userBanksData && userBanksData.length > 0 ? (
          <>
            {renderHeader('User bank Accounts')}
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Account Name</TableCell>
                  <TableCell>Account Number</TableCell>
                  <TableCell>Bank Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Default Bank</TableCell>
                </TableRow>
                {getRowsData(userBanksData)}
              </TableBody>
            </Table>
          </>
        ) : null}
        <Box sx={{ mb: 2 }}>
          <Box
            sx={{
              background: '#eee',
              p: 1.25,
              border: '1px rgb(231, 230, 230) solid'
            }}
            display="flex"
            justifyContent="space-between">
            <Typography variant="h5">User Balances</Typography>
            <Button size="small" variant="contained" onClick={getUserPortfolio}>
              Get User Portfolio
            </Button>
          </Box>
          {userPortfolio && Object.keys(userPortfolio).length > 0 && (
            <>
              <NonProducts userPortfolio={userPortfolio} />
              <Products userPortfolio={userPortfolio} />
              {Object.keys(userPortfolio?.userPocketPortfolio).length > 0 && (
                <Pockets userPortfolio={userPortfolio} />
              )}
            </>
          )}
        </Box>
        <Box
          sx={{
            background: '#eee',
            p: 1.25,
            border: '1px rgb(231, 230, 230) solid'
          }}>
          <Typography variant="h5">User KYC details</Typography>
        </Box>
        {kycData && kycData.id > 0 ? (
          <Box pl={2} pr={2} sx={{ border: '1px solid rgb(231, 230, 230)' }}>
            <TableViewBasic
              heading="Verification Check"
              body={{
                'Dukcapil Checking Response': kycData.asliriResponse,
                'PEP Status': userAmlRiskRatingData.pepCheck?.toString().toUpperCase(),
                'DTTOT Status': userAmlRiskRatingData.sanctionCheck?.toString().toUpperCase(),
                'Risk Rating': userAmlRiskRatingData.userAmlRiskRatingCategory?.toUpperCase()
              }}
            />
            <Box>
              <Typography variant="h6" pt={2}>
                Id Card Picture
              </Typography>
              <Box width="50%">
                {userState.idCardPicture && <ImageMagnifier url={userState.idCardPicture} />}
              </Box>
            </Box>
            <Box>
              <Typography variant="h6" pt={2}>
                Self Portrait Picture
              </Typography>
              <Box width="50%">
                {userState.selfPortraitPicture && (
                  <ImageMagnifier url={userState.selfPortraitPicture} />
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', color: 'red', m: 2 }}>
            No Data Found
          </Box>
        )}
        {userState.npwpPicture && (
          <Box>
            <Typography variant="h6">NPWP Picture </Typography>
            <Box>{userState.npwpPicture && <ImageMagnifier url={userState.npwpPicture} />}</Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default UserView;
