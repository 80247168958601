import React from 'react';
import PluangListPage from 'components/organisms/pluangListTable';
import { filters, headers } from './constants';

const RdnCashoutList = () => {
  return (
    <PluangListPage
      title={'Rdn Cashout List'}
      headers={headers}
      filters={filters}
      resource={'RDN_CASHOUT'}
    />
  );
};

export default RdnCashoutList;
