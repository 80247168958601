const Config = require('config');

export const filters = [
  {
    id: 'partnerId',
    type: 'typeAhead',
    label: 'Partner Name',
    resource: 'PARTNER',
    labelFields: ['companyName']
  },
  {
    id: 'transactionType',
    type: 'dropdown',
    label: 'Transaction Type',
    default: 'all',
    option: [
      { text: 'Type', value: 'all' },
      { text: 'Buy', value: 'buy' },
      { text: 'Sell', value: 'sell' }
    ]
  },
  {
    id: 'status',
    type: 'dropdown',
    label: 'Transaction Status',
    default: 'all',
    option: [
      { text: 'Status', value: 'all' },
      { text: 'Success', value: 'success' },
      { text: 'Failed', value: 'failed' },
      { text: 'Expired', value: 'expired' },
      { text: 'Pending', value: 'pending' }
    ]
  },
  {
    id: 'clientId',
    type: 'dropdown',
    label: 'Platform',
    default: 'all',
    option: [
      { text: 'Platform - all', value: 'all' },
      { text: 'Pluang', value: Config.PLUANG_CLIENT_ID },
      { text: 'Go Invest', value: Config.GOINVEST_CLIENT_ID }
    ]
  },
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];

export const headers = [
  { header_name: 'Trans No.', db_column_name: 'id' },
  { header_name: 'Trans Type', db_column_name: 'transactionType' },
  { header_name: 'Partner Id', db_column_name: 'partnerId' },
  { header_name: 'Price', db_column_name: 'finalAmount', formattedCurrency: true },
  { header_name: 'Amount', db_column_name: 'quantity' },
  { header_name: 'Admin Fee', db_column_name: 'fees', formattedCurrency: true },
  { header_name: 'Trans Status', db_column_name: 'status' },
  { header_name: 'Platform', db_column_name: 'clientId' },
  { header_name: 'Auto Invest', db_column_name: 'autoInvest' },
  { header_name: 'Date', db_column_name: 'created' }
];
