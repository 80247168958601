import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import KycTask from 'components/molecules/KycTask';
import KycTaskTable from 'components/molecules/KycTaskTable';

const UserBanks = ({ mutualFundBankData, idssBankData, otherBanksData, escalate }) => {
  const [showDetail, setShowDetail] = useState(false);

  const isEscalated = escalate.isUserBanksEscalated;

  const bankIds = otherBanksData.data ? otherBanksData.data.map((bankData) => bankData.id) : [];
  const bankStatuses = otherBanksData.data
    ? otherBanksData.data.map((bankData) => (
        <Box
          key={bankData.id}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {bankData.status}
        </Box>
      ))
    : [];

  const banksTableHeaders = [
    'Banks',
    'Mutual Fund default bank data',
    'IDSS default bank data',
    ...bankIds
  ];

  const banksTableValues = [
    'Status',
    <Box key={101} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      {mutualFundBankData.status}
    </Box>,
    <Box key={102} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      {idssBankData.status}
    </Box>,
    ...bankStatuses
  ];

  const kycTaskStatus = () => (
    <>{isEscalated && <Box className="escalated-status">ESCALATED</Box>}</>
  );

  const handleKycTaskClick = () => {
    setShowDetail(!showDetail);
  };

  return (
    <>
      {(Object.keys(mutualFundBankData).length &&
        Object.keys(idssBankData).length &&
        Object.keys(otherBanksData).length && (
          <KycTask
            title="Bank Accounts"
            status={kycTaskStatus()}
            onClick={handleKycTaskClick}
            open={showDetail}>
            <KycTaskTable headers={banksTableHeaders} values={banksTableValues} />
          </KycTask>
        )) ||
        null}
    </>
  );
};

UserBanks.propTypes = {
  mutualFundBankData: PropTypes.object,
  idssBankData: PropTypes.object,
  otherBanksData: PropTypes.object,
  escalate: PropTypes.object
};

export default UserBanks;
