import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import BasicTable from 'components/molecules/BasicTable';
import { REMOTE } from 'constants/serverRoutes';
import { dateFormatter } from 'helpers/dateChanger';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeRequest } from 'services';
import { BCA_CREDIT_NOTIFICATION_STATUS } from '../../constants';

const CreditNotificationView = () => {
  const { id } = useParams();
  const [creditNotificationDetails, setCreditNotificationDetails] = useState({});
  const [bcaTopupDetails, setBcaTopupDetails] = useState({});

  const getCreditNotificationDetails = () => {
    makeRequest({
      url: `${REMOTE.BCA_CREDIT_NOTIFICATIONS}/byReferenceNumber/${id}`
    }).then((result) => {
      if (result.data.data) {
        const creditNotificationDetails = result.data.data;
        creditNotificationDetails.refundDetails =
          JSON.parse(creditNotificationDetails.info)?.refundDetails || null;
        setCreditNotificationDetails(result.data.data);
        if (creditNotificationDetails?.topupId) {
          getBcaTopupDetails(creditNotificationDetails?.topupId);
        }
      }
    });
  };

  const getBcaTopupDetails = (topupId) => {
    makeRequest({
      url: `${REMOTE.TOPUP}/id/${topupId}`
    }).then((result) => {
      if (result.data.data) {
        const topupDetails = result.data.data;
        setBcaTopupDetails(topupDetails);
      }
    });
  };

  useEffect(() => getCreditNotificationDetails(), []);

  const topupRequestData = [
    { label: 'Top Up Id', value: bcaTopupDetails?.id },
    { label: 'Top Up Status', value: bcaTopupDetails?.status },
    { label: 'Top Up Amount', value: bcaTopupDetails?.amount },
    { label: 'Created', value: dateFormatter(bcaTopupDetails?.createdAt) }
  ];

  const creditNotificationData = [
    { label: 'Credit Notification Id', value: creditNotificationDetails?.id },
    { label: 'Credit Notification Status', value: creditNotificationDetails?.status },
    { label: 'Created', value: dateFormatter(creditNotificationDetails?.createdAt) },
    { label: 'Transaction Date', value: creditNotificationDetails?.transactionDate },
    { label: 'Transaction Time', value: creditNotificationDetails?.transactionTime },
    { label: 'Amount', value: creditNotificationDetails?.amount },
    { label: 'Reference Number', value: creditNotificationDetails?.referenceNumber },
    { label: 'Transaction Remarks', value: creditNotificationDetails?.transactionRemarks },
    { label: 'Name', value: creditNotificationDetails?.senderName }
  ];

  const refundDetailsData = [
    { label: 'Bank Name', value: creditNotificationDetails?.refundDetails?.bankName },
    {
      label: 'Bank Account Name',
      value: creditNotificationDetails?.refundDetails?.bankAccountName
    },
    {
      label: 'Bank Account Number',
      value: creditNotificationDetails?.refundDetails?.bankAccountNumber
    },
    {
      label: 'Transfer Date',
      value: dateFormatter(creditNotificationDetails?.refundDetails?.transferDate)
    },
    { label: 'JIRA Ticket', value: creditNotificationDetails?.refundDetails?.jiraTicket },
    { label: 'Refunded Amount', value: creditNotificationDetails?.amount },
    {
      label: 'Refunded Date',
      value:
        (creditNotificationDetails?.status === BCA_CREDIT_NOTIFICATION_STATUS.REFUNDED ||
          creditNotificationDetails?.status === BCA_CREDIT_NOTIFICATION_STATUS.INTERNAL_TRANSFER) &&
        dateFormatter(creditNotificationDetails?.updatedAt)
    }
  ];

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Credit Notification</Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        <BasicTable tableData={creditNotificationData} />
        <Typography variant="h5" sx={{ mt: 4 }}>
          Top Up request
        </Typography>
        <Divider sx={{ my: 2 }} />
        {creditNotificationDetails?.status === BCA_CREDIT_NOTIFICATION_STATUS.ASSIGNED ? (
          <BasicTable tableData={topupRequestData} />
        ) : (
          <Typography sx={{ mt: 2 }}>Unmatched</Typography>
        )}
        {creditNotificationDetails?.status !== BCA_CREDIT_NOTIFICATION_STATUS.ASSIGNED && (
          <>
            <Typography variant="h5" sx={{ mt: 4 }}>
              Refund Details
            </Typography>
            <Divider sx={{ my: 2 }} />
            <BasicTable tableData={refundDetailsData} />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default CreditNotificationView;
