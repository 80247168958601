import { Route, Routes } from 'react-router-dom';
import ViewPockets from './view';
import PocketsList from './list';

const OpsPocketTransactions = () => {
  return (
    <Routes>
      <Route path="/" element={<PocketsList />} />
      <Route path="view/:id" element={<ViewPockets />} />
    </Routes>
  );
};

export default OpsPocketTransactions;
