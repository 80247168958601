import { Box, Drawer, Toolbar } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import SidebarMenu from './SidebarMenu';

const Sidebar = ({ drawerWidth }) => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          backgroundColor: '#1d1d1d',
          color: '#adadad'
        },
        [`.MuiListItemIcon-root`]: { color: '#adadad' },
        [`.MuiBox-root`]: { paddingTop: '20px', overflowX: 'hidden' }
      }}>
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <SidebarMenu />
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  drawerWidth: PropTypes.number
};

export default Sidebar;
