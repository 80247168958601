import { Box, Dialog, FormControl, MenuItem, TextField } from '@mui/material';
import PropTypes from 'prop-types';

const RejectionReason = ({
  showRejectionModal,
  setShowRejectionModal,
  setOptions,
  setRejectModules,
  rejectModules,
  rejectModulesRejectionOption,
  options,
  updatedAllStatus,
  modules,
  product
}) => {
  const handleCloseDialog = () => {
    setShowRejectionModal(false);
    setOptions((prevState) => ({
      ...prevState,
      bahasaOptions: [],
      englishOptions: [],
      rejectModulesInOptions: [],
      rejectModulesEnOptions: []
    }));
    setRejectModules(['']);
  };

  return (
    <Dialog size="xl" fullWidth open={showRejectionModal} onClose={handleCloseDialog}>
    </Dialog>
  );
};

RejectionReason.propTypes = {
  showRejectionModal: PropTypes.bool.isRequired,
  setShowRejectionModal: PropTypes.func.isRequired,
  setOptions: PropTypes.func.isRequired,
  setRejectModules: PropTypes.func.isRequired,
  rejectModules: PropTypes.array,
  rejectModulesRejectionOption: PropTypes.object,
  options: PropTypes.object,
  updatedAllStatus: PropTypes.func,
  modules: PropTypes.array,
  product: PropTypes.string.isRequired
};

export default RejectionReason;
