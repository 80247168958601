/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import ListItem from '@mui/material/ListItem';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const SidebarMenuComponent = (props) => {
  const { onClick, link, children } = props;

  // If link is not set return the orinary ListItem
  if (!link || typeof link !== 'string') {
    return (
      <ListItem
        sx={{
          marginLeft: '10px',
          padding: '3px 16px',
          '&.active': {
            background: 'rgba(0, 0, 0, 0.08)',
            '& .MuiListItemIcon-root': {
              color: '#fff'
            }
          }
        }}
        button
        onClick={onClick}>
        {children}
      </ListItem>
    );
  }

  return (
    <ListItem
      button
      sx={{
        marginLeft: '10px',
        padding: '3px 16px',
        '&.active': {
          color: '#3a93ff',
          '& .MuiListItemIcon-root': {
            color: '#3a93ff'
          }
        }
      }}
      component={forwardRef((props, ref) => (
        <NavLink {...props} ref={ref} />
      ))}
      to={link}>
      {children}
    </ListItem>
  );
};

SidebarMenuComponent.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node
};

export default SidebarMenuComponent;
