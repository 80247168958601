import {
  Card,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { REMOTE } from 'constants/serverRoutes';
import { dateFormatter } from 'helpers/dateChanger';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeRequest } from 'services';
import { useNotification } from 'services/hooks';
import { useStateValue } from 'utils/redux';

const ViewPockets = () => {
  const navigate = useNavigate();
  const [appState] = useStateValue();
  const { showNotification } = useNotification();
  const { id } = useParams();
  const [tableData, setTableData] = useState([]);

  useEffect(() => id && getStock(id), []);

  const getStock = (id) => {
    const query = {};
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    query.id = id;
    makeRequest({
      url: `${REMOTE.POCKETS}/filter`,
      params: query
    }).then((result) => {
      if (result.data?.data?.length) {
        let pocketData = result.data.data[0];
        let orderDetails = result.data.data[0]?.orderDetails;
        const tableData =
          orderDetails &&
          orderDetails.map((data) => {
            return [
              { label: 'Transaction Id', value: data?.transactionId },
              { label: 'Asset Code', value: data?.assetCode },
              { label: 'Trans type', value: pocketData?.transactionType },
              {
                label: 'Quantity',
                value:
                  pocketData?.status === 'pending'
                    ? data?.estimatedQuantity
                    : data?.executedQuantity
              },
              {
                label: 'Unit Price',
                value: data?.unitPrice
              },
              { label: 'Status', value: data?.status },
              { label: 'Total Price', value: data?.totalPrice },
              { label: 'Estimated Amount', value: data?.amount },
              { label: 'Updated Time', value: dateFormatter(data?.updatedAt) },
              { label: 'Created Time', value: dateFormatter(data?.createdAt) }
            ];
          });
        setTableData(tableData);
      } else {
        showNotification('error', 'Data not found');
        navigate(-1);
      }
    });
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Pocket Transactions</Typography>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={4}>
          {tableData?.map((pocketData) => {
            return (
              <Grid key={pocketData.tableId} item xs={6}>
                <Card>
                  <Table>
                    <TableBody>
                      {pocketData?.map(({ label, value }) => (
                        <TableRow key={label}>
                          <TableCell>{label}</TableCell>
                          <TableCell>{value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ViewPockets;
