import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';

const KycTaskTable = ({ headers, values, stripped = true }) => {
  const rows = headers.map((header, i) => [header, values[i]]);

  return (
    <Table>
      <TableBody>
        {stripped
          ? rows.map((row) => (
              <TableRow
                sx={{
                  '&:nth-of-type(odd)': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)'
                  },
                  // hide last border
                  '&:last-child td, &:last-child th': {
                    border: 0
                  }
                }}
                key={row[0]}>
                <TableCell>{row[0]}</TableCell>

                <TableCell>{row[1]}</TableCell>
              </TableRow>
            ))
          : rows.map((row) => (
              <TableRow key={row[0]}>
                <TableCell>{row[0]}</TableCell>

                <TableCell>{row[1]}</TableCell>
              </TableRow>
            ))}
      </TableBody>
    </Table>
  );
};

KycTaskTable.propTypes = {
  headers: PropTypes.array.isRequired,
  stripped: PropTypes.bool,
  values: PropTypes.array.isRequired
};

export default KycTaskTable;
