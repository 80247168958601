export const MISSION_REWARD_CLAIM_STATUS = {
  PENDING: 'PENDING',
  DISBURSED: 'DISBURSED',
  OUT_OF_STOCK: 'OUT_OF_STOCK'
};

export const rewardFilters = [
  {
    id: 'missionId',
    type: 'number',
    label: 'Mission ID'
  },
  {
    id: 'status',
    type: 'dropdown',
    label: 'Status',
    default: '',
    option: [
      { text: 'Status-all', value: '' },
      { text: 'Pending', value: MISSION_REWARD_CLAIM_STATUS.PENDING },
      { text: 'Disbursed', value: MISSION_REWARD_CLAIM_STATUS.DISBURSED },
      { text: 'Out of Stock', value: MISSION_REWARD_CLAIM_STATUS.OUT_OF_STOCK }
    ]
  },
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];

export const rewardHeaders = [
  { header_name: 'Mission Id', db_column_name: 'missionId' },
  { header_name: 'Reward Table Name', db_column_name: 'rewardTableName' },
  { header_name: 'Status', db_column_name: 'status' },
  { header_name: 'Client Id', db_column_name: 'clientId' },
  { header_name: 'Partner Id', db_column_name: 'partnerId' },
  { header_name: 'Asset Class', db_column_name: 'assetClass' },
  { header_name: 'Asset Code', db_column_name: 'assetCode' },
  { header_name: 'Asset Value', db_column_name: 'assetValue' },
  { header_name: 'Asset Unit', db_column_name: 'assetUnit' },
  { header_name: 'Created At', db_column_name: 'created' },
  { header_name: 'Updated At', db_column_name: 'updated' }
];

export const userActionFilters = [
  {
    id: 'missionId',
    type: 'number',
    label: 'Mission ID'
  },
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];

export const userActionHeaders = [
  { header_name: 'Mission Id', db_column_name: 'missionId' },
  { header_name: 'Action Table', db_column_name: 'actionTableName' },
  { header_name: 'Client Id', db_column_name: 'clientId' },
  { header_name: 'Action Id', db_column_name: 'recordIdentifier' },
  { header_name: 'Meta', db_column_name: 'meta' }
];
