import PluangListPage from 'components/organisms/pluangListTable';

const filter = [
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];

const headers = [
  { header_name: 'Order ID', db_column_name: 'tableId' },
  { header_name: 'Transaction type', db_column_name: 'transactionType' },
  { header_name: 'Executed amount', db_column_name: 'executedAmount' },
  { header_name: 'Status', db_column_name: 'status' },
  { header_name: 'Pocket name', db_column_name: 'name' },
  { header_name: 'Estimated amount', db_column_name: 'estimatedAmount' },
  { header_name: 'Created At', db_column_name: 'created' },
  { header_name: 'Updated At', db_column_name: 'updated' },
  { header_name: 'Action', db_column_name: 'action', isView: 'true' }
];

const PocketsList = () => {
  return (
    <PluangListPage
      title="Pocket transactions"
      headers={headers}
      resource="POCKETS"
      filters={filter}
    />
  );
};

export default PocketsList;
