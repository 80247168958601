import React, { useState } from 'react';
import PropTypes from 'prop-types';
import KycTask from 'components/molecules/KycTask';
import { NATIONALITIES } from 'config';
import { dateFormatter } from 'helpers/dateChanger';
import KycTaskTable from 'components/molecules/KycTaskTable';
import { Box } from '@mui/system';
import { ktpOcrTableHeaders } from './constants';

const OcrDetails = ({ ktpOcrData: ktpOcr, escalate, kyc1Data }) => {
  const [showDetail, setShowDetail] = useState(false);

  const handleDropdown = (action) => {
    setShowDetail(!action);
  };

  const kycTaskStatus = () => (
    <>
      {escalate && escalate.isKtpOcrEscalated && <Box className="escalated-status">ESCALATED</Box>}
      {ktpOcr && ktpOcr.verifiedById ? (
        <Box style={{ color: 'green' }}>VERIFIED</Box>
      ) : ktpOcr.status === 'NOT UPLOADED' ? (
        ''
      ) : (
        <Box style={{ color: 'black' }}>UPLOADED</Box>
      )}
    </>
  );

  const handleKycTaskClick = () => {
    handleDropdown(showDetail);
  };

  const ktpOcrTableValues = [
    ktpOcr.citizenship,
    ktpOcr.placeOfBirth,
    (ktpOcr.address && ktpOcr.address['address']) || '',
    (ktpOcr.address && ktpOcr.address['rtRw']) || '',
    (ktpOcr.address && ktpOcr.address['province']) || '',
    (ktpOcr.address && ktpOcr.address['city']) || '',
    (ktpOcr.address && ktpOcr.address['district']) || '',
    (ktpOcr.address && ktpOcr.address['subDistrict']) || '',
    ktpOcr.occupation || ktpOcr.meta?.occupation,
    ktpOcr.religion || ktpOcr.meta?.religion,
    ktpOcr.bloodType || ktpOcr.meta?.bloodType,
    ktpOcr.gender || ktpOcr.meta?.gender,
    ktpOcr.maritalStatus || ktpOcr.meta?.maritalStatus,
    ktpOcr.expirationDate ? dateFormatter(ktpOcr.expirationDate) : '',
    ktpOcr.updatedAt ? dateFormatter(ktpOcr.updatedAt) : '',
    ktpOcr.createdAt ? dateFormatter(ktpOcr.createdAt) : ''
  ];

  const isTaskDisabled = ktpOcr.status === 'NOT UPLOADED';
  return (
    <>
      {(Object.keys(kyc1Data).length &&
        Object.keys(ktpOcr).length &&
        kyc1Data.nationality === NATIONALITIES.INDONESIA && (
          <KycTask
            title="OCR Data"
            status={kycTaskStatus()}
            onClick={handleKycTaskClick}
            disabled={isTaskDisabled}
            open={showDetail}>
            <KycTaskTable headers={ktpOcrTableHeaders} values={ktpOcrTableValues} />
          </KycTask>
        )) ||
        null}
    </>
  );
};

OcrDetails.propTypes = {
  ktpOcrData: PropTypes.object,
  escalate: PropTypes.object,
  kyc1Data: PropTypes.object
};

export default OcrDetails;
