import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import BasicTable from 'components/molecules/BasicTable';
import S3DownloadLink from 'components/molecules/s3DownloadLink';
import { dateFormatter } from 'helpers/dateChanger';
import PropTypes from 'prop-types';
import { FOREX_TOPUP_STATUS } from '../../constants';

const ForexTopupViewForm = ({ usdDetails, userDetails, adminDetails, auditTrail }) => {
  const userDetailsTable = [
    { label: 'Name', value: userDetails ? userDetails.name : '' },
    {
      label: 'Email',
      value: (
        <>
          {userDetails ? userDetails.email : ''}
          <Typography ml={2} variant="caption" color={userDetails?.emailVerified ? 'green' : 'red'}>
            {userDetails?.emailVerified ? 'Verified' : 'Not verified'}
          </Typography>
        </>
      )
    },
    { label: 'Phone', value: userDetails?.phone },
    { label: 'Basic Kyc Status', value: userDetails?.bappebtiKycStatus },
    { label: 'GSS Kyc Status', value: userDetails?.gssKycStatus },
    { label: 'Status', value: userDetails?.active ? 'Active' : 'InActive' },
    { label: 'Edd Status', value: userDetails?.eddStatus || 'Not Requested' },
    { label: 'Risk Rating Category', value: userDetails?.riskRatingCategory },
    { label: 'Referral Code', value: userDetails?.referralCode || 'N/A' },
    {
      label: 'Referral Code Status',
      value: userDetails?.referralCodeActive ? 'Active' : 'InActive'
    },
    { label: 'Registered Date', value: dateFormatter(userDetails?.createdAt) },
    { label: 'Last Updated', value: dateFormatter(userDetails?.updatedAt) }
  ];

  const getAdminEmail = (id) => {
    return adminDetails[id]?.email;
  };

  const auditTrailTable = [
    {
      label: 'Rechecker',
      value: getAdminEmail(auditTrail[FOREX_TOPUP_STATUS.RECHECK]?.processedBy)
    },
    {
      label: 'Rechecker Time',
      value: dateFormatter(auditTrail[FOREX_TOPUP_STATUS.RECHECK]?.processedAt)
    },
    {
      label: 'Requester',
      value: getAdminEmail(auditTrail[FOREX_TOPUP_STATUS.INITIATED]?.processedBy)
    },
    {
      label: 'Requester Time',
      value: dateFormatter(auditTrail[FOREX_TOPUP_STATUS.INITIATED]?.processedAt)
    },
    {
      label: 'Verifier',
      value: getAdminEmail(auditTrail[FOREX_TOPUP_STATUS.VERIFIED]?.processedBy)
    },
    {
      label: 'Verifier Time',
      value: dateFormatter(auditTrail[FOREX_TOPUP_STATUS.VERIFIED]?.processedAt)
    },
    {
      label: 'Final Approver',
      value: getAdminEmail(auditTrail[FOREX_TOPUP_STATUS.COMPLETED]?.processedBy)
    },
    {
      label: 'Final Approver Time',
      value: dateFormatter(auditTrail[FOREX_TOPUP_STATUS.COMPLETED]?.processedAt)
    }
  ];

  const forexTopDetails = [
    { label: 'Top Up Id', value: usdDetails?.id },
    { label: 'Top Up Status', value: usdDetails?.status },
    { label: 'Top Up Amount Requested', value: usdDetails?.topUpAmount },
    { label: 'Source Bank', value: usdDetails?.sourceBank },
    {
      label: 'Source Bank Account',
      value: usdDetails?.sourceBankAccountNumber
    },
    {
      label: 'Rejection Reason (En)',
      value: usdDetails?.rejectionReasonEn
    },
    {
      label: 'Rejection Reason (In)',
      value: usdDetails?.rejectionReasonIn
    },
    {
      label: 'Internal Rejection Reason',
      value: usdDetails?.internalRejectionReason
    },
    {
      label: 'Proof Of Transfer',
      value: usdDetails?.proofOfTransfer ? <S3DownloadLink url={usdDetails?.proofOfTransfer} /> : ''
    },
    {
      label: 'Top Up Form',
      value: usdDetails?.topUpForm ? <S3DownloadLink url={usdDetails?.topUpForm} /> : ''
    }
  ];

  const bankStatementReconciliationData = [
    { label: 'Final Amount Received', value: usdDetails?.finalAmount },
    {
      label: 'Bank Statement Transaction Reference Id',
      value: usdDetails?.bankStatementTransactionId
    }
  ];

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">USD Top Up Details</Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        {usdDetails && (
          <>
            <BasicTable tableData={forexTopDetails} />
            <Typography variant="subtitle1" sx={{ mt: 2, mx: 2 }}>
              Proof Of Transfer
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                my: 2
              }}>
              <Box component="img" src={usdDetails?.proofOfTransfer} sx={{ height: 400, mt: 1 }} />
            </Box>
            <Typography variant="subtitle1" sx={{ mt: 2, mx: 2 }}>
              TopUp Form
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                my: 2
              }}>
              <Box component="img" src={usdDetails?.topUpForm} sx={{ height: 400, mt: 1 }} />
            </Box>
            <Typography variant="h5" sx={{ mt: 4 }}>
              Bank Statement Reconciliation
            </Typography>
            <BasicTable tableData={bankStatementReconciliationData} />
            <Grid item xs={6}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  my: 2
                }}>
                <Typography variant="subtitle3">Bank Statement Entry</Typography>
                {usdDetails?.bankStatementTransactionProof && (
                  <S3DownloadLink url={usdDetails?.bankStatementTransactionProof} />
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  my: 2
                }}>
                {usdDetails?.bankStatementTransactionProof && (
                  <Box
                    component="img"
                    src={usdDetails?.bankStatementTransactionProof}
                    sx={{ height: 200, mt: 1 }}
                  />
                )}
              </Box>
            </Grid>
          </>
        )}
        <Divider sx={{ my: 2 }} />
        <Typography variant="h5">User Details</Typography>
        {userDetails && <BasicTable tableData={userDetailsTable} />}
        <Divider sx={{ my: 2 }} />
        <Typography variant="h5">Audit Trail</Typography>
        <Divider sx={{ my: 2 }} />
        {usdDetails && <BasicTable tableData={auditTrailTable} />}
      </CardContent>
    </Card>
  );
};

ForexTopupViewForm.propTypes = {
  usdDetails: PropTypes.object,
  userDetails: PropTypes.object,
  adminDetails: PropTypes.object,
  auditTrail: PropTypes.object
};

export default ForexTopupViewForm;
