import { Box, Typography } from '@mui/material';
import BasicTable from 'components/molecules/BasicTable';
import PropTypes from 'prop-types';
import { FOREX_CASHOUT_STATUS } from '../../constants';

const ForexFinanceForm = ({ usdDetails }) => {
  const showFinanceConfirmation =
    usdDetails?.status === FOREX_CASHOUT_STATUS.APPROVED ||
    usdDetails?.status === FOREX_CASHOUT_STATUS.COMPLETED;

  const jiraLink = [{ label: 'JIRA Link', value: usdDetails?.financeJiraLink || '-' }];

  return (
    <>
      {usdDetails && showFinanceConfirmation && (
        <>
          <Typography variant="h5" sx={{ mt: 4 }}>
            Finance Confirmation
          </Typography>
          <BasicTable tableData={jiraLink} />
          <Typography variant="subtitle1" sx={{ mt: 2, mx: 2, fontWeight: 'bold' }}>
            Fund Transfer Proof
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              my: 2
            }}>
            <Box component="img" src={usdDetails?.bankTransferSlip} sx={{ height: 400, mt: 1 }} />
          </Box>
        </>
      )}
    </>
  );
};

ForexFinanceForm.propTypes = {
  usdDetails: PropTypes.object,
  userDetails: PropTypes.object
};

export default ForexFinanceForm;
