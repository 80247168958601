import React, { useEffect, useState } from 'react';
import PluangListPage from 'components/organisms/pluangListTable';
import { makeRequest } from 'services/APIService';
import useNotification from 'services/hooks/useNotification';
import { REMOTE } from 'constants/serverRoutes';
import { filter, headers } from './constants';
import { useLoader } from 'services/hooks';

const UsIndexTransaction = (props) => {
  const [filters, setFilters] = useState(filter);
  const { showNotification } = useNotification();
  const [, setLoader] = useLoader();

  useEffect(() => {
    getAllStockIndexes();
  }, []);

  const getAllStockIndexes = () => {
    setLoader(true);
    makeRequest({
      url: `${REMOTE.STOCK_INDEX_ACCOUNT}/allStockIndexes`
    })
      .then((result) => {
        if (result && result.data && result.data.data) {
          const stockIndexFilters = result.data.data.map((stockIndex) => {
            return { text: stockIndex.name, value: stockIndex.symbol };
          });
          const filter = [...filters];
          filter[3].option = [{ text: 'All', value: 'all' }, ...stockIndexFilters];
          setFilters(filter);
        }
      })
      .catch((err) => {
        showNotification('error', err.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <PluangListPage
      {...props}
      title={'US Index Transaction'}
      headers={headers}
      filters={filters}
      resource={'SNP500_TRANSACTIONS'}
    />
  );
};

export default UsIndexTransaction;
