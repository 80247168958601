import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const KycTask = ({ children, title, status, disabled, open, onClick }) => {
  return (
    <Accordion disabled={disabled} expanded={open} onChange={onClick}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 1
          }}>
          <Typography variant="h6">{title}</Typography>
          <Typography>{status}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%">{children}</Box>
      </AccordionDetails>
    </Accordion>
  );
};

KycTask.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  status: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  open: PropTypes.bool
};

export default KycTask;
