import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Grid, IconButton, MenuItem, Select } from '@mui/material';
import { getMonth, getYear, setMonth, setYear } from 'date-fns';
import PropTypes from 'prop-types';

const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
];

const generateYears = () => {
  return Array(10)
    .fill(0)
    .map((y, i) => new Date().getFullYear() - 9 + i);
};

const Header = ({ date, setDate, nextDisabled, prevDisabled, onClickNext, onClickPrevious }) => {
  const handleMonthChange = (event) => {
    setDate(setMonth(date, parseInt(event.target.value)));
  };

  const handleYearChange = (event) => {
    setDate(setYear(date, parseInt(event.target.value)));
  };

  return (
    <Grid container alignItems="center" sx={{ mt: 1, justifyContent: 'space-around' }}>
      <Grid item sx={{ p: 1 }}>
        <IconButton disabled={prevDisabled} onClick={onClickPrevious}>
          <ChevronLeft color={prevDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </Grid>
      <Grid item>
        <Select
          size="small"
          value={getMonth(date)}
          onChange={handleMonthChange}
          MenuProps={{ disablePortal: true }}>
          {MONTHS.map((month, idx) => (
            <MenuItem key={month} value={idx}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item>
        <Select
          size="small"
          value={getYear(date)}
          onChange={handleYearChange}
          MenuProps={{ disablePortal: true }}>
          {generateYears().map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item sx={{ p: 1 }}>
        <IconButton disabled={nextDisabled} onClick={onClickNext}>
          <ChevronRight color={nextDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  date: PropTypes.instanceOf(Date),
  setDate: PropTypes.func,
  nextDisabled: PropTypes.bool,
  prevDisabled: PropTypes.bool,
  onClickNext: PropTypes.func,
  onClickPrevious: PropTypes.func
};

export default Header;
